export const onPhoneInput = function (event) {
  const input = event.target;
  // Оставляем только цифры
  let inputNumbersValue = input.value.replace(/\D/g, '');
  const selectionStart = input.selectionStart;
  let formattedInputValue = '';

  if (!inputNumbersValue) {
    return (input.value = '');
  }

  if (input.value.length != selectionStart) {
    // Проверяем, что курсор не в конце строки
    if (event.data && /\D/g.test(event.data)) {
      // Оставляем только цифры
      input.value = inputNumbersValue;
    }
    return;
  }

  if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
    if (inputNumbersValue[0] == '9')
      inputNumbersValue = '7' + inputNumbersValue;
    let firstSymbols = inputNumbersValue[0] == '8' ? '+7' : '+7';
    formattedInputValue = input.value = firstSymbols + ' ';
    if (inputNumbersValue.length > 1) {
      formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
    }
    if (inputNumbersValue.length >= 5) {
      formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
    }
    if (inputNumbersValue.length >= 8) {
      formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
    }
    if (inputNumbersValue.length >= 10) {
      formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
    }
  } else {
    formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
  }
  input.value = formattedInputValue;
};

// Форматирование при вставке из буфера
export const onPhonePaste = function (event) {
  const input = event.target;
  // Оставляем только цифры
  const inputNumbersValue = input.value.replace(/\D/g, '');

  const pasted = event.clipboardData;
  if (pasted) {
    let pastedText = pasted.getData('Text');
    if (/\D/g.test(pastedText)) {
      // Оставляю только цифры
      // Сработает при вставке
      input.value = inputNumbersValue;
      return;
    }
  }
};

// Фикс удаления последнего символа из инпута
export const onPhoneKeyDown = function (event) {
  let inputValue = event.target.value.replace(/\D/g, '');
  if (event.keyCode == 8 && inputValue.length == 1) {
    event.target.value = '';
  }
};
