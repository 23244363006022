import { useLoginModal } from '../lib/store';
import { useUser } from 'store/user';

import { ModalLayout } from '../../ModalLayouts/StandartModal';
import { LoginForm } from 'entities/Auth/Login/LoginForm';

function LoginModal() {
  const isAuth = useUser((state) => state.isAuth);
  const { isActive, setIsActive } = useLoginModal((state) => ({
    isActive: state.active,
    setIsActive: state.setActiveModal,
  }));

  return (
    !isAuth && (
      <ModalLayout
        active={isActive}
        onClose={() => setIsActive(false)}
        title="Вход"
        message="Выполните вход в личный кабинет, чтобы добавлять товары в Избранное и получать бонусы за покупки"
      >
        <LoginForm />
      </ModalLayout>
    )
  );
}

export { LoginModal };
