import React from 'react';
import styles from './ItemFAQ.module.scss';

import { itemInfo } from 'widgets/Item/model/itemInfo-data';
import { InfoDropdown } from 'shared/ui/InfoDropdown';

export default function ItemFAQ() {
  return (
    <div className={styles.dropdownsContainer}>
      {itemInfo.map((data) => (
        <InfoDropdown
          key={data.id}
          title={data.title}
          children={data.description}
          isOpen={data.isOpen}
        />
      ))}
    </div>
  );
}
