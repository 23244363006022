import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { normalizeSeoPath } from 'shared/lib/normalizeSeoPath';
import { seoMetadataMap } from '../../seoMetadataMap';

function SEOCatalogTitle() {
  const [currentPath, setCurrentPath] = useState('/');
  const [title, setTitle] = useState('Каталог ювелирных изделий');
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(normalizeSeoPath(location.pathname));
  }, [location]);

  useEffect(() => {
    setTitle(
      (seoMetadataMap[currentPath] && seoMetadataMap[currentPath].h1) ||
        'Каталог ювелирных изделий',
    );
  }, [currentPath]);

  return <h1>{title}</h1>;
}

export { SEOCatalogTitle };
