import React from 'react';

function Indicator({ style, size }) {
  return (
    <svg
      className={style}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#E9B163" />
    </svg>
  );
}

export { Indicator };
