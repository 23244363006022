import React, { useState } from 'react';
import styles from './OrderCard.module.scss';

import roundPrice from 'shared/lib/roundPrice';
import { useTinkoffPayment } from 'api/Order/useTinkoffPayment';

import { MainButton } from 'shared/ui/MainButton';
import { CardHorizontal } from 'entities/CardHorizontal';
import { WarningBadge } from 'shared/ui/WarningBadge';

function OrderCard({ order }) {
  const [isExtraInfoOpen, setExtraOInfoOpen] = useState(false);

  const { payTinkoffLoading, payOrderTinkoff } = useTinkoffPayment();

  function handlePayTinkoff() {
    payOrderTinkoff(order.id);
  }

  function handleShowExtraInfo() {
    setExtraOInfoOpen((prevState) => !prevState);
  }

  return (
    <div className={styles.orderItem}>
      {/* Номер заказа и сумма заказа */}
      <div className={styles.orderHeader}>
        <h2>Заказ №{order.id}</h2>
        {order.status !== 'Заказ отменен' && (
          <div className={styles.orderSummContainer}>
            <p className={styles.orderSummStatus}>
              {order.paid ? 'Оплачено' : 'К оплате'}
            </p>
            <h3 className={styles.orderSumm}>
              {roundPrice(order.total_sum)} руб.
            </h3>
          </div>
        )}
      </div>
      <div className={styles.orderInfo}>
        {/* Тип доставки и статус заказа */}
        <div className={styles.orderStatusContainer}>
          <h3>
            {order.order_delivery[0].delivery_type !== 'Самовывоз' &&
              'Служба доставки'}{' '}
            {order.order_delivery[0].delivery_type}
          </h3>
          <WarningBadge text={order.status} />
        </div>
        {/* Картинки товаров. Скрываем, если открыта доп информация о заказе */}
        {!isExtraInfoOpen && (
          <div className={styles.orderImages}>
            {order.order_items.map((order, index) => (
              <img
                key={index}
                src={order.compressed_image}
                alt={order.trade_offer}
                loading="lazy"
              />
            ))}
          </div>
        )}
        {/* Блок с дополнительной информацией о заказе */}
        {isExtraInfoOpen && (
          <>
            {order.order_items.length && (
              <div className={styles.extractOrderInfo}>
                {order.order_items.map((order, index) => (
                  <CardHorizontal
                    key={index}
                    title={order.trade_offer}
                    price={order.price}
                    image={order.compressed_image}
                    quantity={order.quantity}
                  />
                ))}
              </div>
            )}
          </>
        )}
        {/* Выводим эту кнопку только если был выбран способ оплаты картой */}
        {!order.paid &&
          order.payment_type === 3 &&
          order.status !== 'Заказ отменен' && (
            <div className={styles.payButton}>
              <MainButton
                text="Оплатить"
                loading={payTinkoffLoading}
                onClick={handlePayTinkoff}
              />
            </div>
          )}
      </div>
      <p className={styles.moreInfoButton} onClick={handleShowExtraInfo}>
        {isExtraInfoOpen
          ? 'Скрыть дополнительную информацию'
          : 'Подробнее о заказе'}
      </p>
    </div>
  );
}

export { OrderCard };
