import { useEffect } from 'react';
import './CatalogPagination.scss';
import { useShallow } from 'zustand/react/shallow';

import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { useCatalogPagination } from 'store/pagination';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

function CatalogPagination({ itemsCount }) {
  const windowSize = useWindowSize();
  const { setPagination, paginationParam, setPageLimit, limit } =
    useCatalogPagination(
      useShallow((state) => ({
        setPagination: state.setPagination,
        paginationParam: state.pagination,
        setPageLimit: state.setPageLimit,
        limit: state.pageLimit,
      })),
    );

  const totalPages = Math.ceil(itemsCount / limit);

  useEffect(() => {
    if (windowSize > 1737) {
      setPageLimit(35);
    } else if (windowSize >= 1464) {
      setPageLimit(32);
    } else if (windowSize >= 1190) {
      setPageLimit(33);
    } else if (windowSize >= 1024) {
      setPageLimit(32);
    } else if (windowSize >= 862) {
      setPageLimit(33);
    } else if (windowSize >= 680) {
      setPageLimit(32);
    } else if (windowSize >= 500) {
      setPageLimit(33);
    } else {
      setPageLimit(32);
    }
  }, [windowSize]);

  useEffect(() => {
    if (totalPages < paginationParam) setPagination(1);
  }, [totalPages, paginationParam]);

  if (totalPages && totalPages > 1) {
    return (
      <div className="catalogPagination">
        <MuiPagination
          count={totalPages}
          hidePrevButton
          hideNextButton
          page={paginationParam}
          onChange={(_, number) => setPagination(number)}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    );
  }
}

export { CatalogPagination };
