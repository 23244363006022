export const categoriesData = [
  {
    id: 1,
    img: 'sergi.png',
    title: 'Серьги',
    value: 'Серьги',
  },
  {
    id: 2,
    img: 'chains.png',
    title: 'Цепи',
    value: 'Цепи',
  },
  {
    id: 3,
    img: 'braslety.png',
    title: 'Браслеты',
    value: 'Браслеты',
  },
  {
    id: 4,
    img: 'koltsa.png',
    title: 'Кольца',
    value: 'Кольца',
  },
  {
    id: 5,
    img: 'chasy.png',
    title: 'Часы',
    value: 'Часы',
  },
  {
    id: 6,
    img: 'pechatki.png',
    title: 'Печатки',
    value: 'Печатки',
  },
  {
    id: 7,
    img: 'podveski.png',
    title: 'Подвески',
    value: 'Подвески',
  },
  {
    id: 8,
    img: 'suveniry.png',
    title: 'Сувениры',
    value: 'Монеты и сувениры',
  },
  {
    id: 9,
    img: 'gaitans.png',
    title: 'Гайтаны',
    value: 'Гайтаны',
  },
  {
    id: 10,
    img: 'slitki.png',
    title: 'Cлитки',
    value: 'Слитки',
  },
  {
    id: 11,
    img: 'broshi.png',
    title: 'Броши',
  },
  {
    id: 12,
    img: 'zaponki.png',
    title: 'Запонки',
  },
  // {
  //     id: 11,
  //     img: 'pirsing.png',
  //     title: 'Пирсинг',
  // },
  // {
  //     id: 13,
  //     img: 'galstuki.png',
  //     title: 'Зажимы для галстука',
  // },
];
