import React from 'react';
import styles from '../../../LoginPage.module.scss';
import { Link } from 'react-router-dom';

import { LoginCodeForm } from 'entities/Auth/Login/LoginCodeForm';

function SignInBySMSPage() {
  const phone_number = localStorage.getItem('phone_number');

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Вход</h2>
      <p className={styles.text}>
        Сейчас Вам придет СМС{' '}
        {phone_number && (
          <span>
            на номер <br /> {phone_number}{' '}
            <Link to="/login/sms" className={styles.changeNumberLink}>
              Изменить
            </Link>
          </span>
        )}
      </p>
      <LoginCodeForm type="sms" />
    </div>
  );
}

export { SignInBySMSPage };
