import React from 'react';
import styles from '../../../RegisterPage.module.scss';

import RegCodeForm from 'entities/Auth/Register/RegCodeForm';

function RegisterPage() {
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Регистрация</h2>
      <RegCodeForm />
    </div>
  );
}

export { RegisterPage };
