import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ItemButtons.module.scss';

import { useJewMakingModalStore } from 'entities/Modal/ui/components/JewelryMakingModal/lib/store';
import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { MainButton } from 'shared/ui/MainButton';

export default function ItemButtons({
  id,
  quantity,
  is_active,
  inCart,
  onAddToCart,
  loading,
  onBuyOneClickModal,
}) {
  const { setIsActive, setItems } = useJewMakingModalStore((state) => ({
    setIsActive: state.setActiveModal,
    setItems: state.setItems,
  }));

  const handleActiveModal = () => {
    setIsActive(true);
    setItems(id);
  };

  return quantity && is_active ? (
    <div className={styles.itemButtons}>
      {inCart ? (
        <Link to="/cart">
          <MainButton text="Перейти в корзину" />
        </Link>
      ) : (
        <MainButton text="В корзину" onClick={onAddToCart} loading={loading} />
      )}
      <SecondaryButton onClick={onBuyOneClickModal} text="Купить в 1 клик" />
    </div>
  ) : (
    <MainButton text="Изготовить похожее" onClick={handleActiveModal} />
  );
}
