import React, { useEffect, useState } from 'react';
import styles from './ImageModal.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { GoZoomIn } from 'react-icons/go';
import { GoZoomOut } from 'react-icons/go';
import { GoScreenFull } from 'react-icons/go';

import { useImageModal } from '../lib/store';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

const animationContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: { opacity: 0 },
};

function ImageModal({ children }) {
  const [isMobile, setIsMobile] = useState(false);

  const { active, setActiveModal } = useImageModal((state) => ({
    active: state.active,
    setActiveModal: state.setActiveModal,
  }));

  function onCloseModal() {
    setActiveModal(false);
  }

  function onImageClick(event) {
    // Предотвращаем всплытие события клика
    event.stopPropagation();
  }

  // Запрещаю скрол, при открытии модалки
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [active]);

  // Слежу за изменением размена экрана
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={styles.modalActive}
          variants={animationContainer}
          initial="hidden"
          animate="show"
          exit="exit"
          onClick={onCloseModal}
        >
          <div className={styles.modalContent} onClick={onImageClick}>
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <React.Fragment>
                  <TransformComponent>{children}</TransformComponent>
                  <div className={styles.tools}>
                    {!isMobile ? (
                      <>
                        <div className={styles.button} onClick={() => zoomIn()}>
                          <GoZoomIn size={24} />
                        </div>
                        <div
                          className={styles.button}
                          onClick={() => zoomOut()}
                        >
                          <GoZoomOut size={24} />
                        </div>
                      </>
                    ) : null}
                    <div
                      className={styles.button}
                      onClick={() => resetTransform()}
                    >
                      <GoScreenFull size={24} />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { ImageModal };
