import * as Pages from './routerImports';

const publicRoutes = [
  {
    name: 'Главная',
    path: '/',
    component: <Pages.MainPage />,
    index: true,
  },
  {
    name: 'Корзина',
    path: 'cart',
    component: <Pages.CartPage />,
  },
  {
    name: 'Каталог',
    path: 'catalog',
    component: <Pages.CatalogPage />,
  },
  {
    name: 'Страница акции',
    path: 'news/:url',
    component: <Pages.NewPage />,
  },
  {
    name: 'СЕО каталог',
    path: 'catalog/:category?/:subcategory?/:material?/:forwhom?/:vstavki?/',
    component: <Pages.SEOCatalogPage />,
  },
  {
    name: 'Ювелирные работы',
    path: 'yuvelirnyie-rabotyi',
    component: <Pages.JWorksPage />,
  },
  // Инфо-страницы
  {
    name: 'Контакты',
    path: 'about',
    component: <Pages.AboutPage />,
  },
  {
    name: 'Отзывы',
    path: 'otzyvy',
    component: <Pages.Otzyvy />,
  },
  {
    name: 'Ремонты',
    path: 'yuvelirnyie-rabotyi/remont',
    component: <Pages.RepairPage />,
  },
  {
    name: 'Акции',
    path: 'news',
    component: <Pages.News />,
  },
  {
    name: 'Информация',
    path: 'info',
    component: <Pages.Info />,
  },
  {
    name: 'Страница товара',
    path: 'catalog/product/:id',
    component: <Pages.ItemPage />,
  },
  {
    name: 'Вакансии',
    path: 'vacancy',
    component: <Pages.Vacancy />,
  },
  {
    name: 'Вопрос-ответ',
    path: 'vopros-otvet',
    component: <Pages.Vopros />,
  },
  {
    name: 'Возврат-обмен',
    path: 'vozvrat-obmen',
    component: <Pages.Vozvrat />,
  },
  {
    name: 'Доставка',
    path: 'dostavka',
    component: <Pages.Dostavka />,
  },
  {
    name: 'Оплата',
    path: 'oplata',
    component: <Pages.PaymentPage />,
  },
  // Филиалы
  {
    name: 'Филиал на Долгополова',
    path: 'about/dolgopolova',
    component: <Pages.DolgopolovaStore />,
  },
  {
    name: 'Филиал на Родионова',
    path: 'about/rodionova',
    component: <Pages.RodionovaStore />,
  },
  {
    name: 'Филиал на Гагарина',
    path: 'about/gagarina',
    component: <Pages.GagarinaStore />,
  },
  {
    name: 'Филиал на Юбилейном',
    path: 'about/jubileyniy',
    component: <Pages.JubileyniyStore />,
  },
  // Регистрация и Вход
  {
    name: 'Вход',
    path: 'login',
    component: <Pages.LoginPage />,
  },
  {
    name: 'Вход по СМС',
    path: 'login/sms',
    component: <Pages.LoginBySMSPage />,
  },
  {
    name: 'Вход',
    path: 'sign-in',
    component: <Pages.SignInPage />,
  },
  {
    name: 'Вход по СМС',
    path: 'sign-in/sms',
    component: <Pages.SignInBySMSPage />,
  },
  {
    name: 'Регистрация',
    path: 'register',
    component: <Pages.RegisterPage />,
  },
  {
    name: 'Регистрация по СМС',
    path: 'register/sms',
    component: <Pages.RegisterBySMSPage />,
  },
  {
    name: 'Регистрация',
    path: 'sign-up',
    component: <Pages.SignUpPage />,
  },
  {
    name: 'Регистрация по СМС',
    path: 'sign-up/sms',
    component: <Pages.SignUpBySMSPage />,
  },
  // 404
  {
    name: '404',
    path: '*',
    component: <Pages.NotFoundPage />,
  },
];

const privateRoutes = [
  {
    name: 'Профиль',
    path: 'profile/*',
    component: <Pages.LKPage />,
  },
  {
    name: 'Избранное',
    path: 'favorite',
    component: <Pages.FavoritePage />,
  },
];

export { publicRoutes, privateRoutes };
