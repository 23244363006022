import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { privateRoutes, publicRoutes } from './config/routerConfig';
import PrivateRoute from './PrivateRoute';
import { Layout } from 'entities/Layout';

export default function Router() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/catalog') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            element={route.component}
            index={route.index}
            key={route.path}
          />
        ))}
        {/* Приватные роуты */}
        <Route element={<PrivateRoute />}>
          {privateRoutes.map((route) => (
            <Route
              path={route.path}
              element={route.component}
              key={route.path}
            />
          ))}
        </Route>
      </Route>
    </Routes>
  );
}
