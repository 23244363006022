export function transliterateColorParam(param) {
  const transliterateColorParam = {
    'iz-krasnogo-zolota': 'Красный',
    'iz-zheltogo-zolota': 'Желтый',
    'iz-kombinirovannogo-zolota': 'комбинированный',
    'iz-belogo-zolota': 'Белый',
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterateColorParam.hasOwnProperty(param)) {
    return transliterateColorParam[param];
  } else {
    return undefined;
  }
}
