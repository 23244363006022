import styles from './LKPage.module.scss';

import { LkProfile } from 'widgets/LkProfile';

function LKPage() {
  return (
    <div className={styles.profilePage}>
      <h1 className={styles.title}>Личный кабинет</h1>
      <LkProfile />
    </div>
  );
}

export { LKPage };
