import React, { useEffect } from 'react';
import styles from './vopros.module.scss';
import useFaqTopics from 'api/FAQ/useFaqTopics';

import { InfoDropdown } from 'shared/ui/InfoDropdown';
import { FaqSkelleton } from 'shared/ui/Skelletons/FAQ';

function Vopros() {
  const { topics, topicsLoading, getFaqTopics } = useFaqTopics();

  useEffect(() => {
    getFaqTopics();
  }, []);

  if (topicsLoading) {
    return (
      <>
        <h1 className={styles.voprosMainTitle}>Вопрос ответ</h1>
        <div className={styles.voprosContainer}>
          <FaqSkelleton />
        </div>
      </>
    );
  }

  return (
    <>
      <h1 className={styles.voprosMainTitle}>Вопрос ответ</h1>
      <div className={styles.voprosContainer}>
        {topics.map((topic, index) => (
          <InfoDropdown title={topic.title} key={index}>
            <p className={styles.voprosParagraph}>{topic.text}</p>
          </InfoDropdown>
        ))}
      </div>
    </>
  );
}

export { Vopros };
