import React from 'react';
import styles from './CatalogItemsCounter.module.scss';

import wordsDeclention from 'shared/lib/wordsDeclention';
import { CounterSkelleton } from 'shared/ui/Skelletons/CatalogCounter';

const words = ['товар', 'товара', 'товаров'];

function CatalogItemsCounter({ itemsCount, loading }) {
  if (loading) {
    return (
      <span className={styles.catalogItemsCounter}>
        <CounterSkelleton />
      </span>
    );
  }

  return itemsCount ? (
    <span className={styles.catalogItemsCounter}>
      {`${itemsCount} ${wordsDeclention(itemsCount, words)}`}
    </span>
  ) : null;
}

export { CatalogItemsCounter };
