import React, { useEffect, useState } from 'react';
import styles from './StoresRecievType.module.scss';

import { useOrder } from 'store/order';
import { useShallow } from 'zustand/react/shallow';
import { Checkbox } from 'shared/ui/Checkbox';
import { stores } from './model/stores';

export default function StoresRecievType() {
  const [activeCheckbox, setActiveCheckbox] = useState('');
  const { address, setAddRess } = useOrder(
    useShallow((state) => ({
      address: state.address,
      setAddRess: state.setAddRess,
    })),
  );

  const handleChange = (value) => {
    // При снятии активного чекбокса
    if (value === activeCheckbox) {
      setAddRess(null);
      setActiveCheckbox('');
    } else {
      setAddRess(value);
      setActiveCheckbox(value);
    }
  };

  useEffect(() => {
    if (address) {
      setAddRess(address);
      setActiveCheckbox(address);
    }
  }, []);

  return (
    <div className={styles.samovyvozContainer}>
      {stores.map((store, index) => (
        <div className={styles.samovyvoz} key={index}>
          <Checkbox
            item={store}
            label={store.name}
            handleChange={() => handleChange(store.value)}
            checked={activeCheckbox === store.value}
          />
          <p className={styles.storeWorkTime}>
            {`${store.worktimeSart} по ${store.worktimeEnd}`}
          </p>
        </div>
      ))}
    </div>
  );
}
