import React from 'react';
import styles from './CatalogSkelleton.module.scss';

const skelletons = [
  { name: 'skelleton0' },
  { name: 'skelleton1' },
  { name: 'skelleton2' },
  { name: 'skelleton3' },
  { name: 'skelleton4' },
  { name: 'skelleton5' },
  { name: 'skelleton6' },
  { name: 'skelleton7' },
  { name: 'skelleton8' },
  { name: 'skelleton9' },
  { name: 'skelleton10' },
  { name: 'skelleton11' },
];

function CatalogSkelleton() {
  return (
    <div className={styles.catalogSkelletonsContainer}>
      {skelletons.map((skelleton) => (
        <div className={styles.catalogSkelleton} key={skelleton.name}></div>
      ))}
    </div>
  );
}

export { CatalogSkelleton };
