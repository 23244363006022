import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Orders.module.scss';

import { scrollTop } from 'shared/lib/scrollTop';
import { useGetOrders } from 'api/Order/useGetOrders';

import { OrderCard } from 'entities/OrderCard';
import { Pagination } from 'entities/Pagination';
import { MainButton } from 'shared/ui/MainButton';
import { GetOrdersSkelleton } from 'shared/ui/Skelletons/GetOrders';
import { GoPaste } from 'react-icons/go';

function Orders() {
  const { orders, ordersEmpty, getOrdersLoading, getOrders } = useGetOrders();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const ordersPerPage = 5;

  // Изменяем текущую страницу
  const handlePaginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Рассчеты для пагинации
  const calcPagination = () => {
    const lastOrderIndex = currentPage * ordersPerPage;
    const firstOrderIndex = lastOrderIndex - ordersPerPage;
    // Режем массив для отображения на странице
    setCurrentOrders(orders.slice(firstOrderIndex, lastOrderIndex));
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    calcPagination();
    scrollTop();
  }, [orders.length, currentPage]);

  if (getOrdersLoading || ordersEmpty === null) {
    return (
      <div className={styles.ordersContainer}>
        <GetOrdersSkelleton />
      </div>
    );
  }

  if (ordersEmpty) {
    return (
      <div className={styles.ordersEmptyContainer}>
        <GoPaste size={100} />
        <h3>Здесь пока пусто...</h3>
        <Link to="/catalog">
          <MainButton text="Перейти в Каталог" />
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.ordersContainer}>
      {currentOrders.map((order) => (
        <OrderCard key={order.id} order={order} />
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(orders.length / ordersPerPage)}
        handlePaginate={handlePaginate}
      />
    </div>
  );
}

export { Orders };
