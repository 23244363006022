import React, { useState, useEffect } from 'react';
import styles from '../../../Filter.module.scss';

import { useFilters } from 'store/filters';
import {
  chainsSizes,
  cufsSizes,
  ringsSizes,
  gaitanySizes,
} from '../../../../model/sizesStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function SizeFilter() {
  const [sizeArray, setSizeArray] = useState([]);
  const { setFilters, category, size } = useFilters((state) => ({
    setFilters: state.setFilters,
    category: state.filters.category,
    size: state.filters.size || '',
  }));

  const handleChange = (value) => {
    if (size.includes(value)) {
      // При снятии активного чекбокса
      const updatedValues = size
        .split(';')
        .filter((size) => size !== value)
        .join(';');
      setFilters('size', updatedValues);
    } else {
      const updatedValues = size ? `${size};${value}` : value;
      setFilters('size', updatedValues);
    }
  };

  // Условия отображения нужного фильтра
  useEffect(() => {
    if (category === 'Цепи') {
      setSizeArray(chainsSizes);
    }
    if (category === 'Браслеты') {
      setSizeArray(cufsSizes);
    }
    if (category === 'Кольца') {
      setSizeArray(ringsSizes);
    }
    if (category === 'Печатки') {
      setSizeArray(ringsSizes);
    }
    if (category === 'Гайтаны') {
      setSizeArray(gaitanySizes);
    }
  }, [category]);

  return (
    ['Цепи', 'Браслеты', 'Кольца', 'Печатки', 'Гайтаны'].includes(category) && (
      <FilterDropdown title="Размер" isOpen={!!size}>
        <div className={styles.contentContainer}>
          {sizeArray.map((item, index) => (
            <FilterCheckbox
              key={index}
              item={item}
              label={item.value}
              handleChange={() => handleChange(item.value)}
              checked={size.includes(item.value)}
            />
          ))}
        </div>
      </FilterDropdown>
    )
  );
}

export { SizeFilter };
