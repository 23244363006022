import React, { useEffect } from 'react';
import styles from './CDEKWidget.module.scss';

import { urls } from 'api/Config/usrlsConfig';
import { useOrder } from 'store/order';
import { useCart } from 'store/cart';
import { useShallow } from 'zustand/react/shallow';
import { useCalcCdekCost } from 'api/Order/useCalcCdekCost';

export default function CDEKWidget() {
  const itemsCount = useCart((state) => state.itemsCount);
  const {
    address,
    delivery_type,
    setExpressDelivery,
    setAddRess,
    setLocationCode,
    setGeocode,
    setPVZ,
  } = useOrder(
    useShallow((state) => ({
      address: state.address,
      delivery_type: state.delivery_type,
      setExpressDelivery: state.setExpressDelivery,
      setAddRess: state.setAddRess,
      setLocationCode: state.setLocationCode,
      setGeocode: state.setGeocode,
      setPVZ: state.setPVZ,
    })),
  );

  const { calcCdekCost } = useCalcCdekCost();

  useEffect(() => {
    let widgetInstance;

    const initializeWidget = () => {
      widgetInstance = new window.CDEKWidget({
        from: 'Нижний Новгород',
        defaultLocation: 'Нижний Новгород',
        root: 'cdek-map',
        apiKey: process.env.REACT_APP_CDEK_API_KEY,
        servicePath: urls.getCdekWidget,
        onChoose(type, _, address) {
          // console.log('delivery_type:', type)
          // console.log('data:', address)

          if (type === 'door') {
            setExpressDelivery(true);
            setAddRess(address.formatted);
            // Геокод отправляем в формате "43.999186,56.276778"
            setGeocode(address.position.join(','));
            setLocationCode(null);
            setPVZ(null);
            calcCdekCost();
          } else {
            setExpressDelivery(false);
            setAddRess(address.city + ', ' + address.name);
            setLocationCode(address.city_code);
            setPVZ(address.code);
            calcCdekCost();
          }
        },
      });
    };

    const cleanupWidget = () => {
      if (widgetInstance) {
        widgetInstance.destroy();
      }
    };

    // Инициализирую виджет только при выборе cdek
    if (delivery_type === 'cdek') {
      initializeWidget();
    }

    // Очищаю ресурсы при размонтировании компонента
    return cleanupWidget;
  }, [delivery_type]);

  // Будем делать перерассчет при изменении кол-ва товаров или адреса
  useEffect(() => {
    // Но если адрес не выбран, то и пересчет делать не нужно
    if (address !== null) {
      calcCdekCost();
    }
  }, [address, itemsCount]);

  return (
    <div className={styles.widgetContainer}>
      <div id="cdek-map" className={styles.widget}></div>
    </div>
  );
}
