import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function usePromoBanner() {
  const [promoBanner, setPromoBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  async function getPromoBanner(url) {
    setLoading(true);

    try {
      const response = await $api.get(urls.getPromoBanners + url);
      setPromoBanner(response.data);
    } catch (error) {
      navigate('/404');
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    error,
    promoBanner,
    getPromoBanner,
  };
}

export { usePromoBanner };
