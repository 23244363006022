import React from 'react';
import styles from './Pagination.module.scss';

function Pagination({ currentPage, totalPages, handlePaginate }) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  if (pageNumbers.length > 1) {
    return (
      <div className={styles.paginationContainer}>
        {pageNumbers.map((number) => (
          <span
            className={`${styles.paginationItem} ${
              number === currentPage ? styles.activePaginationItem : ''
            }`}
            key={number}
            onClick={() => handlePaginate(number)}
          >
            {number}
          </span>
        ))}
      </div>
    );
  }
}

export { Pagination };
