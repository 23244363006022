import { createPortal } from 'react-dom';

import { LoginModal } from './components/LoginModal';
import { BuyOneClickModal } from './components/BuyOneClickModal';
import { JewelryRepairModal } from './components/JewelryRepairModal';
import { JewelryMakingModal } from './components/JewelryMakingModal';
import { MessageModal } from './components/MessageModal';
import { AddOrderError } from './components/AddOrderError';
import { CookiesModal } from './components/CookiesModal';

function Modals() {
  return (
    <>
      {createPortal(<LoginModal />, document.body)}
      {createPortal(<BuyOneClickModal />, document.body)}
      {createPortal(<JewelryRepairModal />, document.body)}
      {createPortal(<JewelryMakingModal />, document.body)}
      {createPortal(<MessageModal />, document.body)}
      {createPortal(<AddOrderError />, document.body)}
      {createPortal(<CookiesModal />, document.body)}
    </>
  );
}

export { Modals };
