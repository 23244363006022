import React from 'react';
import styles from './FavoriteItems.module.scss';

import { Card } from 'entities/Card';

function FavoriteItems({ favorites }) {
  return (
    <div className={styles.favoriteListItems}>
      {favorites.map((item) => (
        <Card
          key={item.trade_offer}
          id={item.trade_offer}
          title={item.title}
          price={item.price}
          discount_price={item.price_with_discount}
          discount={item.discount}
          image={item.image}
          slug_url={item.slug_url}
          quantity={item.quantity}
          is_active={item.is_active}
          isInCart={item.is_in_cart}
          isInFavorite={item.is_in_favorites}
        />
      ))}
    </div>
  );
}

export { FavoriteItems };
