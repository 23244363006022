import React, { useState, useEffect } from 'react';
import styles from './news.module.scss';

import { scrollTop } from 'shared/lib/scrollTop';
import { useWindowSize } from 'shared/lib/windowSizeChecker';
import { usePromoBanners } from 'api/Banners/usePromoBanners';

import { NewsBanners } from 'widgets/Banners';
import { NewsBannersSkelleton } from 'shared/ui/Skelletons/NewsBanners';
import { Pagination } from 'entities/Pagination';
import { ErrorContainer } from 'widgets/ErrorContainer';

function News() {
  const windowSize = useWindowSize();
  const [key, setKey] = useState('');
  const { loading, error, promoBanners, getPromoBanners } = usePromoBanners();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentBanners, setCurrentBanners] = useState([]);
  const bannersPerPage = 5;

  // Изменяем текущую страницу
  function handlePaginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // Рассчеты для пагинации
  function calcPagination() {
    const lastOrderIndex = currentPage * bannersPerPage;
    const firstOrderIndex = lastOrderIndex - bannersPerPage;
    // Режем массив для отображения на странице
    setCurrentBanners(promoBanners.slice(firstOrderIndex, lastOrderIndex));
  }

  useEffect(() => {
    getPromoBanners();
  }, []);

  useEffect(() => {
    calcPagination();
    scrollTop();
  }, [promoBanners.length, currentPage]);

  // Подключаем разные размеры баннеров по условию
  // Меняю ключ в зависимости от размеров экрана
  useEffect(() => {
    if (!loading) {
      if (windowSize < 600) {
        setKey('image360x512');
      } else if (windowSize < 940) {
        setKey('image768x512');
      } else if (windowSize < 1280) {
        setKey('image1024x512');
      } else if (windowSize < 1700) {
        setKey('image1286x512');
      } else {
        setKey('image1840x512');
      }
    }
  }, [windowSize, loading]);

  if (loading) {
    return (
      <>
        <h1 className={styles.title}>Акции</h1>
        <div className={styles.bannerContainer}>
          <NewsBannersSkelleton />
        </div>
      </>
    );
  }

  if (!promoBanners.length || error) {
    return (
      <ErrorContainer
        title="Акции"
        message={
          error
            ? error
            : `В данный момент у нас нет действующих акций. Однако, Вы
                всегда можете списать часть бонусов со своей карты Grand
                Gold при покупке украшений!`
        }
      />
    );
  }

  return (
    <>
      <h1 className={styles.title}>Акции</h1>
      <div className={styles.bannersContainer}>
        {currentBanners.map((banner) => (
          <NewsBanners
            key={banner.id}
            image={banner[key]}
            url={banner.slug}
            title={banner.title}
            description={banner.text}
            loading={loading}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(promoBanners.length / bannersPerPage)}
        handlePaginate={handlePaginate}
      />
    </>
  );
}

export { News };
