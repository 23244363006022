export const seoMetadataMap = {
  // Хеддер ссылки ////////////////////////////////////////////////////////////////////////
  '/': { title: 'Главная страница Grand Gold', h1: '', description: '' },
  '/about/': { title: 'Контакты Grand Gold', h1: '', description: '' },
  '/catalog/': {
    title: 'Ювелирные изделия купить — цены в каталоге ювелирного магазина',
    h1: 'Каталог ювелирных изделий',
    description:
      'Новое поступление по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/otzyvy/': { title: 'Отзывы о Grand Gold', h1: '', description: '' },

  // Футтер ссылки ////////////////////////////////////////////////////////////////////////
  '/oplata/': {
    title: 'Оплата заказов в Grand Gold',
    h1: '',
    description: '',
  },
  '/dostavka/': {
    title: 'Информация о доставке в Grand Gold',
    h1: '',
    description: '',
  },
  '/vozvrat-obmen/': {
    title: 'Информация о возврате товара в Grand Gold',
    h1: '',
    description: '',
  },
  '/vacancy/': { title: 'Вакансии в Grand Gold', h1: '', description: '' },
  '/news/': {
    title: 'События и акции в Grand Gold',
    h1: '',
    description: '',
  },
  '/info/': { title: 'Grand Gold', h1: '', description: '' },
  '/vopros-otvet/': { title: 'Grand Gold', h1: '', description: '' },

  // СЕО ссылки ////////////////////////////////////////////////////////////////////////
  '/catalog/braslety/': {
    title: 'Купить браслет в ювелирном интернет-магазине Гранд',
    h1: 'Браслеты',
    description:
      'Браслеты по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/braslety/iz-zolota/': {
    title:
      'Купить золотой браслет женский на руку в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые браслеты',
    description:
      'Золотой браслет по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/braslety/iz-zolota/muzhskie/': {
    title:
      'Купить золотой браслет мужской в ювелирном интернет-магазине «Гранд»',
    h1: 'Мужские золотые браслеты',
    description:
      'Браслеты мужские по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/iz-zolota/': {
    title: 'Купить золотые украшения в ювелирном интернет-магазине «Гранд»',
    h1: 'Ювелирные украшения золотые',
    description: 'Ювелирные украшения золотые.',
  },
  '/catalog/koltsa/': {
    title: 'Купить женское кольцо в ювелирном интернет-магазине «Гранд»',
    h1: 'Кольца',
    description:
      'Кольца по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/iz-zolota/': {
    title:
      'Купить золотое кольцо с камнем в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые кольца с камнем',
    description:
      'Золотые кольца с камнями по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/iz-zolota/muzhskie/': {
    title:
      'Купить золотое кольцо мужское в ювелирном интернет-магазине «Гранд»',
    h1: 'Мужские золотые кольца',
    description:
      'Купить золотое мужское кольцо по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/iz-zolota/s-brilliantom/': {
    title:
      'Купить золотое кольцо женское с бриллиантом в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые кольца с бриллиантом',
    description:
      'Золотые кольца с бриллиантом по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/muzhskie/': {
    title: 'Купить мужское кольцо в ювелирном интернет-магазине «Гранд»',
    h1: 'Мужские кольца',
    description:
      'Кольца по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/s-ametistom/': {
    title: 'Купить кольцо с аметистом в ювелирном интернет-магазине «Гранд»',
    h1: 'Кольца с аметистом',
    description:
      'Кольца с аметистами, цитрином, фианитами, гранатом, корундами, опалом, родолитом, топазами, хризолитом или жемчугом.',
  },
  '/catalog/koltsa/s-brilliantom/': {
    title:
      'Купить женское кольцо с бриллиантом в ювелирном интернет-магазине «Гранд»',
    h1: 'Кольца с бриллиантом',
    description:
      'Кольца с бриллиантами, фианитами, изумрудом, корундами, рубином, сапфирами, топазами, хризолитом или жемчугом.',
  },
  '/catalog/koltsa/s-sapfirom/': {
    title: 'Купить кольцо с сапфиром в ювелирном интернет-магазине «Гранд»',
    h1: 'Кольца с сапфиром',
    description:
      'Кольца с бриллиантами, цирконом, фианитами, изумрудом, рубином или сапфирами.',
  },
  '/catalog/koltsa/obruchalnye/': {
    title:
      'Кольцо обручальное женское купить в ювелирном интернет-магазине «Гранд» — каталог свадебных колец',
    h1: 'Обручальные кольца',
    description:
      'Кольца Обручальные по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/sergi/': {
    title: 'Купить ювелирные серьги в интернет-магазине Гранд по выгодной цене',
    h1: 'Серьги',
    description:
      'Серьги по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/sergi/iz-zolota/': {
    title: 'Купить золотые серьги в ювелирном интернет-магазине',
    h1: 'Золотые серьги',
    description: 'Серьги золотые.',
  },
  '/catalog/tsepi/': {
    title:
      'Купить цепь ювелирную — цепочки в интернет-магазине Гранд по выгодным ценам',
    h1: 'Цепи',
    description:
      'Цепи по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/tsepi/iz-zolota/': {
    title:
      'Купить золотую цепочку женскую или мужскую в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые цепи',
    description:
      'Золотые цепи по выгодным ценам в Ювелирном салоне Grand Gold. Ювелирные изделия торгового дома Гранд в Нижнем Новгороде и Ростове-на-Дону. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/iz-zolota/s-kamnyami/': {
    title:
      'Купить золотое кольцо с камнем в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые кольца с камнем',
    description:
      'Золотые кольца с камнями по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями. Заказывайте по номеру 8(800)777-69-30.',
  },
  '/catalog/koltsa/iz-zolota/s-zhemchugom/': {
    title:
      'Золотое кольцо с жемчугом купить в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые кольца с жемчугом',
    description:
      'Кольца золотые с аметистами, бриллиантами, цитрином, фианитами, гранатом, корундами, опалом, родолитом, топазами, хризолитом или жемчугом.',
  },
  '/catalog/podveski/iz-zolota/': {
    title: 'Купить золотую подвеску в ювелирном интернет-магазине «Гранд»',
    h1: 'Золотые подвески',
    description: 'Подвески золотые.',
  },
  '/catalog/sergi/s-brilliantom/': {
    title: 'Купить ювелирные серьги с бриллиантами недорого',
    h1: 'Серьги с бриллиантами',
    description:
      'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/sergi/pusety/': {
    title: 'Серьги гвоздики — Купить пусеты в Санкт-Петербурге',
    h1: 'Серьги гвоздики (пусеты)',
    description: 'Купить пусеты в Санкт-Петербурге.',
  },
  '/catalog/kole/': {
    title: 'Купить колье из золота',
    h1: 'Колье из золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/kole/iz-zolota/': {
    title: 'Купить колье из золота',
    h1: 'Колье из золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/kole/iz-zolota/s-brilliantom/': {
    title: 'Купить золотое колье с бриллиантами в Санкт-Петербурге',
    h1: 'Золотые колье с бриллиантами',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/sergi/iz-zolota/s-kamnem/': {
    title: 'Купить золотые серьги с камнем',
    h1: 'Золотые серьги с камнем',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/koltsa/iz-zolota/bez-kamnej/': {
    title: 'Купить золотое кольцо без камней в Санкт-Петербурге',
    h1: 'Золотые кольца без камней',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/slitki/': {
    title: 'Купить золотой слиток — Католог слитков из золота в гр',
    h1: 'Золотые слитки ',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/koltsa/iz-krasnogo-zolota/': {
    title:
      ' Купить кольцо из красного золота в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Кольца из красного золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/braslety/iz-krasnogo-zolota/': {
    title:
      'Браслеты из красного золота купить в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Браслеты из красного золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/koltsa/iz-krasnogo-zolota/s-brilliantom/': {
    title:
      'Кольца из красного золота с бриллиантами купить в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Кольца из красного золота с бриллиантами',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/podveski/iz-krasnogo-zolota/s-brilliantom/': {
    title:
      'Подвески из красного золота с бриллиантами купить в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Подвески из красного золота с бриллиантами',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/kole/iz-krasnogo-zolota/': {
    title:
      'Колье из красного золота купить в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Колье из красного золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/sergi/iz-krasnogo-zolota/': {
    title: 'Купить серьги из красного золота в Санкт-Петербурге',
    h1: 'Серьги из красного золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/kole/iz-zheltogo-zolota/': {
    title:
      'Колье из желтого золота купить в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Колье из желтого золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
  '/catalog/koltsa/iz-kombinirovannogo-zolota/': {
    title:
      'Кольца из комбинированного золота — Купить кольца из белого и желтого золота в Санкт-Петербурге и Нижнем Новгороде',
    h1: 'Кольца из комбинорованного золота',
    // description:
    //     'Серьги с бриллиантами, эмалью, фианитами, сапфирами, топазами или жемчугом.',
  },
};
