import React, { useEffect, useState } from 'react';
import styles from './Payment.module.scss';

import { useOrder } from 'store/order';
import { paymentTypes } from '../model/paymentTypes';

function Payment() {
  const { payment_type, setPaymentType } = useOrder((state) => ({
    payment_type: state.payment_type,
    setPaymentType: state.setPaymentType,
  }));

  const [paymentState, setPaymentState] = useState(null);

  // Выбираем способ оплаты
  const onClickMethod = (type) => {
    setPaymentType(type.key);
    setPaymentState(type);
  };

  // Подтягиваем значение из стора, если оно было ранне установлено
  useEffect(() => {
    if (payment_type) {
      setPaymentState(paymentTypes.find((type) => type.key === payment_type));
    }
  }, []);

  return (
    <div className={styles.payment}>
      <h2 className={styles.title}>Способ оплаты</h2>
      <div className={styles.paymentMethods}>
        {paymentTypes.map((type, index) => (
          <div
            className={
              type === paymentState ? styles.active : styles.paymentMethod
            }
            onClick={() => onClickMethod(type)}
            key={index}
          >
            <h3>{type.title}</h3>
            <p>{type.subTitle}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export { Payment };
