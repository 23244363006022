import React from 'react';
import styles from './NotificationModal.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

const animation = {
  hidden: { y: 100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: { y: 100, opacity: 0 },
};

function NotificationModal({ active, title, message, children }) {
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={styles.modalContent}
          variants={animation}
          initial="hidden"
          animate="show"
          exit="exit"
          onClick={(event) => event.stopPropagation()}
        >
          {title && <h2 className={styles.title}>{title}</h2>}
          {message && <p className={styles.message}>{message}</p>}
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { NotificationModal };
