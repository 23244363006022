import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styles from '../../../Modals.module.scss';

import validatePhone from 'shared/lib/validatePhone';
import ReCAPTCHA from 'react-google-recaptcha';

import { useUser } from 'store/user';
import { useJewRepairModal } from '../lib/store';
import { useMessageModal } from '../../MessageModal/lib/store';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import { ModalLayout } from '../../ModalLayouts/StandartModal';
import { MainButton } from 'shared/ui/MainButton';
import { TelegramButton } from 'shared/ui/TelegramButton';
import { WatsappButton } from 'shared/ui/WatsappButton';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';
import useJewelryRepairModal from 'api/Modals/useJewelryRepairModal';

function JewelryRepairModal() {
  const recaptchaRef = useRef();
  const { first_name, phone_number } = useUser((state) => ({
    first_name: state.user.first_name,
    phone_number: state.user.phone,
  }));
  const { loading, error, onSubmitJewRepair } = useJewelryRepairModal();
  const [userName, setUserName] = useState(first_name || '');
  const [userPhoneNumber, setUserPhoneNumber] = useState(phone_number || '');

  const setActiveMessageModal = useMessageModal(
    (state) => state.setActiveModal,
  );
  const { active, setActiveJewelryRepairModal } = useJewRepairModal(
    (state) => ({
      active: state.active,
      setActiveJewelryRepairModal: state.setActiveModal,
    }),
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await onSubmitJewRepair(data, first_name, phone_number);
      setActiveJewelryRepairModal(false);
      setActiveMessageModal(true);
    }
  };

  return (
    <ModalLayout
      active={active}
      onClose={() => setActiveJewelryRepairModal(false)}
      title="Оставьте заявку, и мы"
      message="вернем Вашему изделию первозданный вид"
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="text"
              {...register('first_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите свое имя',
                },
              })}
              placeholder="Введите имя"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
          </label>
          <div className={styles.error}>
            {errors?.first_name && (
              <p>{errors?.first_name?.message || 'Ошибка'}</p>
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="tel"
              onPaste={onPhonePaste}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              {...register('phone_number', {
                required: 'Обязательно к заполнению',
                validate: (value) => validatePhone(value.trim()),
              })}
              placeholder="+7 (900) 123 45 67"
              value={userPhoneNumber}
              onChange={(event) => setUserPhoneNumber(event.target.value)}
            />
          </label>
          {error !== null ? (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          ) : (
            <div className={styles.error}>
              {errors?.phone_number && (
                <p>
                  {errors?.phone_number?.message || 'Кажется, здесь ошибка'}
                </p>
              )}
            </div>
          )}
        </div>
        <MainButton type="submit" text="Отправить" loading={loading} />
        <div className={styles.buttonsContainer}>
          <TelegramButton />
          <WatsappButton />
        </div>
        <div className={styles.alignCenter}>
          <PrivacyPolicy />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_CAPTHA_KEY}
          size="invisible"
        />
      </form>
    </ModalLayout>
  );
}

export { JewelryRepairModal };
