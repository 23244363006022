import React, { useEffect, useState } from 'react';
import styles from './YaMap.module.scss';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'shared/lib/windowSizeChecker';
import {
  mapLocation,
  mapStoreMarkers,
  mapJewsMarkers,
} from '../model/YaMapHelpers';
import {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapComponentsProvider,
  YMapZoomControl,
  YMapControls,
  YMapGeolocationControl,
  YMapMarker,
} from 'ymap3-components';

import { BsFillGeoAltFill } from 'react-icons/bs';

function YaMap({ mapType }) {
  const mapMarkers = mapType === 'jews' ? mapJewsMarkers : mapStoreMarkers;

  const [location, setLocation] = useState(mapLocation);
  const [zoom, setZoom] = useState(12);

  const navigate = useNavigate();
  const windowSize = useWindowSize();

  // Приближаем карту к выбранному маркеру
  function handleSelectMarker(coordinates) {
    setLocation(coordinates);
    setZoom(15);
  }

  // Переводим на страницу филиала
  function handleDoubleSelectMarker(link) {
    navigate(link);
  }

  useEffect(() => {
    if (windowSize <= 768) {
      setZoom(11);
    }
  }, []);

  return (
    <div className={styles.map}>
      <YMapComponentsProvider apiKey={process.env.REACT_APP_YAMAP_KEY}>
        <YMap
          location={{ center: location, zoom: zoom }}
          behaviors={['drag', 'pinchZoom', 'scrollZoom: false', 'pinchRotate']}
        >
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />
          <YMapControls position="right">
            <YMapZoomControl />
          </YMapControls>
          <YMapControls position="top right">
            <YMapGeolocationControl />
          </YMapControls>
          {mapMarkers.map((marker, index) => (
            <YMapMarker
              key={index}
              coordinates={marker.coordinates}
              onClick={() => handleSelectMarker(marker.coordinates)}
              onDoubleClick={() => handleDoubleSelectMarker(marker.link)}
            >
              <div className={styles.markerContainer}>
                <BsFillGeoAltFill size={36} color="#dbb153" />
                <p className={styles.markerText}>{marker.text}</p>
              </div>
            </YMapMarker>
          ))}
        </YMap>
      </YMapComponentsProvider>
    </div>
  );
}

export { YaMap };
