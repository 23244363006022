import { create } from 'zustand';

let modal = (set) => ({
  active: false,
  setActiveModal: (param) => {
    set({ active: param });
  },
  items: '',
  setItems: (id) => {
    set({ items: id });
  },
  removeItems: () => {
    set({ items: '' });
  },
});

export const useBuyOneClickModal = create(modal);
