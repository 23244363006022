import { useState } from 'react';
import $api from '../../app/axios/axiosConfig';
import { hashPhoneNumber } from 'shared/lib/hashPhoneNumber';

export default function useResendSMSCode() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const resendCode = async (link, phone_number) => {
    setLoading(true);

    try {
      const response = await $api.post(link, {
        phone_number: phone_number,
        google_metric_id: hashPhoneNumber(phone_number),
        type_of_auth: 'sms',
      });

      if (response.status !== 200) {
        throw new Error('Ошибка при повторной отправке кода');
      }
    } catch (error) {
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, resendCode };
}
