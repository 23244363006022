export function transliterateForwhomParam(param) {
  const transliterateForwhomParam = {
    muzhskie: 'Мужские',
    // Фильтр может принять больше вариантов параметров, но
    // сеошники пока не дали свои сеошные названия
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterateForwhomParam.hasOwnProperty(param)) {
    return transliterateForwhomParam[param];
  } else {
    return undefined;
  }
}
