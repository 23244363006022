import React from 'react';
import styles from '../../../RegisterPage.module.scss';
import { Link } from 'react-router-dom';

import { useUser } from 'store/user';

import RegForm from 'entities/Auth/Register/RegForm';

function SignUpBySMSPage() {
  const phone_number = useUser((state) => state.user.phone_number);

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Регистрация</h2>
      <p className={styles.text}>
        Сейчас Вам придет СМС{' '}
        {phone_number && (
          <span>
            на номер <br /> {phone_number}{' '}
            <Link to="/register/sms" className={styles.changeNumberLink}>
              Изменить
            </Link>
          </span>
        )}
      </p>
      <RegForm type="sms" />
    </div>
  );
}

export { SignUpBySMSPage };
