import sha256 from 'crypto-js/sha256';

export function hashPhoneNumber(tel) {
  const clearTel = tel.replace(/\D/g, '');
  const digits = clearTel.match(/\d/g) || [];
  const sum = digits.reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  const lastDigit = (sum % 10).toString();
  const finalValue = clearTel + lastDigit;

  const hashedValue = sha256(finalValue.toString()).toString();

  return hashedValue;
}
