import React, { useRef, useState } from 'react';
import styles from '../../../Modals.module.scss';

import { useForm } from 'react-hook-form';
import validatePhone from 'shared/lib/validatePhone';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import ReCAPTCHA from 'react-google-recaptcha';
import { useBuyOneClickModal } from '../lib/store';
import { useMessageModal } from 'entities/Modal/ui/components/MessageModal/lib/store';
import { useUser } from 'store/user';

import { ModalLayout } from '../../ModalLayouts/StandartModal';
import { MainButton } from 'shared/ui/MainButton';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';
import useOneClickModal from 'api/Modals/useOneClickModal';
import { Checkbox } from 'shared/ui/Checkbox';

function BuyOneClickModal() {
  const { first_name, phone_number } = useUser((state) => ({
    first_name: state.user.first_name,
    phone_number: state.user.phone,
  }));
  const { loading, error, onSubmitOneClick } = useOneClickModal();
  const [userName, setUserName] = useState(first_name || '');
  const [userPhoneNumber, setUserPhoneNumber] = useState(phone_number || '');
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const recaptchaRef = useRef();

  const { active, setActiveButOneClickModal, items, removeItems } =
    useBuyOneClickModal((state) => ({
      active: state.active,
      setActiveButOneClickModal: state.setActiveModal,
      items: state.items,
      removeItems: state.removeItems,
    }));

  const setActiveMessageModal = useMessageModal(
    (state) => state.setActiveModal,
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  async function onSubmit(data) {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await onSubmitOneClick(data, first_name, phone_number, isWhatsApp, items);
      setActiveButOneClickModal(false);
      setActiveMessageModal(true);
      removeItems();
    }
  }

  return (
    <ModalLayout
      active={active}
      onClose={() => setActiveButOneClickModal(false)}
      title="Мы поможем быстро оформить заказ"
      message="Для этого просто оставьте свои данные"
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="text"
              {...register('first_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите свое имя',
                },
              })}
              placeholder="Введите имя"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
          </label>
          <div className={styles.error}>
            {errors?.first_name && (
              <p>{errors?.first_name?.message || 'Ошибка'}</p>
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="tel"
              onPaste={onPhonePaste}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              {...register('phone_number', {
                required: 'Обязательно к заполнению',
                validate: (value) => validatePhone(value.trim()),
              })}
              placeholder="+7 (900) 123 45 67"
              value={userPhoneNumber}
              onChange={(event) => setUserPhoneNumber(event.target.value)}
            />
          </label>
          {error !== null ? (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          ) : (
            <div className={styles.error}>
              {errors?.phone_number && (
                <p>
                  {errors?.phone_number?.message || 'Кажется, здесь ошибка'}
                </p>
              )}
            </div>
          )}
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            label="Получить ответ на WhatsApp"
            checked={isWhatsApp}
            handleChange={(event) => setIsWhatsApp(event.target.checked)}
          />
        </div>
        <MainButton type="submit" text="Отправить" loading={loading} />
        <div className={styles.alignCenter}>
          <PrivacyPolicy />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_CAPTHA_KEY}
          size="invisible"
        />
      </form>
    </ModalLayout>
  );
}

export { BuyOneClickModal };
