import React, { useEffect, useState } from 'react';
import styles from '../../../Filter.module.scss';

import { useFilters } from 'store/filters';
import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { MainButton } from 'shared/ui/MainButton';
import { SecondaryButton } from 'shared/ui/SecondaryButton';

function PriceFilter() {
  const { minPrice, maxPrice, setFilters } = useFilters((state) => ({
    minPrice: state.filters.minPrice,
    maxPrice: state.filters.maxPrice,
    setFilters: state.setFilters,
  }));
  const [minPriceState, setMinPriceState] = useState(minPrice);
  const [maxPriceState, setMaxPriceState] = useState(maxPrice);

  const handleSubmitPrice = () => {
    setFilters('minPrice', minPriceState);
    setFilters('maxPrice', maxPriceState);
  };

  const handleResetPrice = () => {
    setFilters('minPrice', '');
    setFilters('maxPrice', '');
    setMinPriceState('');
    setMaxPriceState('');
  };

  // Обращаемся к стору, чтобы всегда иметь акруальное состояние
  useEffect(() => {
    if (minPrice || maxPrice) {
      setFilters('minPriceData', minPrice);
      setFilters('maxPriceData', maxPrice);
      setMinPriceState(minPrice);
      setMaxPriceState(maxPrice);
    } else {
      setMinPriceState('');
      setMaxPriceState('');
    }
  }, [minPrice, maxPrice]);

  //Управляю состоянием инпутов
  useEffect(() => {
    if (minPriceState < 0) {
      setMinPriceState('');
    }
  }, [minPriceState, maxPriceState]);

  return (
    <FilterDropdown title="Цена изделия" isOpen={minPrice || maxPrice}>
      <div className={styles.contentContainer}>
        <label className={styles.inputContainer}>
          <span className={styles.labelText}>От</span>
          <input
            className={styles.input}
            type="number"
            value={minPriceState}
            onChange={(event) => setMinPriceState(event.target.value)}
          />
          <span className={styles.labelText}>руб.</span>
        </label>
        <label className={styles.inputContainer}>
          <span className={styles.labelText}>До</span>
          <input
            className={styles.input}
            type="number"
            value={maxPriceState}
            onChange={(event) => setMaxPriceState(event.target.value)}
          />
          <span className={styles.labelText}>руб.</span>
        </label>

        <MainButton
          text="Применить"
          onClick={handleSubmitPrice}
          disabled={!minPriceState && !maxPriceState}
        />

        {minPrice || maxPrice ? (
          <SecondaryButton text="Сбросить фильтр" onClick={handleResetPrice} />
        ) : null}
      </div>
    </FilterDropdown>
  );
}

export { PriceFilter };
