import React, { useState, useEffect } from 'react';
import styles from './Checkbox.module.scss';

function Checkbox({ label, handleChange, checked }) {
  const [isChecked, setIsChecked] = useState(checked || false);

  //Обновляю isChecked при изменении checked
  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    handleChange(event);
  };

  return (
    <label className={styles.checkboxContainer}>
      <div className={styles.inputContainer}>
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={checked || isChecked}
          onChange={(event) => handleCheckboxChange(event)}
        />
        <span className={styles.checkmark}></span>
      </div>
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
}

export { Checkbox };
