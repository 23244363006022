import React from 'react';
import styles from './Offers.module.scss';
import { Link } from 'react-router-dom';

import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';
import getActualPrice from 'shared/lib/getActualPrice';

import OffersDropdown from './components/OffersDropdown';

export default function Offers({ offers }) {
  if (offers.length) {
    return (
      <div className={styles.offers}>
        <OffersDropdown title="Другие варианты товара">
          <div className={styles.offersContainer}>
            {offers.map((offer, index) => (
              <Link
                className={styles.linkContainer}
                to={`/catalog/product/${offer.slug_url}`}
                key={offer.series}
              >
                <ol className={styles.offerList}>
                  <li className={styles.offerListItem}>{`${index + 1}.`}</li>
                  <div className={styles.offerListComtainer}>
                    <li className={styles.offerListItem}>
                      {`Артикул: ${offer.series.substr(7)}`}
                    </li>
                    <li className={styles.offerListItem}>
                      {`Вес: ${offer.weight} гр.`}
                    </li>
                    <li className={styles.offerListItem}>
                      {offer.size ? `Размер: ${offer.size}` : null}
                    </li>
                    <li className={styles.offerItem}>
                      {`Цена: ${toCurrency.format(
                        offer.discount
                          ? roundPrice(
                              getActualPrice(offer.price, offer.discount),
                            )
                          : offer.price,
                      )} руб.`}
                    </li>
                  </div>
                </ol>
              </Link>
            ))}
          </div>
        </OffersDropdown>
      </div>
    );
  }
}
