import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Search.module.scss';
import qs from 'qs';

import { useSearch } from 'store/search';
import { useCatalogPagination } from 'store/pagination';

import { GoXCircleFill } from 'react-icons/go';
import { BsSearch } from 'react-icons/bs';
import { useFilters } from 'store/filters';

function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const URL = window.location.search;
  const params = qs.parse(URL.substring(1));

  const [value, setValue] = useState('');

  const setSearch = useSearch((state) => state.setSearch);
  const setPagination = useCatalogPagination((state) => state.setPagination);
  const resetFilters = useFilters((state) => state.resetFilters);

  // Выполняем поиск
  const onFormSubmit = (event) => {
    event.preventDefault();
    setSearch(value);
    resetFilters();

    if (value !== '') {
      setPagination(1);
      // Переходим в каталог, если мы еще не там
      if (location.pathname !== '/catalog/') {
        navigate('/catalog/');
      }
    }
  };

  const onResetSearch = () => {
    setSearch(null);
    setValue('');
  };

  // Проверяем URL, на наличие параметра
  useEffect(() => {
    if (params.search !== undefined && params.search !== null)
      setValue(params.search);
  }, []);

  return (
    <form className={styles.search} onSubmit={onFormSubmit}>
      <BsSearch className={styles.icon} onClick={onFormSubmit} />
      <input
        className={styles.searchInput}
        type="text"
        placeholder="Поиск по каталогу"
        onChange={(event) => setValue(event.target.value.trim())}
        value={value}
      />
      {value !== '' ? (
        <GoXCircleFill className={styles.closeButton} onClick={onResetSearch} />
      ) : null}
    </form>
  );
}

export { Search };
