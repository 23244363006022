const wordsDeclention = (value, words) => {
  value = Math.abs(value) % 100;
  let number = value % 10;

  if (value >= 11 && value <= 19) {
    return words[2];
  }

  if (number === 1) {
    return words[0];
  }

  if (number >= 2 && number <= 4) {
    return words[1];
  }

  return words[2];
};

export default wordsDeclention;
