import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';

import { useNavbar } from './Burger/lib/store';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

import { HeaderLogo } from 'widgets/Header/ui/HeaderLogo';
import { Navbar } from 'widgets/Header/ui/Navbar';
import { CartButton } from 'widgets/Header/ui/CartButton';
import { LkButton } from 'widgets/Header/ui/LkButton';
import { FavoriteButton } from 'widgets/Header/ui/FavoriteButton';
import { Burger } from 'widgets/Header/ui/Burger';
import { Search } from 'entities/Search';

function Header() {
  const setNavbarState = useNavbar((state) => state.setNavbarState);
  const [laptop, setLaptop] = useState(false);
  const [mobile, setMobile] = useState(false);

  //СЛЕДИМ ЗА РАЗМЕРОМ ЭКРАНА
  const windowSize = useWindowSize();

  //Изменяем расположение поиска
  useEffect(() => {
    if (windowSize <= 1280) {
      setLaptop(true);
    } else setLaptop(false);

    if (windowSize <= 600) {
      setMobile(true);
    } else setMobile(false);

    if (windowSize > 1280) {
      setNavbarState(false);
    }
  }, [windowSize]);

  if (mobile) {
    return (
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <HeaderLogo />
          <Navbar />
          <Search />
          <div className={styles.buttons}>
            <FavoriteButton />
            <LkButton />
            <CartButton />
            {laptop ? <Burger /> : null}
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <HeaderLogo />
        <Navbar />
        <div className={styles.buttons}>
          <Search />
          <FavoriteButton />
          <LkButton />
          <CartButton />
          {laptop ? <Burger /> : null}
        </div>
      </div>
    </header>
  );
}

export { Header };
