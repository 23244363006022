import { create } from 'zustand';

let modal = (set) => ({
  active: false,
  items: null,
  setActiveModal: (param) => {
    set({ active: param });
  },
  setItems: (items) => {
    set({ items: items });
  },
});

export const useJewMakingModalStore = create(modal);
