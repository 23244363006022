import React from 'react';
import styles from './CastomTooltip.module.scss';

import { GoInfo } from 'react-icons/go';
import { Tooltip } from 'react-tooltip';

function CastomTooltip({ text }) {
  if (text) {
    return (
      <div className={styles.tooltipContainer}>
        {/* eslint-disable-next-line */}
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-offset={20}
          data-tooltip-content={text}
        >
          <GoInfo className={styles.hintIcon} />
        </a>
        <Tooltip id="my-tooltip" className={styles.tooltip} />
      </div>
    );
  }
}

export { CastomTooltip };
