import React from 'react';
import styles from './CounterSkelleton.module.scss';

function CounterSkelleton() {
  return (
    <div className={styles.loaderContainer}>
      <span className={styles.loader}></span>
      <span>товаров</span>
    </div>
  );
}

export { CounterSkelleton };
