import React from 'react';
import styles from './FaqSkelleton.module.scss';

function FaqSkelleton() {
  return (
    <>
      <div className={styles.skelleton}></div>
      <div className={styles.skelleton}></div>
      <div className={styles.skelleton}></div>
      <div className={styles.skelleton}></div>
    </>
  );
}

export { FaqSkelleton };
