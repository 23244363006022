const stores = [
  {
    name: 'ул. Долгополова, 18',
    value: 'г.Н.Новгород,ул.Долгополова,д.18/36',
    worktimeSart: '9:00',
    worktimeEnd: '20:00',
  },
  {
    name: 'ул. Родионова, 17',
    value: 'г.Н.Новгород, ул.Родионова,д.17, пом.12',
    worktimeSart: '9:00',
    worktimeEnd: '20:00',
  },
  {
    name: 'пр-т Гагарина, 66',
    value: 'г.Н.Новгород, пр.Гагарина,д.66, пом.3',
    worktimeSart: '9:00',
    worktimeEnd: '20:00',
  },
  {
    name: 'Юбилейный бульвар, 6',
    value: 'Нижний Новгород, Юбилейный бульвар, 6',
    worktimeSart: '9:00',
    worktimeEnd: '20:00',
  },
];

export { stores };
