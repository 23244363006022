import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LinkButton.module.scss';

function LinkButton({ text, link, onClick }) {
  return (
    <div className={styles.linkButton} onClick={onClick}>
      <Link to={link}>{text}</Link>
    </div>
  );
}

export { LinkButton };
