import React from 'react';
import styles from '../../../LoginPage.module.scss';
import { Link } from 'react-router-dom';

import { LoginCodeForm } from 'entities/Auth/Login/LoginCodeForm';

function SignInPage() {
  const phone_number = localStorage.getItem('phone_number');

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Вход</h2>
      <p className={styles.text}>
        Сейчас Вам поступит звонок{' '}
        {phone_number && (
          <span>
            на номер <br /> {phone_number}{' '}
            <Link to="/login" className={styles.changeNumberLink}>
              Изменить
            </Link>
          </span>
        )}
      </p>
      <p className={styles.text}>Автоответчик продиктует код</p>
      <LoginCodeForm type="call" />
    </div>
  );
}

export { SignInPage };
