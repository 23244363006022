import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { typeStore } from '../../../../model/typeStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function TypeFilter() {
  const { type, setFilters } = useFilters((state) => ({
    type: state.filters.trade_offer_type || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (value === type) {
      setFilters('trade_offer_type', '');
    } else {
      setFilters('trade_offer_type', value);
    }
  };

  return (
    <FilterDropdown title="Вид изделия" isOpen={!!type}>
      <div className={styles.contentContainer}>
        {typeStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={type.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { TypeFilter };
