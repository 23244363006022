import { create } from 'zustand';

let search = (set) => ({
  search: '',
  setSearch: (param) => {
    set({ search: param });
  },
});

export const useSearch = create(search);
