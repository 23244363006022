export const itemsBrand = [
  {
    id: 1,
    title: 'Якорное плетение',
    img: 'yakornoe.webp',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 2,
    title: 'Плетение Фигаро',
    img: 'figaro.webp',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 3,
    title: 'Московский бит',
    img: 'msk-bit.jpg',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 4,
    title: 'Плетение Бисмарк',
    img: 'bismark.webp',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 5,
    title: 'Плетение Питон',
    img: 'piton.webp',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 6,
    title: 'Бусы',
    img: 'busy.jpg',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 7,
    title: 'Плетение Веревка',
    img: 'verevka.jpg',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 8,
    title: 'С камнями или с эмалью',
    img: 'skamnyami.jpg',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
  {
    id: 9,
    title: 'Венецианское плетение',
    img: 'hvost.webp',
    url: '/catalog?page=1&sort=-id&type=Наше&category=Цепи',
  },
];
