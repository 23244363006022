import styles from './NotFoundPage.module.scss';

import { ErrorContainer } from 'widgets/ErrorContainer';

function NotFoundPage() {
  return (
    <div className={styles.notFoundPage}>
      <ErrorContainer
        title="Такой страницы не найдено"
        message="Пожалуйста проверьте правильность написания ссылки, возможно Вы
                допустили ошибку. Если Вы искали какой-то товар, перейдите в
                Каталог, воспользуйтесь фильтром или поиском"
      />
    </div>
  );
}

export { NotFoundPage };
