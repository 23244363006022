import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function usePromoBanners() {
  const [promoBanners, setPromoBanners] = useState([]);
  const [activePromoBanners, setActivePromoBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function getPromoBanners() {
    setLoading(true);

    try {
      const response = await $api.get(urls.getPromoBanners);
      const banners = response.data;
      setPromoBanners(banners);
      setActivePromoBanners(banners.filter((banner) => banner.add_to_main));
      setError(null);
    } catch (error) {
      console.log(error);
      setError(
        'Что-то пошло не так! Вы можете связаться с нами, чтобы сообщить об этой ошибке. Так Вы поможете нам сделать сайт лучше!',
      );
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    error,
    promoBanners,
    activePromoBanners,
    getPromoBanners,
  };
}

export { usePromoBanners };
