import React from 'react';
import styles from './info.module.scss';

import { InfoDropdown } from 'shared/ui/InfoDropdown';

function Info() {
  return (
    <div className={styles.info}>
      <h1 className={styles.infoMainTitle}>Полезная информация</h1>
      <section className={styles.infoContainer} key="1">
        <InfoDropdown title="Публичная оферта">
          <div className={styles.blockInfo}>
            <h3>Публичная оферта ИП Манеляк Л.А.</h3>
            <div className={styles.infoDescription}>
              <h3>1. Термины и определения</h3>
              <p className={styles.infoParagraph}>
                1.1. Интернет-магазин - торговая площадка продавца,
                расположенная в сети интернет по адресу grand.gold, имеющая в
                своем контенте каталог предлагаемых для продажи товаров с
                указанием описания и цен - виртуальную витрину и интерактивный
                механизм оформления заказа на эти товары.
                <br /> 1.2. Сайт - совокупность электронных данных, доступных
                для просмотра в сети интернет в домене grand.gold.
                <br /> 1.3. Продавец –Индивидуальный предприниматель Манеляк
                Л.А.
                <br /> 1.4. Клиент - полностью дееспособное физическое лицо,
                размещающее заказы на сайте grand.gold, либо указанное в
                качестве получателя товара, либо использующее товары,
                приобретенные на сайте grand.gold, исключительно для личных,
                семейных, домашних и иных нужд, не связанных с осуществлением
                предпринимательской деятельности.
                <br />
                1.5. Товар - объект материального мира, не изъятый из
                гражданского оборота и представленный к продаже на сайте.
                <br /> 1.6. Заказ - должным образом оформленный запрос клиента
                на доставку по указанному адресу или получение товаров в
                розничных магазинах или пунктах самовывоза товаров, выбранных на
                сайте.
                <br /> 1.7. Личный кабинет клиента - это «закрытая», доступная
                только по логину и паролю, зона на интернет-портале компании,
                которая объединяет набор информационных блоков и адресных
                сервисов для конкретного клиента.
                <br />
                1.8. Служба доставки - третье лицо, оказывающее по договору с
                продавцом услуги по доставке заказов клиентам.
                <br /> 1.9. Акция – это мероприятие, направленное на увеличение
                объемов продаж, на привлечение внимания потребителей к
                продукции, на упрочение положения организации на рынке. Акции на
                сайте публикуются в форме: подарков, призов и скидок.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>2. Общие положения</h3>
              <p className={styles.infoParagraph}>
                2.1. Сайт принадлежит и администрируетсяМанеляк Л.А.
                <br /> 2.2. Заказывая товары через интернет-магазин, клиент
                соглашается с условиями настоящего предложения (далее -
                Предложение), изложенными ниже.
                <br /> 2.3. Настоящее Предложение, а также информация о товаре,
                представленная на сайте, являются публичной офертой в
                соответствии со ст.435 и ч.2 ст.437 ГК РФ.
                <br />
                2.4. К отношениям между клиентом и продавцом применяются
                положения ГК РФ о розничной купле-продаже (§2 глава 30), а также
                Закон РФ «О защите прав потребителей» от 07.02.1992 №2300-1 и
                иные правовые акты, принятые в соответствии с ними.
                <br />
                2.5. Продавец оставляет за собой право вносить изменения в
                условия настоящего Предложения, в связи с чем, клиент обязуется
                самостоятельно отслеживать подобные изменения.
                <br />
                2.6. Клиент соглашается с условиями настоящего Предложения
                нажатием галочки «Согласен с условиями оферты» на последнем
                этапе оформления заказа на сайте.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>3. Регистрация на сайте</h3>
              <p className={styles.infoParagraph}>
                3.1. Для оформления заказа и получения возможности использования
                личного кабинета клиенту необходимо зарегистрироваться на сайте.
                <br />
                3.2. При регистрации на сайте и получения возможности
                использования личного кабинета клиент предоставляет следующую
                информацию о себе: фамилию, имя, отчество, адрес электронной
                почты, пароль для доступа к сайту.
                <br />
                3.3. Продавец не несет ответственность за точность и
                правильность информации, предоставляемой клиентом при
                регистрации.
                <br />
                3.4. Клиент обязуется не сообщать третьим лицам логин и пароль,
                указанные при регистрации на сайте. В случае возникновения у
                клиента подозрений относительно безопасности его логина и пароля
                или возможности их несанкционированного использования третьими
                лицами, клиент обязуется незамедлительно уведомить об этом
                продавца, направив электронное письмо в разделе «Контакты».
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>4. Оформление и сроки выполнения заказа</h3>
              <p className={styles.infoParagraph}>
                4.1. Заказ клиента может быть оформлен следующими способами:
                принят по телефону или оформлен клиентом самостоятельно на
                сайте. Подробности оформления заказа описаны в разделе
                «Оформление заказа».
                <br />
                4.2. При оформлении заказа клиент должен указать следующую
                информацию: - Ф.И.О. клиента либо получателя заказа; - адрес
                доставки заказа; - адрес электронной почты - контактный телефон.
                <br />
                4.3 В случае, если покупатель передает продавцу сообщение о
                своем намерении приобрести товар, в обратном сообщении (заказе),
                должны быть обязательно указаны: а) полное фирменное
                наименование (наименование) и адрес (место нахождения) продавца,
                фамилия, имя, отчество покупателя или указанного им лица
                (получателя), адрес, по которому следует доставить товар; б)
                наименование товара, артикул, марка, разновидность, количество
                предметов, входящих в комплект приобретаемого товара, цена
                товара; в) вид услуги (при предоставлении), время ее исполнения
                и стоимость;
                <br />
                4.3.1. После оформления заказа клиенту предоставляется
                информация об ожидаемой дате передачи заказа в службу доставки.
                Указанная дата зависит от наличия заказанных товаров на складе
                продавца и времени, необходимого на обработку заказа.
                <br />
                4.4. Если клиентом оформлен заказ на товар в количестве,
                отсутствующем на складе продавца, продавец информирует об этом
                клиента посредством направления информационного сообщения.
                Сообщение направляется по электронному адресу, указанному
                клиентом при регистрации, либо на телефонный номер, указанный
                при оформлении заказа. Клиент вправе согласиться принять товар в
                количестве, имеющемся в наличии у продавца, либо аннулировать
                данную позицию товара из заказа. В случае неполучения ответа
                клиента в течение 14 дней продавец оставляет за собой право
                аннулировать данный товар из заказа.
                <br />
                4.4.1. Продавец вправе аннулировать заказы клиента, содержащие
                товары, от которых ранее клиент отказался 5 и более раз, указав
                причины, не связанные с наличием недостатков в этих товарах.
                <br />
                4.4.2. Продавец вправе отказать в заключении договора и
                оформлении заказа, если у клиента уже оформлены и не получены
                другие заказы на общую сумму от 50 000 рублей.
                <br />
                4.5. Конечные сроки получения заказа клиентом зависят от адреса
                и региона доставки, работы конкретной службы доставки, и не
                регулируются продавцом.
                <br />
                4.6. Все информационные материалы, представленные на сайте,
                носят справочный характер и не могут в полной мере передавать
                достоверную информацию о свойствах и характеристиках товара,
                включая цвета, размеры и формы. В случае возникновения у клиента
                вопросов, касающихся свойств и характеристик товара, перед
                оформлением заказа, клиент должен обратиться к продавцу.
                <br />
                4.6.1. Продавец должен до заключения договора розничной
                купли-продажи предоставить покупателю информацию об основных
                потребительских свойствах товара и адресе (месте нахождения)
                продавца, о месте изготовления товара, полном фирменном
                наименовании (наименовании) продавца, о цене и об условиях
                приобретения товара, о его доставке, сроке службы, сроке
                годности и гарантийном сроке, о порядке оплаты товара.
                <br />
                4.7. В случае отсутствия заказанных товаров на складе продавца,
                в том числе по причинам, не зависящим от продавца, продавец
                вправе аннулировать указанный товар из заказа клиента и
                уведомить об этом клиента путем направления электронного
                сообщения по адресу, указанному при регистрации, либо на
                телефонный номер, указанный при оформлении заказа.
                <br />
                4.8. В случае заказа товара с сайта и оформлении доставки, товар
                оплачивается клиентом полностью. После этого происходит отгрузка
                товара в транспортную компанию.
                <br />
                4.9. Приобретение товаров по Акции.
                <br />
                4.9.1. При приобретении товаров по Акции, клиент принимает на
                себя обязанности ознакомления с Правилами проведения Акции на
                сайте.
                <br />
                4.9.2. Клиент самостоятельно выступает исполнителем по налоговым
                обязательствам перед ФНС, предусмотренные п.1 ст.210 НК РФ и
                самостоятельно уплачивает НДФЛ, стоимость подарка или приза,
                полученного по Акции, превышающего стоимость 4000 (четыре
                тысячи) рублей.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>5. Доставка</h3>
              <p className={styles.infoParagraph}>
                5.1. Способы доставки товаров указаны на сайте.
                <br />
                5.2. Продавец приложит все усилия для соблюдения сроков
                доставки, указанных на сайте, тем не менее, задержки в доставке
                возможны ввиду непредвиденных обстоятельств, произошедших не по
                вине продавца.
                <br />
                5.3. Риск случайной гибели или случайного повреждения товара
                переходит к клиенту с момента передачи ему заказа и проставления
                получателем заказа подписи в документах, подтверждающих доставку
                заказа. В случае недоставки заказа продавец возмещает клиенту
                стоимость предоплаченного клиентом заказа и доставки после
                получения подтверждения утраты заказа от службы доставки.
                <br />
                5.4. Стоимость доставки каждого заказа рассчитывается
                индивидуально, исходя из его веса, объема, региона и способа
                доставки, а иногда и формы оплаты. При условии самовывоза товара
                клиентом из пункта выдачи устанавливается плата, которая
                включает в себя расходы продавца на доставку товара со склада до
                места нахождения пункта выдачи. Плата за доставку товара до
                места нахождения пункта выдачи заказа рассчитывается
                индивидуально, исходя из его веса, объема и региона доставки.
                <br />
                5.5. При доставке заказ вручается клиенту либо лицу, указанному
                в качестве получателя заказа.
                <br />
                5.6. Во избежание случаев мошенничества, при вручении
                предоплаченного заказа лицо, осуществляющее доставку заказа,
                вправе затребовать документ, удостоверяющий личность получателя,
                а также указать тип и номер предоставленного получателем
                документа на квитанции к заказу. Продавец гарантирует
                конфиденциальность и защиту персональной информации получателя.
                <br />
                5.7. При передаче заказа клиент должен проверить внешний вид и
                упаковку заказа, количество товара в заказе, комплектность,
                ассортимент.
                <br />
                5.8. Сроки, отведенные продавцом для получения заказа клиентом,
                носят ориентированный характер и указываются при оформлении
                заказа, на этапе выбора соответствующего способа доставки. Срок
                доставки зависит от выбранной клиентом службы доставки
                (перевозчика). При наличии товара на складе продавец обязуется
                передать товар в службу доставки в течение 20 календарных дней с
                момента оформления соответствующего заказа клиентом.
                <br />
                5.9. Неполучение заказа клиентом в течение 15 дней с момента его
                поступления в пункт доставки, указанный клиентом, считается
                отказом клиента от договора купли-продажи и является основанием
                для аннулирования заказа продавцом. Если неполученный заказ был
                предоплачен, денежные средства возвращаются клиенту в порядке,
                обратном порядку получения денежных средств продавцом.
                <br />
                5.10. В случае, если доставка товара произведена в установленные
                договором сроки, но товар не был передан покупателю по его вине,
                последующая доставка производится в новые сроки, согласованные с
                продавцом, после повторной оплаты покупателем стоимости услуг по
                доставке товара
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>6. Оплата товара</h3>
              <p className={styles.infoParagraph}>
                6.1. Цена товара указывается на сайте. В случае неверного
                указания цены заказанного клиентом товара, продавец при первой
                возможности информирует об этом клиента для подтверждения заказа
                по исправленной цене либо аннулирования заказа. При
                невозможности связаться с клиентом данный заказ считается
                аннулированным.
                <br />
                6.2. Цена товара, конечная стоимость и окончательная сумма
                заказа, указывается на последнем этапе оформления заказа и
                действительна на момент нажатия кнопки «Оформить заказ». При
                этом цена на заказанный клиентом товар изменению не подлежит.
                <br />
                6.3. Способы оплаты товара указаны на сайте в разделе «Оплата и
                доставка». Согласованным способом оплаты считается способ,
                выбранный клиентом из доступных способов оплаты при оформлении
                заказа.
                <br />
                6.4. При предоплате товаров заказ принимается в обработку только
                после зачисления денежных средств клиента на расчетный счет
                продавца. При этом товар под заказ не резервируется, и продавец
                не может гарантировать доступность товара на складе продавца,
                указанную в момент оформления заказа, как следствие, могут
                увеличиться сроки обработки заказа. В случае несогласия клиента
                с увеличением сроков обработки предоплаченного заказа, продавец
                возвращает клиенту оплаченную за заказ сумму путем ее отражения
                на пользовательском счете клиента, либо по его требованию
                производит возврат денежных средств тем способом, которым товар
                изначально был предоплачен.
                <br />
                6.5. Особенности оплаты товара с помощью банковских карт. 6.5.1.
                В соответствии с положением ЦБ РФ «Об эмиссии банковских карт и
                об операциях, совершаемых с использованием платежных карт» от
                24.12.2004 №266-П операции по банковским картам совершаются
                держателем карты либо уполномоченным им лицом.
                <br />
                6.5.2. При совершении оплаты товара с помощью банковской карты в
                пункте выдачи заказов клиент должен предъявить документ,
                удостоверяющий личность.
                <br />
                6.5.3. Авторизация операций по банковским картам осуществляется
                банком. Если у банка есть основания полагать, что операция носит
                мошеннический характер, то банк вправе отказать в осуществлении
                данной операции.
                <br />
                6.5.4. Во избежание случаев различного рода неправомерного
                использования банковских карт при оплате, все заказы,
                оформленные на сайте и предоплаченные банковской картой,
                проверяются продавцом. Продавец оставляет за собой право без
                объяснения причины аннулировать заказ. Стоимость заказа
                возвращается на банковскую карту владельца.
                <br />
                6.6. Продавец вправе предоставлять клиенту скидки на товар и
                устанавливать программу бонусов. Виды скидок, бонусов, порядок и
                условия начисления указаны на сайте и могут быть изменены
                продавцом в одностороннем порядке.
                <br />
                6.7. Продавец вправе устанавливать скидки в целях продвижения
                того либо иного способа оплаты или доставки товара. При этом
                продавец может ограничивать условия действия скидок на те или
                иные группы товаров.
                <br />
                6.8. При проведении маркетинговых мероприятий, предполагающих
                вложение каких-либо объектов в заказы клиента, доставка
                указанных вложений осуществляется за счет клиента. Чтобы
                отказаться от вложения, клиенту необходимо обратиться в службу
                по работе с клиентами.
                <br />
                6.9. В стоимость заказа не включены таможенные пошлины. Условия
                оплаты и размер таможенных пошлин определяются таможенным
                законодательством той страны, куда осуществляется доставка
                заказа.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>7. Возврат товара</h3>
              <p className={styles.infoParagraph}>
                7.1. Возврат товара надлежащего качества.
                <br />
                7.1.1. Клиент вправе отказаться от заказанного товара в любое
                время до его получения. Возврат товара надлежащего качества
                возможен в случае, если сохранены его товарный вид,
                потребительские свойства, а также документ, подтверждающий факт
                и условия покупки указанного товара.
                <br />
                7.1.2. Клиент не вправе отказаться от товара надлежащего
                качества, имеющего индивидуально-определенные свойства, если
                указанный товар может быть использован исключительно
                приобретающим его клиентом.
                <br />
                7.1.3. При отказе клиента от товара согласно п.7.1.1. условий
                продавец возвращает ему стоимость возвращенного товара, за
                исключением расходов продавца на доставку от клиента
                возвращенного товара, не позднее чем через 10 дней с даты
                получения продавцом письменного заявления клиента и возврата
                товара.
                <br />
                7.1.4. При отказе клиента от товара согласно п.7.1.1. и п.7.1.3.
                условий, подарок, выданный вместе с данным товаром подлежит
                обязательному возврату с сохранением товарного вида.
                <br />
                7.2. Возврат товара ненадлежащего качества.
                <br />
                7.2.1. Клиент может возвратить товар ненадлежащего качества
                изготовителю или продавцу и потребовать возврата уплаченной
                денежной суммы в течение гарантийного срока. Клиент также может
                потребовать замены товара ненадлежащего качества либо устранения
                его недостатков.
                <br />
                7.2.2. Покупатель, которому продан товар ненадлежащего качества,
                если это не было оговорено продавцом, вправе по своему выбору
                потребовать: а) безвозмездного устранения недостатков товара или
                возмещения расходов на их исправление покупателем или третьим
                лицом; б) соразмерного уменьшения покупной цены; в) замены на
                товар аналогичной модели, артикула с соответствующим
                перерасчетом покупной цены.
                <br />
                7.2.3. Покупатель вместо предъявления требований, вправе
                отказаться от исполнения договора и потребовать возврата
                уплаченной за товар суммы. По требованию продавца и за его счет
                покупатель должен возвратить товар с недостатками.
                <br />
                7.2.3.1. В случае отказа клиента от договора и предъявления
                требования о возврате уплаченной за товар денежной суммы
                согласно п.7.2.1. условий, стоимость товара подлежит возврату
                клиенту в течение 10 дней с момента получения продавцом
                письменного заявления клиента и возврата товара.
                <br />
                7.2.3.2. При отказе клиента от товара согласно п.7.2.1. и
                п.7.2.2. условий, подарок, выданный вместе с данным товаром,
                подлежит обязательному возврату с сохранением товарного вида.
                <br />
                7.3. Возврат денежных средств.
                <br />
                7.3.1. Денежные средства подлежат возврату безналичным путем,
                если клиент получил товар сторонними транспортными компаниями и
                иными мобильными пунктами выдачи, а также мобильными курьерскими
                службами.
                <br />
                7.3.2. В случае если возврат денежных средств осуществляется
                неодновременно с возвратом товара клиентом, возврат указанной
                суммы осуществляется продавцом с согласия клиента одним из
                следующих способов: -наличными денежными средствами по месту
                нахождения продавца; -почтовым переводом; -путем перечисления
                соответствующей суммы на банковский или иной счет клиента,
                указанный клиентом.
                <br />
                7.4. Порядок действий при нарушении продавцом условия об
                ассортименте (пересорт).
                <br />
                7.4.1. В случае передачи товара в нарушении условия об
                ассортименте применяются правила ст.468 ГК РФ.
                <br />
                7.4.2. В случае обнаружения в заказе товара, не соответствующего
                заказанному ассортименту (пересорт), клиент вправе при передаче
                заказа отказаться от данного товара и потребовать замены на
                товар в ассортименте, предусмотренном заказом, либо возврата
                денежных средств за фактически непереданный товар.
                <br />
                7.4.3. Товар, переданный клиенту в нарушение условия об
                ассортименте, подлежит возврату продавцу. В случае если клиент
                принимает данный товар, продавец вправе потребовать от клиента
                оплаты данного товара по цене, установленной продавцом для
                данного товара на сайте на момент передачи товара. Если
                фактически переданный товар отсутствует в ассортименте продавца,
                представленном на сайте на момент передачи товара, данный товар
                оплачивается по цене, согласованной с продавцом.
                <br />
                7.4.4. Замена товара, не соответствующего заказу по
                ассортименту, осуществляется путем оформления нового заказа по
                согласованию сторон – продавцом либо клиентом – с оплатой с
                пользовательского счета, где отражается стоимость фактически
                непереданного товара.
                <br />
                7.4.5. В случае невозможности осуществить замену товара,
                продавец уведомляет об этом клиента посредством направления
                сообщения на электронный адрес, указанный клиентом при
                регистрации, а денежные средства, фактически оплаченные за
                непереданный товар, возвращаются в порядке, предусмотренном
                п.7.4.6.
                <br />
                7.4.6. Денежные средства, оплаченные клиентом за фактически
                непереданный товар, подлежат возврату в течение 10 дней с
                момента получения письменного заявления клиента о возврате
                денежных средств. Возврат уплаченной за товар суммы
                осуществляется тем способом, которым была произведена оплата.
                <br />
                7.5. Порядок действий при нарушении продавцом условия о
                количестве.
                <br />
                7.5.1. При передаче заказа клиент обязан проверить количество
                товаров в заказе. Если при передаче заказа клиентом обнаружены
                расхождения по количеству товара в заказе, клиент обязан в
                присутствии представителя продавца или перевозчика составить акт
                о расхождении по количеству.
                <br />
                7.5.2. Если продавец передал клиенту меньшее количество товара,
                чем определено заказом (недовложение), клиент при передаче
                заказа вправе принять товар в части, соответствующей заказу, и
                потребовать передать недостающее количество товара, либо, если
                недостающий товар был оплачен, отказаться от заказа в части
                недостающего товара и потребовать возврата денежных средств за
                недостающий товар.
                <br />
                7.5.3. Передача недостающего товара осуществляется посредством
                оформления нового заказа по согласованию сторон – продавцом либо
                клиентом, при условии предоставления клиентом акта о расхождении
                (акта о недовложении), составленного в порядке п.7.5.1.
                <br />
                7.5.4. В случае если недостающий товар был предварительно
                оплачен клиентом, его стоимость отражается на пользовательском
                счете, который выбирается как способ оплаты нового заказа. Если
                недостающий товар не был оплачен клиентом, он оплачивается любым
                доступным способом, выбранным клиентом либо продавцом по
                согласованию с клиентом, при оформлении нового заказа.
                <br />
                7.5.5. В случае невозможности передать недостающий товар,
                продавец уведомляет об этом клиента посредством направления
                сообщения на электронный адрес, указанный клиентом при
                регистрации, а денежные средства, фактически оплаченные за
                недостающий товар, возвращаются в порядке, предусмотренном
                п.7.5.6.
                <br />
                7.5.6. Денежные средства, оплаченные клиентом за недостающий
                товар, подлежат возврату в течение 10 дней с момента получения
                письменного заявления клиента о возврате денежных средств, а
                также акта о расхождении (акта о недовложении) по количеству,
                составленного в порядке п.7.5.1. Возврат уплаченной за товар
                суммы осуществляется тем способом, которым была произведена
                оплата.
                <br />
                7.5.7. В случае нарушения клиентом п.7.5.1 в части составления
                акта, продавец вправе отказать клиенту в удовлетворении
                претензий по количеству переданного товара.
                <br />
                7.6 Возврат товара выданного в подарок.
                <br />
                7.6.1 Товар, выданный в подарок возврату, замене и гарантийному
                обслуживанию не подлежит.
                <br />
                7.7.1 Любая характеристика представленного на нашем сайте товара
                может быть изменена производителем в одностороннем порядке.
                Продавец гарантирует лишь соответствие характеристик, указанных
                в товарном чеке (либо товарной накладной). В соответствии ст.437
                ГК РФ от 30.11.1994 №-51-ФЗ- часть 1.
                <br />
                7.7.2 В момент передачи товара покупателю, покупатель обязан
                свериться с товарным чеком, а также с внешним видом покупаемого
                товара.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>8. Интеллектуальная собственность</h3>
              <p className={styles.infoParagraph}>
                8.1. Вся текстовая информация и графические изображения,
                находящиеся на сайте, являются собственностью продавца и/или его
                контрагентов.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>9. Гарантии и ответственность</h3>
              <p className={styles.infoParagraph}>
                9.1. Продавец не несет ответственность за ущерб, причиненный
                клиенту вследствие ненадлежащего использования товаров,
                заказанных на сайте.
                <br />
                9.2. Продавец не несет ответственности за содержание и
                функционирование Внешних сайтов, напрямую не связанных с
                продавцом.
                <br />
                9.3. Продавец вправе переуступать либо каким-либо иным способом
                передавать свои права и обязанности, вытекающие из его отношений
                с клиентом, третьим лицам.
                <br />
                9.4. Клиент обязуется не использовать заказанный товар в
                предпринимательских целях.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>10. Конфиденциальность и защита персональной информации.</h3>
              <p className={styles.infoParagraph}>
                10.1. Для приобретения товаров в интернет-магазине, клиент
                предоставляет продавцу свои персональные данные.
                <br />
                10.2. Предоставляя свои персональные данные, клиент соглашается
                на их обработку продавцом, в том числе и в целях продвижения
                продавцом товаров и услуг.
                <br />
                10.2.1. Если клиент не желает, чтобы его персональные данные
                обрабатывались, то он должен сообщить об этом в службу по работе
                с клиентами. В таком случае вся полученная от клиента информация
                (в тот числе логин и пароль) удаляется из клиентской базы
                продавца и клиент не сможет размещать заказы на сайте.
                <br />
                10.3. Использование информации предоставленной клиентом и
                получаемой продавцом.
                <br />
                10.3.1. Продавец использует информацию:
                <br />
                для регистрации клиента на сайте;
                <br />
                для выполнения своих обязательств перед клиентом;
                <br />
                для оценки и анализа работы сайта;
                <br />
                для определения победителя в акциях, проводимых продавцом.
                <br />
                10.3.2. Продавец вправе направлять клиенту сообщения
                рекламно-информационного характера. Если клиент не желает
                получать рассылки от продавца, он должен изменить
                соответствующие настройки подписки в личном кабинете, либо
                обратиться в службу по работе с клиентами продавца.
                <br />
                10.4. Разглашение информации, полученной продавцом.
                <br />
                10.4.1. Продавец обязуется не разглашать полученную от клиента
                информацию. Не считается нарушением предоставление продавцом
                информации агентам и третьим лицам, действующим на основании
                договора с продавцом, для исполнения обязательств перед
                клиентом.
                <br />
                10.4.2. Не считается нарушением обязательств разглашение
                информации в соответствии с обоснованными и применимыми
                требованиями закона.
                <br />
                10.5. Продавец не несет ответственности за сведения,
                предоставленные клиентом на сайте в общедоступной форме.
                <br />
                10.6. Продавец не несет ответственности за убытки, которые
                клиент может понести в результате того, что его логин и пароль
                стали известны третьему лицу.
                <br />
                10.7. В случае возникновения у продавца подозрений относительно
                использования учетной записи клиента третьим лицом или
                вредоносным программным обеспечением продавец вправе в
                одностороннем порядке изменить пароль клиента. Для
                восстановления пароля клиенту необходимо обратиться в службу по
                работе с клиентами продавца.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>11. Прочие условия</h3>
              <p className={styles.infoParagraph}>
                11.1. К отношениям между клиентом и продавцом применяется право
                Российской Федерации.
                <br />
                11.2. В случае возникновения вопросов и претензий со стороны
                клиента он должен обратиться в службу по работе с клиентами
                продавца по телефону или через форму обратной связи на сайте.
                Все возникающее споры стороны будут стараться решить путем
                переговоров, при недостижении соглашения спор будет передан на
                рассмотрение в судебный орган в соответствии с действующим
                законодательством РФ.
                <br />
                11.3. Признание судом недействительности какого-либо из условий
                настоящего Предложения не влечет за собой недействительность
                остальных его положений.
                <br />
                11.4. Срок, в течение которого действует Предложение о
                приобретении товаров в интернет-магазине grandnn.com, ограничен
                наличием того или иного товара на складе продавца.
                <br />
                11.5. Претензии по качеству товара принимаются по юридическому
                адресу продавца.
                <br />
                11.6. Любая информация, предоставленная вами, может быть
                использована в соответствии с действующим законодательством
                Российской Федерации в целях:
                <br />
                - обработки заказа на сайте Компании или для предоставления вам
                доступа к специальной информации
                <br />
                - повышения качества предоставляемых товаров и услуг
                <br />
                - анализа данных и проведения различных исследований
                <br />
                - осуществления деятельности по продвижению товаров и услуг
                <br />
                - подготовки индивидуальных предложений, которые подходят именно
                вам
                <br />
                - информирования Вас об акциях, скидках и специальных
                предложениях, присылая на указанный адрес электронной почты или
                номер телефона информационные сообщения
                <br />
                Обращаем Ваше внимание на то, что в любой момент Вы можете
                отказаться от указанных сообщений, обратившись к Компании.
                <br />
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>12. Реквизиты продавца</h3>
              <p className={styles.infoParagraph}>
                Индивидуальный предприниматель Манеляк Л.А.
                <br />
                ИНН525629285613
                <br />
                ОГРН321527500104158
                <br />
                <br />
                АО "БАНК РУССКИЙ СТАНДАРТ"
                <br />
                ИНН/КПП: 525629285613/0
                <br />
                БИК: 044525151
                <br />
                Кок. счет: 30101810845250000151
                <br />
                Расчетный счет: 40802810700000017652
                <br />
                <br />
                Генеральный директор: Манеляк Людмила Александровна
              </p>
            </div>
          </div>
        </InfoDropdown>
        <InfoDropdown
          title="
Политика в отношении обработки персональных данных"
        >
          <div className={styles.blockInfo}>
            <div className={styles.infoDescription}>
              <h3>1. Общие положения</h3>
              <p className={styles.infoParagraph}>
                Настоящая политика обработки персональных данных составлена в
                соответствии с требованиями Федерального закона от 27.07.2006.
                №152-ФЗ «О персональных данных» (далее - Закон о персональных
                данных) и определяет порядок обработки персональных данных и
                меры по обеспечению безопасности персональных данных,
                предпринимаемые ООО "Ломбард "Золотой Гранд" (далее – Оператор).
                <br />
                1.1. Оператор ставит своей важнейшей целью и условием
                осуществления своей деятельности соблюдение прав и свобод
                человека и гражданина при обработке его персональных данных, в
                том числе защиты прав на неприкосновенность частной жизни,
                личную и семейную тайну.
                <br />
                1.2. Настоящая политика Оператора в отношении обработки
                персональных данных (далее – Политика) применяется ко всей
                информации, которую Оператор может получить о посетителях
                веб-сайта grand.gold.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>2. Основные понятия, используемые в Политике</h3>
              <p className={styles.infoParagraph}>
                2.1. Автоматизированная обработка персональных данных –
                обработка персональных данных с помощью средств вычислительной
                техники.
                <br />
                2.2. Блокирование персональных данных – временное прекращение
                обработки персональных данных (за исключением случаев, если
                обработка необходима для уточнения персональных данных).
                <br />
                2.3. Веб-сайт – совокупность графических и информационных
                материалов, а также программ для ЭВМ и баз данных,
                обеспечивающих их доступность в сети интернет по сетевому адресу
                grand.gold.
                <br />
                2.4. Информационная система персональных данных — совокупность
                содержащихся в базах данных персональных данных, и
                обеспечивающих их обработку информационных технологий и
                технических средств.
                <br />
                2.5. Обезличивание персональных данных — действия, в результате
                которых невозможно определить без использования дополнительной
                информации принадлежность персональных данных конкретному
                Пользователю или иному субъекту персональных данных.
                <br />
                2.6. Обработка персональных данных – любое действие (операция)
                или совокупность действий (операций), совершаемых с
                использованием средств автоматизации или без использования таких
                средств с персональными данными, включая сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
                <br />
                2.7. Оператор – государственный орган, муниципальный орган,
                юридическое или физическое лицо, самостоятельно или совместно с
                другими лицами организующие и (или) осуществляющие обработку
                персональных данных, а также определяющие цели обработки
                персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными
                данными.
                <br />
                2.8. Персональные данные – любая информация, относящаяся прямо
                или косвенно к определенному или определяемому Пользователю
                веб-сайта grand.gold.
                <br />
                2.9. Персональные данные, разрешенные субъектом персональных
                данных для распространения, - персональные данные, доступ
                неограниченного круга лиц к которым предоставлен субъектом
                персональных данных путем дачи согласия на обработку
                персональных данных, разрешенных субъектом персональных данных
                для распространения в порядке, предусмотренном Законом о
                персональных данных (далее - персональные данные, разрешенные
                для распространения).
                <br />
                2.10. Пользователь – любой посетитель веб-сайта grand.gold.
                <br />
                2.11. Предоставление персональных данных – действия,
                направленные на раскрытие персональных данных определенному лицу
                или определенному кругу лиц.
                <br />
                2.12. Распространение персональных данных – любые действия,
                направленные на раскрытие персональных данных неопределенному
                кругу лиц (передача персональных данных) или на ознакомление с
                персональными данными неограниченного круга лиц, в том числе
                обнародование персональных данных в средствах массовой
                информации, размещение в информационно-телекоммуникационных
                сетях или предоставление доступа к персональным данным
                каким-либо иным способом.
                <br />
                2.13. Трансграничная передача персональных данных – передача
                персональных данных на территорию иностранного государства
                органу власти иностранного государства, иностранному физическому
                или иностранному юридическому лицу.
                <br />
                2.14. Уничтожение персональных данных – любые действия, в
                результате которых персональные данные уничтожаются безвозвратно
                с невозможностью дальнейшего восстановления содержания
                персональных данных в информационной системе персональных данных
                и (или) уничтожаются материальные носители персональных данных.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>3. Основные права и обязанности Оператора</h3>
              <p className={styles.infoParagraph}>
                3.1. Оператор имеет право:
                <br />
                – получать от субъекта персональных данных достоверные
                информацию и/или документы, содержащие персональные данные;
                <br />
                – в случае отзыва субъектом персональных данных согласия на
                обработку персональных данных Оператор вправе продолжить
                обработку персональных данных без согласия субъекта персональных
                данных при наличии оснований, указанных в Законе о персональных
                данных;
                <br />
                – самостоятельно определять состав и перечень мер, необходимых и
                достаточных для обеспечения выполнения обязанностей,
                предусмотренных Законом о персональных данных и принятыми в
                соответствии с ним нормативными правовыми актами, если иное не
                предусмотрено Законом о персональных данных или другими
                федеральными законами.
                <br />
                3.2. Оператор обязан:
                <br />
                – предоставлять субъекту персональных данных по его просьбе
                информацию, касающуюся обработки его персональных данных;
                <br />
                – организовывать обработку персональных данных в порядке,
                установленном действующим законодательством РФ;
                <br />
                – отвечать на обращения и запросы субъектов персональных данных
                и их законных представителей в соответствии с требованиями
                Закона о персональных данных;
                <br />
                – сообщать в уполномоченный орган по защите прав субъектов
                персональных данных по запросу этого органа необходимую
                информацию в течение 30 дней с даты получения такого запроса;
                <br />
                – публиковать или иным образом обеспечивать неограниченный
                доступ к настоящей Политике в отношении обработки персональных
                данных;
                <br />
                – принимать правовые, организационные и технические меры для
                защиты персональных данных от неправомерного или случайного
                доступа к ним, уничтожения, изменения, блокирования,
                копирования, предоставления, распространения персональных
                данных, а также от иных неправомерных действий в отношении
                персональных данных;
                <br />
                – прекратить передачу (распространение, предоставление, доступ)
                персональных данных, прекратить обработку и уничтожить
                персональные данные в порядке и случаях, предусмотренных Законом
                о персональных данных;
                <br />
                – исполнять иные обязанности, предусмотренные Законом о
                персональных данных.
                <br />
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>
                4. Основные права и обязанности субъектов персональных данных
              </h3>
              <p className={styles.infoParagraph}>
                4.1. Субъекты персональных данных имеют право:
                <br />
                – получать информацию, касающуюся обработки его персональных
                данных, за исключением случаев, предусмотренных федеральными
                законами. Сведения предоставляются субъекту персональных данных
                Оператором в доступной форме, и в них не должны содержаться
                персональные данные, относящиеся к другим субъектам персональных
                данных, за исключением случаев, когда имеются законные основания
                для раскрытия таких персональных данных. Перечень информации и
                порядок ее получения установлен Законом о персональных данных;
                <br />
                – требовать от оператора уточнения его персональных данных, их
                блокирования или уничтожения в случае, если персональные данные
                являются неполными, устаревшими, неточными, незаконно
                полученными или не являются необходимыми для заявленной цели
                обработки, а также принимать предусмотренные законом меры по
                защите своих прав;
                <br />
                – выдвигать условие предварительного согласия при обработке
                персональных данных в целях продвижения на рынке товаров, работ
                и услуг;
                <br />
                – на отзыв согласия на обработку персональных данных;
                <br />
                – обжаловать в уполномоченный орган по защите прав субъектов
                персональных данных или в судебном порядке неправомерные
                действия или бездействие Оператора при обработке его
                персональных данных;
                <br />
                – на осуществление иных прав, предусмотренных законодательством
                РФ.
                <br />
                4.2. Субъекты персональных данных обязаны:
                <br />
                – предоставлять Оператору достоверные данные о себе;
                <br />
                – сообщать Оператору об уточнении (обновлении, изменении) своих
                персональных данных.
                <br />
                4.3. Лица, передавшие Оператору недостоверные сведения о себе,
                либо сведения о другом субъекте персональных данных без согласия
                последнего, несут ответственность в соответствии с
                законодательством РФ.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>
                5. Оператор может обрабатывать следующие персональные данные
                Пользователя
              </h3>
              <p className={styles.infoParagraph}>
                5.1. Фамилия, имя, отчество.
                <br />
                5.2. Электронный адрес.
                <br />
                5.3. Номера телефонов.
                <br />
                5.4. Фотографии.
                <br />
                5.5. Также на сайте происходит сбор и обработка обезличенных
                данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
                интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
                <br />
                5.6. Вышеперечисленные данные далее по тексту Политики
                объединены общим понятием Персональные данные.
                <br />
                5.7. Обработка специальных категорий персональных данных,
                касающихся расовой, национальной принадлежности, политических
                взглядов, религиозных или философских убеждений, интимной жизни,
                Оператором не осуществляется.
                <br />
                5.8. Обработка персональных данных, разрешенных для
                распространения, из числа специальных категорий персональных
                данных, указанных в ч. 1 ст. 10 Закона о персональных данных,
                допускается, если соблюдаются запреты и условия, предусмотренные
                ст. 10.1 Закона о персональных данных.
                <br />
                5.9. Согласие Пользователя на обработку персональных данных,
                разрешенных для распространения, оформляется отдельно от других
                согласий на обработку его персональных данных. При этом
                соблюдаются условия, предусмотренные, в частности, ст. 10.1
                Закона о персональных данных. Требования к содержанию такого
                согласия устанавливаются уполномоченным органом по защите прав
                субъектов персональных данных.
                <br />
                5.9.1 Согласие на обработку персональных данных, разрешенных для
                распространения, Пользователь предоставляет Оператору
                непосредственно.
                <br />
                5.9.2 Оператор обязан в срок не позднее трех рабочих дней с
                момента получения указанного согласия Пользователя опубликовать
                информацию об условиях обработки, о наличии запретов и условий
                на обработку неограниченным кругом лиц персональных данных,
                разрешенных для распространения.
                <br />
                5.9.3 Передача (распространение, предоставление, доступ)
                персональных данных, разрешенных субъектом персональных данных
                для распространения, должна быть прекращена в любое время по
                требованию субъекта персональных данных. Данное требование
                должно включать в себя фамилию, имя, отчество (при наличии),
                контактную информацию (номер телефона, адрес электронной почты
                или почтовый адрес) субъекта персональных данных, а также
                перечень персональных данных, обработка которых подлежит
                прекращению. Указанные в данном требовании персональные данные
                могут обрабатываться только Оператором, которому оно направлено.
                <br />
                5.9.4 Согласие на обработку персональных данных, разрешенных для
                распространения, прекращает свое действие с момента поступления
                Оператору требования, указанного в п. 5.9.3 настоящей Политики в
                отношении обработки персональных данных.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>6. Принципы обработки персональных данных</h3>
              <p className={styles.infoParagraph}>
                6.1. Обработка персональных данных осуществляется на законной и
                справедливой основе.
                <br />
                6.2. Обработка персональных данных ограничивается достижением
                конкретных, заранее определенных и законных целей. Не
                допускается обработка персональных данных, несовместимая с
                целями сбора персональных данных.
                <br />
                6.3. Не допускается объединение баз данных, содержащих
                персональные данные, обработка которых осуществляется в целях,
                несовместимых между собой.
                <br />
                6.4. Обработке подлежат только персональные данные, которые
                отвечают целям их обработки.
                <br />
                6.5. Содержание и объем обрабатываемых персональных данных
                соответствуют заявленным целям обработки. Не допускается
                избыточность обрабатываемых персональных данных по отношению к
                заявленным целям их обработки.
                <br />
                6.6. При обработке персональных данных обеспечивается точность
                персональных данных, их достаточность, а в необходимых случаях и
                актуальность по отношению к целям обработки персональных данных.
                Оператор принимает необходимые меры и/или обеспечивает их
                принятие по удалению или уточнению неполных или неточных данных.
                <br />
                6.7. Хранение персональных данных осуществляется в форме,
                позволяющей определить субъекта персональных данных, не дольше,
                чем этого требуют цели обработки персональных данных, если срок
                хранения персональных данных не установлен федеральным законом,
                договором, стороной которого, выгодоприобретателем или
                поручителем по которому является субъект персональных данных.
                Обрабатываемые персональные данные уничтожаются либо
                обезличиваются по достижении целей обработки или в случае утраты
                необходимости в достижении этих целей, если иное не
                предусмотрено федеральным законом.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>7. Цели обработки персональных данных</h3>
              <p className={styles.infoParagraph}>
                7.1. Цель обработки персональных данных Пользователя:
                <br />
                – информирование Пользователя посредством отправки электронных
                писем;
                <br />
                – заключение, исполнение и прекращение гражданско-правовых
                договоров;
                <br />
                – предоставление доступа Пользователю к сервисам, информации
                и/или материалам, содержащимся на веб-сайтеgrand.gold
                <br />
                – уточнение деталий заказа.
                <br />
                7.2. Также Оператор имеет право направлять Пользователю
                уведомления о новых продуктах и услугах, специальных
                предложениях и различных событиях. Пользователь всегда может
                отказаться от получения информационных сообщений, направив
                Оператору письмо на адрес электронной почтыorder@grand.gold с
                пометкой «Отказ от уведомлений о новых продуктах и услугах и
                специальных предложениях».
                <br />
                7.3. Обезличенные данные Пользователей, собираемые с помощью
                сервисов интернет-статистики, служат для сбора информации о
                действиях Пользователей на сайте, улучшения качества сайта и его
                содержания.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>8. Правовые основания обработки персональных данных</h3>
              <p className={styles.infoParagraph}>
                8.1. Правовыми основаниями обработки персональных данных
                Оператором являются:
                <br />
                – уставные (учредительные) документы Оператора;
                <br />
                – федеральные законы, иные нормативно-правовые акты в сфере
                защиты персональных данных;
                <br />
                – согласия Пользователей на обработку их персональных данных, на
                обработку персональных данных, разрешенных для распространения.
                <br />
                8.2. Оператор обрабатывает персональные данные Пользователя
                только в случае их заполнения и/или отправки Пользователем
                самостоятельно через специальные формы, расположенные на
                сайтеgrand.gold или направленные Оператору посредством
                электронной почты. Заполняя соответствующие формы и/или
                отправляя свои персональные данные Оператору, Пользователь
                выражает свое согласие с данной Политикой.
                <br />
                8.3. Оператор обрабатывает обезличенные данные о Пользователе в
                случае, если это разрешено в настройках браузера Пользователя
                (включено сохранение файлов «cookie» и использование технологии
                JavaScript).
                <br />
                8.4. Субъект персональных данных самостоятельно принимает
                решение о предоставлении его персональных данных и дает согласие
                свободно, своей волей и в своем интересе.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>9. Условия обработки персональных данных</h3>
              <p className={styles.infoParagraph}>
                9.1. Обработка персональных данных осуществляется с согласия
                субъекта персональных данных на обработку его персональных
                данных.
                <br />
                9.2. Обработка персональных данных необходима для достижения
                целей, предусмотренных международным договором Российской
                Федерации или законом, для осуществления возложенных
                законодательством Российской Федерации на оператора функций,
                полномочий и обязанностей.
                <br />
                9.3. Обработка персональных данных необходима для осуществления
                правосудия, исполнения судебного акта, акта другого органа или
                должностного лица, подлежащих исполнению в соответствии с
                законодательством Российской Федерации об исполнительном
                производстве.
                <br />
                9.4. Обработка персональных данных необходима для исполнения
                договора, стороной которого либо выгодоприобретателем или
                поручителем по которому является субъект персональных данных, а
                также для заключения договора по инициативе субъекта
                персональных данных или договора, по которому субъект
                персональных данных будет являться выгодоприобретателем или
                поручителем.
                <br />
                9.5. Обработка персональных данных необходима для осуществления
                прав и законных интересов оператора или третьих лиц либо для
                достижения общественно значимых целей при условии, что при этом
                не нарушаются права и свободы субъекта персональных данных.
                <br />
                9.6. Осуществляется обработка персональных данных, доступ
                неограниченного круга лиц к которым предоставлен субъектом
                персональных данных либо по его просьбе (далее – общедоступные
                персональные данные).
                <br />
                9.7. Осуществляется обработка персональных данных, подлежащих
                опубликованию или обязательному раскрытию в соответствии с
                федеральным законом.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>
                10. Порядок сбора, хранения, передачи и других видов обработки
                персональных данных
              </h3>
              <p className={styles.infoParagraph}>
                Безопасность персональных данных, которые обрабатываются
                Оператором, обеспечивается путем реализации правовых,
                организационных и технических мер, необходимых для выполнения в
                полном объеме требований действующего законодательства в области
                защиты персональных данных.
                <br />
                10.1. Оператор обеспечивает сохранность персональных данных и
                принимает все возможные меры, исключающие доступ к персональным
                данным неуполномоченных лиц.
                <br />
                10.2. Персональные данные Пользователя никогда, ни при каких
                условиях не будут переданы третьим лицам, за исключением
                случаев, связанных с исполнением действующего законодательства
                либо в случае, если субъектом персональных данных дано согласие
                Оператору на передачу данных третьему лицу для исполнения
                обязательств по гражданско-правовому договору.
                <br />
                10.3. В случае выявления неточностей в персональных данных,
                Пользователь может актуализировать их самостоятельно, путем
                направления Оператору уведомление на адрес электронной почты
                Оператора order@grand.gold с пометкой «Актуализация персональных
                данных».
                <br />
                10.4. Срок обработки персональных данных определяется
                достижением целей, для которых были собраны персональные данные,
                если иной срок не предусмотрен договором или действующим
                законодательством. Пользователь может в любой момент отозвать
                свое согласие на обработку персональных данных, направив
                Оператору уведомление посредством электронной почты на
                электронный адрес Оператора order@grand.gold с пометкой «Отзыв
                согласия на обработку персональных данных».
                <br />
                10.5. Вся информация, которая собирается сторонними сервисами, в
                том числе платежными системами, средствами связи и другими
                поставщиками услуг, хранится и обрабатывается указанными лицами
                (Операторами) в соответствии с их Пользовательским соглашением и
                Политикой конфиденциальности. Субъект персональных данных и/или
                Пользователь обязан самостоятельно своевременно ознакомиться с
                указанными документами. Оператор не несет ответственность за
                действия третьих лиц, в том числе указанных в настоящем пункте
                поставщиков услуг.
                <br />
                10.6. Установленные субъектом персональных данных запреты на
                передачу (кроме предоставления доступа), а также на обработку
                или условия обработки (кроме получения доступа) персональных
                данных, разрешенных для распространения, не действуют в случаях
                обработки персональных данных в государственных, общественных и
                иных публичных интересах, определенных законодательством РФ.
                <br />
                10.7. Оператор при обработке персональных данных обеспечивает
                конфиденциальность персональных данных.
                <br />
                10.8. Оператор осуществляет хранение персональных данных в
                форме, позволяющей определить субъекта персональных данных, не
                дольше, чем этого требуют цели обработки персональных данных,
                если срок хранения персональных данных не установлен федеральным
                законом, договором, стороной которого, выгодоприобретателем или
                поручителем по которому является субъект персональных данных.
                <br />
                10.9. Условием прекращения обработки персональных данных может
                являться достижение целей обработки персональных данных,
                истечение срока действия согласия субъекта персональных данных
                или отзыв согласия субъектом персональных данных, а также
                выявление неправомерной обработки персональных данных.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>
                11. Перечень действий, производимых Оператором с полученными
                персональными данными
              </h3>
              <p className={styles.infoParagraph}>
                11.1. Оператор осуществляет сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление и
                уничтожение персональных данных.
                <br />
                11.2. Оператор осуществляет автоматизированную обработку
                персональных данных с получением и/или передачей полученной
                информации по информационно-телекоммуникационным сетям или без
                таковой.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>12. Трансграничная передача персональных данных</h3>
              <p className={styles.infoParagraph}>
                12.1. Оператор до начала осуществления трансграничной передачи
                персональных данных обязан убедиться в том, что иностранным
                государством, на территорию которого предполагается осуществлять
                передачу персональных данных, обеспечивается надежная защита
                прав субъектов персональных данных.
                <br />
                12.2. Трансграничная передача персональных данных на территории
                иностранных государств, не отвечающих вышеуказанным требованиям,
                может осуществляться только в случае наличия согласия в
                письменной форме субъекта персональных данных на трансграничную
                передачу его персональных данных и/или исполнения договора,
                стороной которого является субъект персональных данных.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>13. Конфиденциальность персональных данных</h3>
              <p className={styles.infoParagraph}>
                Оператор и иные лица, получившие доступ к персональным данным,
                обязаны не раскрывать третьим лицам и не распространять
                персональные данные без согласия субъекта персональных данных,
                если иное не предусмотрено федеральным законом.
              </p>
            </div>
            <div className={styles.infoDescription}>
              <h3>14. Заключительные положения</h3>
              <p className={styles.infoParagraph}>
                14.1. Пользователь может получить любые разъяснения по
                интересующим вопросам, касающимся обработки его персональных
                данных, обратившись к Оператору с помощью электронной
                почтыorder@grand.gold.
                <br />
                14.2. В данном документе будут отражены любые изменения политики
                обработки персональных данных Оператором. Политика действует
                бессрочно до замены ее новой версией.
                <br />
                14.3. Актуальная версия Политики в свободном доступе расположена
                в сети Интернет по адресуgrand.gold.
              </p>
            </div>
          </div>
        </InfoDropdown>
        <InfoDropdown
          title="
                    Согласие на обработку персональных данных"
        >
          <div className={styles.blockInfo}>
            <div className={styles.infoDescription}>
              <p className={styles.infoParagraph}>
                Пользователь, посещая настоящий сайт в информационно-
                телекоммуникационной сети Интернет, принимает настоящее Согласие
                на автоматизированную обработку персональных данных в части
                Cookie-файлов (далее – Согласие). Действуя свободно, своей волей
                и в своем интересе, а также подтверждая свою дееспособность,
                Пользователь дает свое согласие ООО "Ломбард "Золотой Гранд",
                ИНН 5260480502, ОГРН 1215200040285, адрес местонахождения:
                Р603093, Нижегородская область, г Нижний Новгород, ул Родионова,
                д. 17, помещ. 12 (далее – Оператор), на обработку своих
                персональных данных в части пользовательских данных и файлов
                “cookie” в соответствии со следующими условиями:
                <br />
                <br />
                1. Настоящее Согласие предоставляется в отношении персональных
                данных в части пользовательских данных , которые могут быть
                получены Оператором при открытии Пользователем страниц сайта.
                <br />
                <br />
                2. Согласие дается на обработку пользовательских данных и файлов
                «cookie»: сведения о местоположении; тип и версия операционной
                системы; тип и версия браузера; тип устройства и разрешение его
                экрана; источник входа на Сайт; язык операционной системы и
                браузера; информация о поведении Пользователя на Сайте (включая
                количество и наименование просмотренных страниц); сведения о
                почтовом клиенте; сведения об используемом браузере; сведения о
                переходах по ссылкам в электронных письмах; сведения о
                месторасположении; IP- адрес Пользователя, дата и время
                посещения Сайта, информация о поведении Пользователя на Сайте
                (включая количество и наименование просмотренных страниц),
                возраст, пол, интересы, географические данные, прочие
                технические данные (cookies, flash, java и т.п.).
                <br />
                <br />
                3. На Сайте используются следующие файлы «cookie»: Сookie-сессии
                – файлы, использующиеся исключительно во временной памяти.
                Указанные файлы удаляются при закрытии Пользователем окна
                интернет-браузера. Постоянные cookie-файлы – файлы,
                сохраняющиеся на устройстве в течение длительного времени или до
                тех пор, пока они не будут удалены вручную. Данные файлы
                учитывают ранее введенную Пользователем информацию и позволяют
                не вводить одни те же данные при каждом посещении Сайта.
                <br />
                <br />
                4. Персональные данные предоставляются в целях: - Предоставления
                пользователю доступа к сервисам, информации и/или материалам,
                содержащимся на Сайте, к персонализированным ресурсам Сайта,
                включая консультационные услуги; создания учетной записи; -
                Осуществления аналитики сайта, отслеживания и понимания
                принципов использования сайта Пользователями, в том числе с
                помощью сервисов интернет-статистики (Яндекс Метрика),
                совершенствования функционирования сайта, решения технических
                проблем сайта, разработки новых продуктов, расширения услуг,
                проведения ретаргетинга, настройки рекламных кампаний;
                <br />
                <br />
                5. В указанных целях Оператор может осуществлять передачу
                персональных данных Пользователя в объеме, необходимом для
                указанных целей, следующим лицам на основании заключенных с ними
                договоров, а Пользователь предоставляет согласие на передачу
                персональных данных в указанных ниже целях: (1) лицу,
                осуществляющему сбор персональных данных, техническое и
                информационно-консультационное обслуживание средств
                автоматизации и программного обеспечения, используемых
                Оператором при оказании услуг по договору; лицу, ответственному
                за организацию обработки персональных данных и обеспечения
                безопасности персональных данных, осуществляющему аналитику
                сайта, анализ отслеживания и понимания принципов использования
                сайта Пользователями, в том числе с помощью сервисов
                интернет-статистики (Яндекс Метрика), совершенствования
                функционирования сайта, решения технических проблем сайта,
                разработки новых продуктов, расширения услуг, проведения
                ретаргетинга, настройки рекламных компаний; (2) лицу,
                осуществляющему аналитику сайта для отслеживания и понимания
                принципов использования сайта Пользователями, в том числе с
                помощью сервисов интернет-статистики (Яндекс Метрика),
                проведения ретаргетинга, настройки рекламных кампаний: ООО
                «Яндекс» (ИНН 7736207543, ОГРН 1027700229193, адрес
                местонахождения 119021, ГОРОД МОСКВА, ЛЬВА ТОЛСТОГО УЛИЦА, 16).
                <br />
                <br />
                Обработка персональных данных, предусмотренная настоящим
                Согласием, будет осуществляться Оператором персональных данных с
                использованием средств автоматизации или без использования таких
                средств путем сбора, записи, систематизации, накопления,
                хранения, уточнения, извлечения, использования, передачи
                (предоставления доступа) третьим лицам, обезличивания,
                блокирования, удаления, уничтожения персональных данных, в
                соответствии с требованиями Федерального закона от 27.07.2006 No
                152-ФЗ «О персональных данных».
                <br />
                <br />
                Пользователь согласен с получением информации по каналам доступа
                и понимает, что все сообщения передаются по открытым каналам
                связи и Оператор не гарантирует конфиденциальности в отношении
                переданной таким образом информации. Пользователь поставлен в
                известность и понимает, что получение информации, в том числе о
                сумме, прочих параметрах операций в отсылаемых
                сообщениях-оповещения по каналам доступа увеличивает риск
                несанкционированного получения этой информации сторонними
                лицами.
                <br />
                <br />
                Используя Сайт, Пользователь подтверждает, что им внимательно и
                в полном объеме прочитаны и поняты условия использования
                пользовательских данных и файлов «cookie», изложенные в
                настоящем согласии, дает свое согласие на использование
                cookie-файлов и выражает согласие с условиями использования
                пользовательских данных и файлов «cookie»в соответствии с
                настоящим согласием.
                <br />
                <br />
                Пользователь может отказаться от использования или заблокировать
                все или определенные пользовательские данные и файлы «cookie»в
                своем браузере, однако, в таком случае, работа отдельных
                элементов Сайта может быть нарушена. В случае отказа от
                обработки данных Пользователь проинформирован о необходимости
                прекратить использование портала или отключить файлы «cookie» в
                настройках браузера.
              </p>
            </div>
          </div>
        </InfoDropdown>
      </section>
    </div>
  );
}

export { Info };
