import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import styles from '../../../Forms.module.scss';

import { useLoginModal } from 'entities/Modal/ui/components/LoginModal/lib/store';
import { useGetLoginCode } from 'api/Auth/Login/useGetLoginCode';
import validatePhone from 'shared/lib/validatePhone';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import ReCAPTCHA from 'react-google-recaptcha';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';
import { MainButton } from 'shared/ui/MainButton';
import { LinkButton } from 'shared/ui/LinkButton';

function LoginSMSForm() {
  const { loginError, loginLoading, getLoginCode } = useGetLoginCode();
  const recaptchaRef = useRef();

  // Скрываю модалку Вход, если она включена
  const { isActive, setIsActive } = useLoginModal((state) => ({
    isActive: state.active,
    setIsActive: state.setActiveModal,
  }));

  const onCloseLoginModal = () => {
    setIsActive(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await getLoginCode(data, 'sms', '/sign-in/sms');
    }
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="tel"
              onPaste={onPhonePaste}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              {...register('phone_number', {
                required: 'Обязательно к заполнению',
                validate: (value) => validatePhone(value.trim()),
              })}
              placeholder="+7 (900) 123 45 67"
            />

            {loginError !== null ? (
              <div className={styles.error}>
                <p>{loginError}</p>
              </div>
            ) : (
              <div className={styles.error}>
                {errors?.phone_number && (
                  <p>
                    {errors?.phone_number?.message || 'Кажется, здесь ошибка'}
                  </p>
                )}
              </div>
            )}
          </div>
          <MainButton
            type="submit"
            text="Выполнить вход"
            loading={loginLoading}
          />
        </div>
        <div
          className={styles.tip}
          onClick={isActive ? onCloseLoginModal : null}
        >
          <LinkButton
            text="У меня пока нет аккаунта. Создать"
            link="/register"
          />
        </div>
        <div className={styles.alignCenter}>
          <PrivacyPolicy />
        </div>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_CAPTHA_KEY}
        size="invisible"
      />
    </>
  );
}

export { LoginSMSForm };
