import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useUser } from 'store/user';

function useRegister() {
  const [registerError, setRegisterError] = useState(null);
  const [registerLoading, setRegisterLoading] = useState(false);

  const navigate = useNavigate();

  const { phone_number, first_name, last_name, setAuth } = useUser((state) => ({
    phone_number: state.user.phone_number,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    setAuth: state.setAuth,
  }));

  async function onRegister(data) {
    setRegisterLoading(true);

    try {
      const response = await $api.post(urls.register, {
        phone_number: phone_number,
        first_name: first_name,
        last_name: last_name,
        code: data.code,
      });
      localStorage.setItem('token', response.data?.access_token);

      setAuth(true);
      navigate('/profile/info');
      localStorage.removeItem('phone_number');
    } catch (error) {
      console.log('Ошибка при регистрации пользователя', error);
      setRegisterError(error.response.data.message);
    } finally {
      setRegisterLoading(false);
    }
  }
  return { registerError, registerLoading, onRegister };
}

export { useRegister };
