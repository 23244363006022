import React from 'react';
import styles from './SecondaryButton.module.scss';

function SecondaryButton({ onClick, text, disabled, type, id }) {
  return (
    <button
      onClick={onClick}
      className={styles.SecondaryButton}
      disabled={disabled}
      type={type || 'button'}
      id={id || null}
    >
      {text}
    </button>
  );
}

export { SecondaryButton };
