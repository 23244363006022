import React from 'react';
import styles from '../../../LoginPage.module.scss';

import { LoginForm } from 'entities/Auth/Login/LoginForm';

function LoginPage() {
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Вход</h2>
      <LoginForm />
    </div>
  );
}

export { LoginPage };
