import React from 'react';
import styles from './MapSection.module.scss';

import { YaMap } from 'shared/ui/YaMap';
import { Addresses } from 'widgets/Addresses';

import {
  storesAddresses,
  jewsAddresses,
} from 'widgets/Addresses/model/stores-data';

// mapType может быть "jews" либо "stores"
function MapSection({ mapType }) {
  // По-дефолту будем устанавливать карту с филиалами, stores
  return (
    <div className={styles.mapSection}>
      <YaMap mapType={mapType} />
      <Addresses
        title={mapType === 'jews' ? jewsAddresses.title : storesAddresses.title}
        data={mapType === 'jews' ? jewsAddresses : storesAddresses}
      />
    </div>
  );
}

export { MapSection };
