import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function useFetchOurItems() {
  const [ourItems, setOurItems] = useState([]);
  const [ourItemsLoading, setOurItemsLoading] = useState(false);
  const [ourItemsError, setOurItemsError] = useState(null);

  // Нужно для вывода товаров
  const limit = 32;
  const offset = 0;
  const type = 'Наше';

  async function fetchOurItems() {
    setOurItemsLoading(true);

    try {
      const response = await $api.get(
        `${urls.getItems}?limit=${limit}&offset=${offset}&trade_offer_type=${type}`,
      );

      setOurItems(response.data.results);
      setOurItemsError(null);
    } catch (error) {
      setOurItemsError(error.message);
    } finally {
      setOurItemsLoading(false);
    }
  }

  return { ourItems, ourItemsLoading, ourItemsError, fetchOurItems };
}

export { useFetchOurItems };
