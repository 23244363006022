import styles from './RepairPages.module.scss';
import { repairTableData } from '../model/table-data';
import { useJewRepairModal } from 'entities/Modal/ui/components/JewelryRepairModal/lib/store';
import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { MapSection } from 'widgets/MapSection';
import { RepairTable } from 'entities/RepairTagble';

function RepairPage() {
  const setActive = useJewRepairModal((state) => state.setActiveModal);

  return (
    <>
      <h1 className={styles.title}>Ремонт ювелирных изделий</h1>
      <div className={styles.remontPage}>
        <div className={styles.remontContainer}>
          <div className={styles.info}>
            <p className={styles.text}>
              В процессе ношения звенья золотой или серебряной цепочки трутся
              друг о друга. Это приводит к их постепенному перетиранию. Если на
              цепочке носят подвеску, стирание звеньев происходит значительно
              быстрее. При подборе подвески и цепочки необходимо помнить, что
              вес цепочки должен превышать вес подвески не менее чем в 2 раза. В
              противном случае срок эксплуатации цепочки будет значительно
              меньше. Если вес подвески значительно превышает вес цепочки, это
              может привести не только к интенсивному перетиранию звеньев, но и
              к вытягиванию всего плетения. Вытянутые звенья как правило не
              подлежат ремонту. Также при выборе цепочки или браслета следует
              обратить внимание на вид плетение: пустотелое или цельное. Срок
              эксплуатации пустотелых цепей значительно меньше, чем цельных. Для
              того, чтобы цепочка прослужила вам долгие годы, необходимо следить
              за состоянием звеньев и вовремя обращаться за профессиональной
              помощью к ювелирам.
            </p>
            <div className={styles.btn}>
              <SecondaryButton
                text="Создать заявку на ремонт"
                onClick={() => setActive(true)}
              />
            </div>
          </div>
          <img src="../img/repair-img/repair.webp" alt="" />
        </div>
        {repairTableData && (
          <div className={styles.tablesSection}>
            {repairTableData.map((table, index) => (
              <RepairTable
                key={index}
                title={table.title}
                tableData={table.data}
              />
            ))}
          </div>
        )}
        <div className={styles.mapSection}>
          <h2>Где мы находимся</h2>
          <MapSection mapType="stores" />
        </div>
      </div>
    </>
  );
}

export { RepairPage };
