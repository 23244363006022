import React from 'react';
import styles from './RepairTable.module.scss';

function RepairTable({ title, tableData }) {
  return (
    <div className={styles.repairTable}>
      <h2>{title}</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{null}</th>
            <th className={styles.centerCell}>Серебро</th>
            <th className={styles.centerCell}>Золото</th>
          </tr>
        </thead>
        {tableData ? (
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td className={styles.nameCell}>
                  {index + 1}. {row.name}
                </td>
                <td className={styles.centerCell}>{row.silverPrice}</td>
                <td className={styles.centerCell}>{row.goldenPrice}</td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
    </div>
  );
}

export { RepairTable };
