import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Categories.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import { CategoryItem } from 'shared/ui/CategoryItem';
import { categoriesData } from '../model/data-categories';
import { useSearch } from 'store/search';

function Categories() {
  const navigate = useNavigate();

  // Сохраняю параметры поиска, если они были заданы
  const searchParam = useSearch((state) => state.search);
  const search = searchParam ? `search=${searchParam}` : '';

  const onClickCategory = (param) => {
    navigate(`/catalog/?page=1&category=${param}&${search}`);
  };

  return (
    <div className="categorySwiperContainer">
      <Swiper
        className="categorySwiper"
        slidesPerView="auto"
        spaceBetween={8}
        slidesPerGroup={2}
        breakpoints={{
          768: {
            spaceBetween: 24,
          },
        }}
      >
        {categoriesData.map((category) => (
          <SwiperSlide
            key={category.id}
            onClick={() => onClickCategory(category.value)}
          >
            <CategoryItem img={category.img} title={category.title} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export { Categories };
