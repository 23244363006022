import React, { useState } from 'react';
import styles from './InfoDropdown.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

import { BsChevronDown } from 'react-icons/bs';

const animation = {
  hidden: { height: 0, opacity: 0 },
  show: {
    height: 'auto',
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
  },
};

function InfoDropdown({ title, children, isOpen }) {
  const [openDropdown, setOpenDropdown] = useState(isOpen | false);

  function handleOpenDropdown() {
    setOpenDropdown((prevState) => !prevState);
  }

  return (
    <div className={styles.dropdownContainer}>
      <div
        className={styles.titleContainer}
        onClick={handleOpenDropdown}
        role="button"
      >
        <h3
          className={
            openDropdown ? styles.dropdownTitleActive : styles.dropdownTitle
          }
        >
          {title}
        </h3>
        <BsChevronDown
          className={openDropdown ? styles.rowReverse : styles.row}
          size={20}
        />
      </div>

      <AnimatePresence>
        {openDropdown && (
          <motion.div
            className={styles.dropdownContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            <div className={styles.dropdownChildren}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export { InfoDropdown };
