export const work = [
  {
    id: 1,
    position: 'right',
    name: 'Изготовление ювелирных изделий',
    type: 'making',
    title: 'Ваши ювелирные мечты в реальности',
    history:
      'Как часто вы задумывались о том, что на витринах нет того изделия, которое бы вам хотелось передавать из поколения в поколение? Создайте украшение, отражающее вашу индивидуальность вместе с Ювелирным Домом Grand Gold. Мы предлагаем вам навыки наших ювелиров для того, чтобы воплотить желаемое в реальность. Задача наших мастеров сделать качество доступным для каждого!',
    img: 'workTypesRightt.webp',
  },

  {
    id: 2,
    position: 'left',
    name: 'Ремонт ювелирных украшении ',
    type: 'repair',
    title: 'Ваши ювелирные мечты в реальности',
    history:
      'Ваше любимое украшение потеряло прежний вид? В Ювелирном Доме Grand Gold есть возможность придать ему привычный блеск и устранить поломки изделия любой сложности. В мастерской Ювелирного Дома Grand Gold обеспечивают выдающийся уровень качества сервиса и товара, проверяют каждый заказ по 22 пунктам чек-листа, чтобы гарантировать, что вы получите результат отвечающий вашим ожиданиям!',
    img: 'workTypesLeft.webp',
  },

  {
    id: 3,
    position: 'right',
    name: 'Ремонт лазерной пайкой',
    type: 'repair',
    title: 'Ваши ювелирные мечты в реальности',
    history:
      'Ювелиры в Grand Gold осуществляют профессиональный ремонт, чтобы восстановить ваши украшения и улучшить их внешний вид. Методом гальванизации наши специалисты покроют украшение тонким слоем драгоценного металла, выбранного вами цвета. При помощи лазерной пайки наши мастера отремонтируют брендовые украшения, которые требуют более тонкую ювелирную работу.',
    img: 'workTypesRight.webp',
  },

  {
    id: 4,
    position: 'left',
    name: 'Работа с драгоценными камнями и металлом',
    type: 'making',
    title: 'Ваши ювелирные мечты в реальности',
    history:
      'Наша команда специалистов готова разработать украшение по вашему эскизу или совместно с нашими художниками, а также создать реплику известных брендовых моделей. Украшение может быть выполнено по вашему желанию в различных вариациях цвета, типа металла, размера и количества вставок. ',
    img: 'workTypesLeftt.webp',
  },
];
