import { Link } from 'react-router-dom';
import styles from './NewsBanners.module.scss';

import { MainBannerSkellaton } from 'shared/ui/Skelletons/MainBanner';

function NewsBanners({ image, url, title, description, loading }) {
  if (loading) {
    return <MainBannerSkellaton />;
  }

  return (
    <div className={styles.banner}>
      <Link to={url ? url : '#'}>
        <img src={image} alt={title} />
      </Link>
      {title && (
        <div className={styles.info}>
          <Link to={url ? url : '#'}>
            <h1 className={styles.title}>{title}</h1>
          </Link>
          {description && <p className={styles.text}>{description}</p>}
        </div>
      )}
    </div>
  );
}

export { NewsBanners };
