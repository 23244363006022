import React from 'react';
import styles from './ItemMainSkelleton.module.scss';

function ItemMainSkelleton() {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.photoSlider}></div>
      <div className={styles.itemInfo}>
        <h2 className={styles.title}></h2>
        <p className={styles.bonus}></p>
        <div className={styles.prices}>
          <h2 className={styles.price}></h2>
          <h2 className={styles.oldPrice}></h2>
        </div>
        <p className={styles.description}></p>
        <div className={styles.itemButtons}>
          <div className={styles.button}></div>
          <div className={styles.button}></div>
        </div>
      </div>
    </div>
  );
}

export { ItemMainSkelleton };
