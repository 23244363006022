import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ItemBrand.module.scss';

import { itemsBrand } from '../model/itemBrand-data';

function ItemBrand() {
  return (
    <div className={styles.itemsBrandSection}>
      <h2 className={styles.mainTitle}>Изготовим цепи на заказ</h2>

      <div className={styles.itemsBrandContainer}>
        {itemsBrand.map((item) => (
          <Link
            to={item.url}
            className={styles.itemBrandContainer}
            key={item.id}
          >
            <h3 className={styles.title}>{item.title}</h3>
            <div className={styles.imgContainer}>
              <img
                className={styles.image}
                src={`./img/jewerly-brands/ItemBrand/${item.img}`}
                alt={item.title}
                loading="lazy"
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export { ItemBrand };
