import React from 'react';
import styles from './CatalogItemsError.module.scss';

import { useFilters } from 'store/filters';
import { useCatalogPagination } from 'store/pagination';
import { useSearch } from 'store/search';
import { useSort } from 'store/sort';

import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { CatalogSkelleton } from 'shared/ui/Skelletons/Catalog';

export default function CatalogItemsError({ error, loading }) {
  const resetFilters = useFilters((state) => state.resetFilters);
  const setPaginationState = useCatalogPagination(
    (state) => state.setPagination,
  );
  const setSortState = useSort((state) => state.setSort);
  const setSearchState = useSearch((state) => state.setSearch);

  // Скидываем состояния на дефолтные
  const handleResetFilters = () => {
    resetFilters();
    setPaginationState(1);
    setSortState('-id');
    setSearchState('');
  };

  return (
    <>
      {!loading ? (
        <div className={styles.errorContainer}>
          <div className={styles.contentError}>
            <h3 className={styles.errorMessage}>{error}</h3>
            <div>
              <SecondaryButton
                text="Сбросить фильтры"
                onClick={handleResetFilters}
              />
            </div>
          </div>
        </div>
      ) : (
        <CatalogSkelleton />
      )}
    </>
  );
}
