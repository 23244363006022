import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from '../../StoresPages.module.scss';

import { storesAddresses } from 'widgets/Addresses/model/stores-data';
import { dlg } from '../../model/imgDataStores';

import { useImageModal } from 'entities/Modal/ui/components/ImageModal/lib/store';

import AddressContacts from 'widgets/Addresses/components/AddressContacts/AddressContacts';
import AddressItem from 'widgets/Addresses/components/AddressItem/AddressItem';
import { MapSection } from 'widgets/MapSection';
import { ImageModal } from 'entities/Modal/ui/components/ImageModal';
import { StoresSlider } from 'widgets/Sliders/ui/StoresSlider';

function DolgopolovaStore() {
  // Состояние модалки с открытием изображения и его индекс
  const setActiveImgModal = useImageModal((state) => state.setActiveModal);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Открываем фото на весь экран
  function onOpenImageModal(index) {
    setActiveImgModal(true);
    setCurrentImageIndex(index);
  }

  return (
    <>
      <div className={styles.storePage}>
        <h1 className={styles.storeTitle}>
          Магазин на {storesAddresses.stores[0].address}
        </h1>
        <div className={styles.storeContainer}>
          <div className={styles.addresses}>
            <div className={styles.info}>
              <AddressItem
                key={storesAddresses.stores[0].id}
                url={storesAddresses.stores[0].url}
                address={storesAddresses.stores[0].address}
                info={storesAddresses.stores[0].info}
                time={storesAddresses.stores[0].time}
              />
              {storesAddresses.stores[0].repairTime && (
                <div className={styles.repairTimeContainer}>
                  <h3>Время работы ювелира</h3>
                  <p>{storesAddresses.stores[0].repairTime}</p>
                </div>
              )}
            </div>
            <AddressContacts />
          </div>
          <StoresSlider data={dlg} onOpenImageModal={onOpenImageModal} />
        </div>
        <h2 className={styles.storeTitle}>
          Адреса Ювелирных салонов Grand Gold
        </h2>
        <MapSection />
      </div>
      {createPortal(
        <ImageModal>
          <img src={dlg[currentImageIndex].img} alt={dlg.name} />
        </ImageModal>,
        document.body,
      )}
    </>
  );
}

export { DolgopolovaStore };
