import React, { useEffect } from 'react';
import styles from './ModalLayout.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

import { CloseIcon } from 'shared/ui/CloseIcon';

const animationContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: { opacity: 0 },
};

const animation = {
  hidden: { y: 100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: { y: 400, opacity: 0 },
};

function ModalLayout({ active, onClose, title, message, children }) {
  // Запрещаю скрол, при открытии модалки
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [active]);

  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={styles.modalActive}
          variants={animationContainer}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <motion.div
            className={styles.modalContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
            onClick={(event) => event.stopPropagation()}
          >
            <span className={styles.closeSpanContainer}>
              <CloseIcon onClick={onClose} size={24} />
            </span>
            {title && <h2 className={styles.title}>{title}</h2>}
            {message && <p className={styles.message}>{message}</p>}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { ModalLayout };
