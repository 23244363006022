import { useFilters } from 'store/filters';

function CatalogTitle() {
  const categories = useFilters((state) => state.filters.category || '');

  const formatCategories = (string) => {
    const arr = string.split(';');

    if (arr.length < 2) {
      return arr.join(', ');
    } else if (arr.length <= 5) {
      const lastElement = arr.pop();
      return arr.join(', ') + ' и ' + lastElement;
    } else {
      return 'Каталог ювелирных изделий';
    }
  };

  return (
    <h1>
      {categories ? formatCategories(categories) : 'Каталог ювелирных изделий'}
    </h1>
  );
}

export { CatalogTitle };
