// Создайте маппинг параметров URL к ожидаемым ключам
const paramMapping = {
  // Категории
  sergi: 'category',
  tsepi: 'category',
  braslety: 'category',
  koltsa: 'category',
  podveski: 'category',
  broshi: 'category',
  pechatki: 'category',
  kole: 'category',
  chasy: 'category',
  souvenir: 'category',
  gaitani: 'category',
  slitki: 'category',
  zaponki: 'category',
  pirsing: 'category',
  // Податегории
  obruchalnye: 'subcategory',
  pusety: 'subcategory',
  // Материал
  'iz-zolota': 'material',
  's-serebrom': 'material',
  // Для кого
  muzhskie: 'forwhom',
  // Вставки
  fianity: 'vstavki',
  's-brilliantom': 'vstavki',
  korund: 'vstavki',
  yantar: 'vstavki',
  emal: 'vstavki',
  rubin: 'vstavki',
  topaz: 'vstavki',
  's-sapfirom': 'vstavki',
  's-ametistom': 'vstavki',
  izumrud: 'vstavki',
  citrin: 'vstavki',
  granat: 'vstavki',
  's-zhemchugom': 'vstavki',
  malahit: 'vstavki',
  dragocennye: 'vstavki',
  podelochnye: 'vstavki',
  poludragocennye: 'vstavki',
  sinteticheskie: 'vstavki',
  'bez-kamnej': 'vstavki',
  // Цвет металла
  'iz-krasnogo-zolota': 'color_metal',
  'iz-zheltogo-zolota': 'color_metal',
  'iz-kombinirovannogo-zolota': 'color_metal',
  'iz-belogo-zolota': 'color_metal',
  // Не все из вышеперечисленных параметров используются в сео
};

// Создал, чтобы хранить соответствия к параметров к категории
function createFilters(category, subcategory, material, forwhom, vstavki) {
  const filters = {};

  Object.keys(paramMapping).forEach((urlParam) => {
    const filterKey = paramMapping[urlParam];
    if (
      urlParam === category ||
      urlParam === subcategory ||
      urlParam === material ||
      urlParam === forwhom ||
      urlParam === vstavki
    ) {
      filters[filterKey] = urlParam;
    }
  });
  return filters;
}

export { paramMapping, createFilters };
