import React, { useEffect, useState } from 'react';
import styles from './OffersDropdown.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

import { BsChevronDown } from 'react-icons/bs';

const animation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
  },
};

export default function OffersDropdown({ title, children, isOpen }) {
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    setOpenDropdown(isOpen || false);
  }, [isOpen]);

  return (
    <div
      className={styles.dropdownContainer}
      onClick={() => setOpenDropdown((prevState) => !prevState)}
      role="button"
    >
      <div className={styles.titleContainer}>
        <h3
          className={
            openDropdown ? styles.dropdownTitleActive : styles.dropdownTitle
          }
        >
          {title || 'Название'}
        </h3>
        <BsChevronDown
          className={openDropdown ? styles.rowReverse : styles.row}
          size={24}
        />
      </div>
      <AnimatePresence>
        {openDropdown && (
          <motion.div
            className={styles.dropdownContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
