import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function useGetOrders() {
  const [getOrdersLoading, setGetOrdersLoading] = useState(false);
  const [getOrdersError, setGetOrdersError] = useState(null);
  const [ordersEmpty, setOrdersEmpty] = useState(null);
  const [orders, setOrders] = useState([]);

  async function getOrders() {
    setGetOrdersLoading(true);
    setOrdersEmpty(null);

    try {
      const response = await $api.get(urls.getOrders);

      const result = response.data.results;

      // Замедляю загрузку для плавности
      setTimeout(() => {
        if (result.length !== 0) {
          setOrders(result);
          setOrdersEmpty(false);
        } else {
          setOrdersEmpty(true);
        }
      }, [500]);
    } catch (error) {
      setGetOrdersError(error.message);
    } finally {
      setGetOrdersLoading(false);
    }
  }

  return { orders, ordersEmpty, getOrdersLoading, getOrdersError, getOrders };
}

export { useGetOrders };
