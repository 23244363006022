import React, { useEffect, useState } from 'react';
import styles from './Gallery.module.scss';

import { useWindowSize } from 'shared/lib/windowSizeChecker';

function Gallery() {
  const [isMobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize <= 1023) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, [windowSize]);

  return (
    <div className={styles.container}>
      <div className={styles.vertical + ' ' + styles.firstPhoto}></div>
      <div
        className={styles.horizontal + ' ' + styles.secondPhoto}
        id="secondPhoto"
      ></div>
      {isMobile ? (
        <div className={styles.vertical + ' ' + styles.thirdPhoto}></div>
      ) : null}
      <div className={styles.horizontal + ' ' + styles.fourthPhoto}></div>
    </div>
  );
}

export { Gallery };
