export const materialStore = [
  {
    name: 'Золото',
    value: 'Золото',
  },
  {
    name: 'Серебро',
    value: 'Серебро',
  },
  {
    name: 'Платина',
    value: 'Платина',
  },
];
