import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { colorStore } from '../../../../model/colorStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function ColorMetalFilter() {
  const { color, setFilters } = useFilters((state) => ({
    color: state.filters.color_metal || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (value === color) {
      setFilters('color_metal', '');
    } else {
      setFilters('color_metal', value);
    }
  };

  return (
    <FilterDropdown title="Оттенок металла" isOpen={!!color}>
      <div className={styles.contentContainer}>
        {colorStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={color.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { ColorMetalFilter };
