import React from 'react';
import styles from './dostavka.module.scss';

import { InfoDropdown } from 'shared/ui/InfoDropdown';

function Dostavka() {
  return (
    <>
      <h1 className={styles.dostavkaMainTitle}>Доставка</h1>
      <section className={styles.dostavkaContainer}>
        <div className={styles.dostavkaBox}>
          <p className={styles.dostavkaParagraph}>
            Заказанный товар доставляется курьерами (лично в руки), имеющими
            лицензию на перевозку драгоценных металлов и изделий из них.
            Обращаем Ваше внимание, что данные службы сотрудничают с нами в
            рамках подписанных договоров и на условиях, оговоренных в данных
            документах, поэтому наша компания вынуждена соблюдать и выполнять их
            как Продавец, а Вы — как покупатель интернет-магазина.
          </p>
        </div>
        <div className={styles.dostavkaBox}>
          <h2 className={styles.dostavkaTitle}>
            Доставляем по всей России, а также в Казахстан, Беларусь и за рубеж
          </h2>
          <p className={styles.dostavkaParagraph}>
            Доставка ювелирных украшений, заказанных в нашем интернет-магазине,
            осуществляется во все, даже труднодоступные уголки РФ, а также в
            Казахстан, Беларусь и за рубеж.
          </p>
          <p className={styles.dostavkaParagraph}>
            Доставка в Казахстан и Беларусь осуществляется только по 100%
            предоплате в связи с отличием валюты от российского рубля. При
            оплате таких заказов в банках своей страны по квитанции оператор
            пересчитает Вам сумму в национальной валюте, подлежащей к оплате для
            того, чтобы нам была зачислена сумма в российских рублях. Комиссию
            уточняйте у сотрудников банка!
          </p>
        </div>

        <div className={styles.dropdownLogo}>
          <div className={styles.dropdownLogoBox}>
            <img
              className={styles.dropdownImage}
              src="/img/dostavka-img/russian_post.webp"
              alt="pochta_logo"
            />
            <div className={styles.dropdownLogoText}>
              <p>Стоимость от 300 руб.</p>
              <p>Срок от 3 до 10 дней</p>
            </div>
          </div>
          <div className={styles.dropdownLogoBox}>
            <img
              className={styles.dropdownLogoBoxx}
              src="/img/dostavka-img/cdek-logo.webp"
              alt="cdek_logo"
            />
            <div className={styles.dropdownLogoText}>
              <p>Стоимость от 500 руб.</p>
              <p>Срок от 5 до 10 дней</p>
            </div>
          </div>
        </div>

        <div className={styles.dropdownBox}>
          <InfoDropdown title="Нюансы при заказе с доставкой Почтой России">
            В случае, если при оформлении заказа Вы выбрали способ доставки
            Почтой России, то забирать заказ Вы будете в отделении Почты России
            с тем индексом, который Вы указали при оформлении. В заказах,
            отправленных Почтой России, прилагается так же опись вложения, один
            экземпляр которого будет в самой бандероли, а второй остается у нас
            при отправке. Однако при получении заказа Почтой осмотреть и
            примерить украшения до момента их оплаты невозможно. Вам необходимо
            произвести оплату оператору, и только после этого Вам выдадут в руки
            бандероль.
          </InfoDropdown>
          <InfoDropdown
            title="Нюансы при заказе с доставкой в сельскую местность и
                        малые населённые пункты"
          >
            В случае, если при оформлении заказа Вы выбрали способ доставки
            Почтой России, то забирать заказ Вы будете в отделении Почты России
            с тем индексом, который Вы указали при оформлении. В заказах,
            отправленных Почтой России, прилагается так же опись вложения, один
            экземпляр которого будет в самой бандероли, а второй остается у нас
            при отправке. Однако при получении заказа Почтой осмотреть и
            примерить украшения до момента их оплаты невозможно. Вам необходимо
            произвести оплату оператору, и только после этого Вам выдадут в руки
            бандероль.
          </InfoDropdown>
          <InfoDropdown title="Описание процесса передачи данных">
            В случае, если при оформлении заказа Вы выбрали способ доставки
            Почтой России, то забирать заказ Вы будете в отделении Почты России
            с тем индексом, который Вы указали при оформлении. В заказах,
            отправленных Почтой России, прилагается так же опись вложения, один
            экземпляр которого будет в самой бандероли, а второй остается у нас
            при отправке. Однако при получении заказа Почтой осмотреть и
            примерить украшения до момента их оплаты невозможно. Вам необходимо
            произвести оплату оператору, и только после этого Вам выдадут в руки
            бандероль.
          </InfoDropdown>
        </div>

        <div className={styles.dostavkaWarning}>
          <p>ВНИМАНИЕ</p>
          <br />
          <p>
            Независимо от выбранного вами способа доставки, заказ хранится в
            отделении Почты России либо в офисе транспортной компании не более
            двух недель со дня поступления на склад. По истечению этого срока
            бандероль возвращается обратно отправителю без уведомления об этом
            Покупателя. Сумма, оплаченная Клиентом за доставку, в таком случае,
            возврату не подлежит!
          </p>
        </div>
      </section>
    </>
  );
}

export { Dostavka };
