import { create } from 'zustand';
import { persist } from 'zustand/middleware';

let order = (set) => ({
  // Статус оплаты
  paid: false,
  setPaid: (param) => set({ paid: param }),
  // Способ оплаты
  payment_type: null,
  setPaymentType: (method) => set({ payment_type: method }),
  // Способ получения
  delivery_type: null,
  setDeliveryType: (method) => set({ delivery_type: method }),
  // Доставка до двери
  express_delivery: false,
  setExpressDelivery: (param) => set({ express_delivery: param }),
  // Адрес
  address: null,
  setAddRess: (adres) => set({ address: adres }),
  // Номер города из СДЭК виджета
  location_code: null,
  setLocationCode: (param) => set({ location_code: param }),
  // Координаты города из СДЭК виджета (нужно для точного рассчета при доставке до двери)
  geo_code: null,
  setGeocode: (param) => set({ geo_code: param }),
  // Номер пункта выдачи СДЭК
  pvz: null,
  setPVZ: (param) => set({ pvz: param }),
  // Стоимость доставки
  delivery_cost: null,
  setDeliveryCost: (param) => set({ delivery_cost: param }),

  // Cброс заказа
  resetOrder: () =>
    set({
      payment_type: null,
      delivery_type: null,
      express_delivery: false,
      address: null,
      location_code: null,
      geo_code: null,
      pvz: null,
      delivery_cost: null,
    }),

  // Cброс способа оплаты
  resetPaymentType: () =>
    set({
      payment_type: null,
    }),

  // Cброс способа получения и все что с ним связано
  resetDeliveryType: () =>
    set({
      delivery_type: null,
      express_delivery: false,
      address: null,
      location_code: null,
      geo_code: null,
      pvz: null,
      delivery_cost: null,
    }),

  // Частичный сброс способа получения при удалении товара из корзины
  // Нужно, чтобы повторно произвести рассчет стоимости доставки
  resetDeliveryTypePartly: () =>
    set({
      express_delivery: false,
      address: null,
      location_code: null,
      geo_code: null,
      pvz: null,
      delivery_cost: null,
    }),
});

order = persist(order, { name: 'order' });
export const useOrder = create(order);
