export function transliterateSubcategoryParam(param) {
  const transliterateSubcategoryParam = {
    obruchalnye: 'Обручальные',
    pusety: 'Пусеты',
    // Можно добавить еще соответствий
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterateSubcategoryParam.hasOwnProperty(param)) {
    return transliterateSubcategoryParam[param];
  } else {
    return undefined;
  }
}
