import React from 'react';
import styles from './Addresses.module.scss';

import { SocialMedia } from 'shared/ui/SocialMedia';
import AddressItem from '../components/AddressItem/AddressItem';
import AddressContacts from '../components/AddressContacts/AddressContacts';

function Addresses({ data }) {
  return (
    <div className={styles.addresses}>
      <h2 className={styles.title}>
        {data.title || `Наши филиалы в Нижнем Новгороде`}
      </h2>
      <div className={styles.addressesContainer}>
        {data.stores.map((data) => (
          <AddressItem
            key={data.id}
            url={data.url}
            title={data.title}
            address={data.address}
            info={data.info}
            time={data.time}
          />
        ))}
      </div>
      <div className={styles.contactsContainer}>
        <AddressContacts />
        <div className={styles.socialMedia}>
          <SocialMedia />
        </div>
      </div>
    </div>
  );
}

export { Addresses };
