import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styles from '../../../Modals.module.scss';

import validatePhone from 'shared/lib/validatePhone';
import ReCAPTCHA from 'react-google-recaptcha';

import useJewelryMakingModal from 'api/Modals/useJewelryMakingModal';
import { useUser } from 'store/user';
import { useJewMakingModalStore } from '../lib/store';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import { ModalLayout } from '../../ModalLayouts/StandartModal';
import { MainButton } from 'shared/ui/MainButton';
import { TelegramButton } from 'shared/ui/TelegramButton';
import { WatsappButton } from 'shared/ui/WatsappButton';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';

function JewelryMakingModal() {
  const { first_name, phone_number } = useUser((state) => ({
    first_name: state.user.first_name,
    phone_number: state.user.phone,
  }));
  const { isActive, setIsActive, items } = useJewMakingModalStore((state) => ({
    isActive: state.active,
    setIsActive: state.setActiveModal,
    items: state.items,
  }));
  const { loading, error, onSubmitJewMaking } = useJewelryMakingModal();
  const [name, setName] = useState(first_name || '');
  const [phoneNumber, setPhoneNumber] = useState(phone_number || '');
  const recaptchaRef = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await onSubmitJewMaking(data, first_name, phone_number, items);
      setIsActive(false);
    }
  };

  return (
    <ModalLayout
      active={isActive}
      onClose={() => setIsActive(false)}
      title="Оставьте заявку, и мы"
      message="изготовим для Вас уникальное украшение или произведем ремонт Вашего изделия"
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="text"
              {...register('first_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите свое имя',
                },
              })}
              placeholder="Введите имя"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </label>
          <div className={styles.error}>
            {errors?.first_name && (
              <p>{errors?.first_name?.message || 'Ошибка'}</p>
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="tel"
              onPaste={onPhonePaste}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              {...register('phone_number', {
                required: 'Обязательно к заполнению',
                validate: (value) => validatePhone(value.trim()),
              })}
              placeholder="+7 (900) 123 45 67"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </label>
          {error !== null ? (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          ) : (
            <div className={styles.error}>
              {errors?.phone_number && (
                <p>
                  {errors?.phone_number?.message || 'Кажется, здесь ошибка'}
                </p>
              )}
            </div>
          )}
        </div>
        <MainButton text="Отправить" loading={loading} type="submit" />
        <div className={styles.buttonsContainer}>
          <TelegramButton />
          <WatsappButton />
        </div>
        <div className={styles.alignCenter}>
          <PrivacyPolicy />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_CAPTHA_KEY}
          size="invisible"
        />
      </form>
    </ModalLayout>
  );
}

export { JewelryMakingModal };
