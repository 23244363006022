import React from 'react';
import styles from './LkButton.module.scss';
import { NavLink } from 'react-router-dom';

import { GoPersonFill } from 'react-icons/go';

function LkButton() {
  return (
    <NavLink
      to="profile/info"
      className={({ isActive }) =>
        isActive ? styles.iconLinkActive : styles.iconLink
      }
    >
      <GoPersonFill className={styles.icon} size={24} />
    </NavLink>
  );
}

export { LkButton };
