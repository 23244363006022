import React from 'react';
import styles from './NewsBannersSkelleton.module.scss';

function NewsBannersSkelleton() {
  return (
    <>
      <div className={styles.item}>
        <div className={styles.image}></div>
        <div className={styles.title}></div>
      </div>
      <div className={styles.item}>
        <div className={styles.image}></div>
        <div className={styles.title}></div>
      </div>
      <div className={styles.item}>
        <div className={styles.image}></div>
        <div className={styles.title}></div>
      </div>
    </>
  );
}

export { NewsBannersSkelleton };
