import React from 'react';
import styles from './CompanyInfo.module.scss';

function CompanyInfo() {
  return (
    <div className={styles.companyInfo}>
      <p>ИП Манеляк Людмила Александровна</p>
      <p>ИНН: 525629285613</p>
      <p>ООО «Ломбард «Золотой Гранд»</p>
      <p>ИНН: 5260480502</p>
    </div>
  );
}

export { CompanyInfo };
