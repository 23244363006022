import { useEffect } from 'react';
import styles from './MainPage.module.scss';

import { usePromoBanners } from 'api/Banners/usePromoBanners';
import { useFetchNewItems } from 'api/Catalog/useFetchNewItems';

import { Categories } from 'widgets/Categories';
import { CarouselItems } from 'widgets/CarouselItems';
import { MainSlider } from 'widgets/Sliders/ui/MainSlider';
import { Topic } from 'shared/ui/Topic';
import { Gallery } from 'entities/Gallery';

function MainPage() {
  const { loading, activePromoBanners, getPromoBanners } = usePromoBanners();
  const { newItems, newItemsLoading, fetchNewItems } = useFetchNewItems();

  useEffect(() => {
    getPromoBanners();
    fetchNewItems();
  }, []);

  return (
    <div className={styles.mainPage}>
      <MainSlider banners={activePromoBanners} loading={loading} />
      <Categories />
      <CarouselItems
        items={newItems}
        title="Новинки"
        link="catalog"
        loading={newItemsLoading}
      />
      <Gallery />
      <Topic />
    </div>
  );
}

export { MainPage };
