export const probStore = [
  { name: 'Золото 375', value: 'Золото 375' },
  { name: 'Золото 500', value: 'Золото 500' },
  { name: 'Золото 583', value: 'Золото 583' },
  { name: 'Золото 585', value: 'Золото 585' },
  { name: 'Золото 750', value: 'Золото 750' },
  { name: 'Палладий 850', value: 'Палладий 850' },
  { name: 'Золото 900', value: 'Золото 900' },
  { name: 'Серебро 925', value: 'Серебро 925' },
  { name: 'Золото 958', value: 'Золото 958' },
  { name: 'Золото 999', value: 'Золото 999' },
];
