import { useState } from 'react';
import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

export default function useFaqTopics() {
  const [topics, setTopics] = useState([]);
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [topicsError, setTopicsError] = useState(null);

  const getFaqTopics = async () => {
    setTopicsLoading(true);

    try {
      const response = await $api.get(urls.getFaqTopics);
      setTopics(response.data);
    } catch (error) {
      console.log(error);
      setTopicsError(error.message);
    } finally {
      setTopicsLoading(false);
    }
  };

  return { topics, topicsLoading, topicsError, getFaqTopics };
}
