export function transliterateMaterialParam(param) {
  const transliterationMaterialParam = {
    'iz-zolota': 'Золото',
    's-serebrom': 'Серебро',
    // Фильтр может принять больше вариантов параметров, но
    // сеошники пока не дали свои сеошные названия
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterationMaterialParam.hasOwnProperty(param)) {
    return transliterationMaterialParam[param];
  } else {
    return undefined;
  }
}
