import React from 'react';
import styles from '../../../LoginPage.module.scss';

import { LoginSMSForm } from 'entities/Auth/Login/LoginSMSForm';

function LoginBySMSPage() {
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Вход</h2>
      <p className={styles.text}>Мы отправим Вам код по СМС</p>
      <LoginSMSForm />
    </div>
  );
}

export { LoginBySMSPage };
