import React from 'react';
import { useForm } from 'react-hook-form';
import styles from '../../../Forms.module.scss';

import useLogin from 'api/Auth/Login/useLogin';

import ResendSignInSMSCode from '../../../ResendCode/BySMS/ResendSignInSMSCode';
import ResendSignInCode from '../../../ResendCode/ByCall/ResendSignInCode';
import validatePassCode from 'shared/lib/validatePassCode';
import { MainButton } from 'shared/ui/MainButton';

function LoginCodeForm({ type }) {
  const { loginError, loginLoading, onLogin } = useLogin();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    await onLogin(data);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <label>
            <input
              className={styles.input}
              type="tel"
              autoFocus
              {...register('code', {
                required: 'Не может быть пустым',
                minLength: {
                  value: 4,
                  message: 'Введите 4-х значный код',
                },
                maxLength: {
                  value: 4,
                  message: 'Введите 4-х значный код',
                },
                validate: (value) => validatePassCode(value.trim()),
              })}
              placeholder="Введите код"
            />
          </label>
          {loginError !== null ? (
            <div className={styles.error}>
              <p>{loginError}</p>
            </div>
          ) : (
            <div className={styles.error}>
              {errors?.code && (
                <p>{errors?.code?.message || 'Кажется, здесь ошибка'}</p>
              )}
            </div>
          )}
        </div>
      </div>

      <MainButton type="submit" text="Подтвердить" loading={loginLoading} />
      {type === 'sms' ? (
        <ResendSignInSMSCode setError={loginError} />
      ) : (
        <ResendSignInCode />
      )}
    </form>
  );
}

export { LoginCodeForm };
