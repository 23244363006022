import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import $api from '../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

let user = (set) => ({
  isAuth: false,
  user: {
    // Пользователь
    // id: null,
    // first_name: null,
    // last_name: null,
    // phone_number: null,
    // email: null,
    // is_mailing: null,
  },
  loading: false,
  error: null,
  setAuth: (param) => set({ isAuth: param }),
  regUser: (user) => set({ user: user }),
  getUser: async () => {
    set({ loading: true });

    try {
      const response = await $api.get(urls.getUser);

      set({ user: { ...response.data }, error: null });
    } catch (error) {
      set({ error: error.message });
      console.log('Ошибка при получении пользователя', error);
    } finally {
      set({ loading: false });
    }
  },
  patchUser: async (first_name, last_name, email, is_mailing) => {
    set({ loading: true });

    const userData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      is_mailing: is_mailing,
    };

    try {
      const response = await $api.patch(urls.getUser, userData);

      set({ user: { ...response.data }, error: null });
    } catch (error) {
      console.log('Ошибка при изменении пользователя', error);
    } finally {
      set({ loading: false });
    }
  },
  setLogOut: async () => {
    set({ loading: true });

    try {
      await $api.get(urls.logout);
      localStorage.removeItem('token');
      set(() => {
        return {
          isAuth: false,
          user: {},
        };
      });
    } catch (error) {
      console.log('Ошибка при выходе', error);
      set(() => {
        return {
          isAuth: false,
          user: {},
        };
      });
    } finally {
      set({ loading: false, error: null });
    }
  },
});

user = persist(user, { name: 'user' });
export const useUser = create(user);
