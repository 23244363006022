import React from 'react';
import styles from './CartSkelleton.module.scss';

function CartSkelleton() {
  return (
    <div className={styles.container}>
      <div className={styles.cartSkelletonsContainer}>
        <div className={styles.cartSkelleton}>
          <div className={styles.cartItems}></div>
          <div className={styles.cartReciev}></div>
        </div>
        <div className={styles.total}></div>
      </div>
    </div>
  );
}

export { CartSkelleton };
