export function smoothScrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function scrollTop() {
  window.scrollTo(0, 0);
}
