function isStoreOpen() {
  // Московское времени имеет смещение +3 часа относительно UTC
  const moscowTimezoneOffset = 3;
  const currentDatetime = new Date();
  const currentHour = currentDatetime.getUTCHours() + moscowTimezoneOffset;

  const openingTime = 9;
  const closingTime = 19;

  return currentHour >= openingTime && currentHour <= closingTime;
}

export default isStoreOpen;
