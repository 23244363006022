import { create } from 'zustand';

let sort = (set) => ({
  sortItems: [
    {
      name: 'Новинки',
      value: null,
    },
    {
      name: 'Дорогие',
      value: '-price',
    },
    {
      name: 'Недорогие',
      value: 'price',
    },
    {
      name: 'С большей скидкой',
      value: 'discount',
    },
  ],
  sort: {
    name: 'Новинки',
    value: null,
  },
  setSort: (value) => {
    set((state) => ({
      sort: state.sortItems.find((item) => item.value === value) || state.sort,
    }));
  },
});

export const useSort = create(sort);
