import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';

// import LogoWebp from "/img/logotype-img/logo.webp";
// import SubLogoWebp from "/img/logotype-img/sublogo.webp";

import { smoothScrollTop } from 'shared/lib/scrollTop';

function Logo() {
  return (
    <div className={styles.brand}>
      <div className={styles.container}>
        <Link className={styles.link} to="/" onClick={smoothScrollTop}>
          <img
            className={styles.logo}
            src="/img/logotype-img/logo.webp"
            alt="logo"
          />
          <img
            className={styles.sublogo}
            src="/img/logotype-img/sublogo.webp"
            alt="sub-ogo"
          />
        </Link>
      </div>
    </div>
  );
}

export { Logo };
