export const paymentTypes = [
  {
    title: 'Оплата наличными в филиале',
    key: 'cash',
  },
  // {
  //     title: 'Рассрочка',
  //     subTitle: 'Без % до 3 месяцев',
  //     key: 'instalments',
  // },
  {
    title: 'Наложенный платеж',
    subTitle: 'Оплата после получения заказа в пункте выдачи',
    key: 'cod',
  },
  {
    title: 'Картой онлайн',
    subTitle: 'Оплата банковской картой на сайте',
    key: 'card',
  },
  // Есть еще такие методы
  // {
  //     title: 'Оплата при получении',
  //     subTitle: 'Картой или наличными в нашем магазине',
  //     key: 'cash',
  // },
  // {
  //     title: 'СБП',
  //     subTitle: 'Оплата по QR-коду',
  //     key: 'sbp',
  // },
];
