import React from 'react';
import styles from '../../../RegisterPage.module.scss';

import RegSMSCodeForm from 'entities/Auth/Register/RegSMSCodeForm';

function RegisterBySMSPage() {
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Регистрация</h2>
      <p className={styles.text}>Мы отправим Вам код по СМС</p>
      <RegSMSCodeForm />
    </div>
  );
}

export { RegisterBySMSPage };
