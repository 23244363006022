import React, { useState, useEffect } from 'react';
import styles from '../../../Filter.module.scss';

import { useFilters } from 'store/filters';
import {
  sergi,
  braslety,
  koltsa,
  podveski,
} from '../../../../model/subcategoryStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function SubcategoryFilter() {
  const [subcategoryArray, setSubcategoryArray] = useState([]);
  const { setFilters, category, subcategory } = useFilters((state) => ({
    setFilters: state.setFilters,
    category: state.filters.category,
    subcategory: state.filters.subcategory || '',
  }));

  const handleChange = (value) => {
    if (subcategory.includes(value)) {
      // При снятии активного чекбокса
      const updatedValues = subcategory
        .split(';')
        .filter((subcategory) => subcategory !== value)
        .join(';');
      setFilters('subcategory', updatedValues);
    } else {
      const updatedValues = subcategory ? `${subcategory};${value}` : value;
      setFilters('subcategory', updatedValues);
    }
  };

  // Условия отображения нужного фильтра
  useEffect(() => {
    if (category === 'Подвески') {
      setSubcategoryArray(podveski);
    }
    if (category === 'Браслеты') {
      setSubcategoryArray(braslety);
    }
    if (category === 'Кольца') {
      setSubcategoryArray(koltsa);
    }
    if (category === 'Серьги') {
      setSubcategoryArray(sergi);
    }
  }, [category]);

  return (
    ['Подвески', 'Браслеты', 'Кольца', 'Серьги'].includes(category) && (
      <FilterDropdown title="Подкатегория" isOpen={!!subcategory}>
        <div className={styles.contentContainer}>
          {subcategoryArray.map((item, index) => (
            <FilterCheckbox
              key={index}
              item={item}
              label={item.value}
              handleChange={() => handleChange(item.value)}
              checked={subcategory.includes(item.value)}
            />
          ))}
        </div>
      </FilterDropdown>
    )
  );
}

export { SubcategoryFilter };
