import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CardHorizontal.module.scss';

import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';

import { useLoginModal } from 'entities/Modal/ui/components/LoginModal/lib/store';
import { useFavorite } from 'store/favorite';
import { useUser } from 'store/user';

import { WarningBadge } from 'shared/ui/WarningBadge';
import { GoHeart } from 'react-icons/go';
import { GoHeartFill } from 'react-icons/go';
import { GoTrash } from 'react-icons/go';

function CardHorizontal({
  id,
  series,
  title,
  price,
  discount_price,
  discount,
  image,
  size,
  slug_url,
  quantity,
  is_active,
  isInFavorite,
  onRemoveFromCart,
}) {
  // Добавление в избранное
  const isAuth = useUser((state) => state.isAuth);
  const [imgSrc, setImgSrc] = useState(image);
  const [inFavorite, setInFavorite] = useState(() => isInFavorite);
  const setActiveLoginModal = useLoginModal((state) => state.setActiveModal);
  const { addToFavorite, removeFromFavorite } = useFavorite((state) => ({
    addToFavorite: state.addToFavorite,
    removeFromFavorite: state.removeFromFavorite,
  }));

  const onAddFavorite = () => {
    addToFavorite(id);
    setInFavorite(true);
  };

  const onRemoveFavorite = () => {
    removeFromFavorite(id);
    setInFavorite(false);
  };

  // Вызов модалки Вход в ЛК
  const onShowEnterModal = () => {
    setActiveLoginModal(true);
  };

  return (
    <div className={styles.cartItem}>
      <Link
        to={slug_url ? `/catalog/product/${slug_url}` : '#'}
        className={styles.cartItemImgContainer}
      >
        <img
          className={styles.cartItemImg}
          src={imgSrc}
          onError={() => setImgSrc('/img/no-image.webp')}
          alt={title}
          loading="lazy"
        />
        {!price || !is_active || !quantity ? (
          <div className={styles.badge}>
            <WarningBadge text="Под заказ" />
          </div>
        ) : null}
      </Link>
      <div className={styles.cartItemInfo}>
        <Link
          className={styles.cartItemLinkTitle}
          to={slug_url ? `/catalog/product/${slug_url}` : '#'}
        >
          {title}
        </Link>
        {price > 0 && is_active && quantity ? (
          <div className={styles.cartItemPrices}>
            <h3 className={styles.cartItemPrice}>
              {toCurrency.format(roundPrice(price))} руб.
            </h3>
            {discount > 0 && (
              <p className={styles.cartItemOldPrice}>
                {toCurrency.format(roundPrice(discount_price))} руб.
              </p>
            )}
          </div>
        ) : null}

        {/* Если нет series товара, то эту информацию выводить не нужно */}
        {series && (
          <div className={styles.cartItemDopInfo}>
            <p>Артикул: {series}</p>
            {size && <p>Размер: {size}</p>}
          </div>
        )}
      </div>
      {/* Если нет id товара, то этот функционал не может работать корректно */}
      {/* Применимо в ЛК в заказах */}
      {id && (
        <div className={styles.cartIcons}>
          {inFavorite ? (
            <GoHeartFill
              className={styles.favoriteIcon}
              onClick={onRemoveFavorite}
              size={24}
            />
          ) : (
            <GoHeart
              className={styles.favoriteIcon}
              onClick={isAuth ? onAddFavorite : onShowEnterModal}
              size={24}
            />
          )}

          <GoTrash
            className={styles.trashIcon}
            onClick={() => onRemoveFromCart(id)}
            size={24}
          />
        </div>
      )}
    </div>
  );
}

export { CardHorizontal };
