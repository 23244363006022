import React from 'react';

import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';

export default function TotalMainPrice({ summTotal, delivery_cost }) {
  const total = summTotal + (delivery_cost ?? 0);

  return <h2>Итого {toCurrency.format(roundPrice(total))} руб.</h2>;
}
