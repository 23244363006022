import React, { useEffect, useState } from 'react';
import styles from './VideoPlayer.module.scss';
import { useWindowSize } from 'shared/lib/windowSizeChecker';
import { CloseIcon } from 'shared/ui/CloseIcon';

function VideoPlayer({ isOpen, setIsOpen, url }) {
  const [height, setHeight] = useState(640);
  const [width, setWidth] = useState(640);
  const windowSize = useWindowSize();
  const isMobile = windowSize < 768;

  useEffect(() => {
    if (isMobile) {
      setHeight('80vh');
      setWidth('auto');
    } else {
      setHeight(668);
      setWidth('auto');
    }
  }, [windowSize]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={styles.fullscreenPlayerOverlay}
      onClick={() => setIsOpen(false)}
    >
      <div className={styles.playerWrapper}>
        {!isMobile && (
          <span className={styles.closeIconContainer}>
            <CloseIcon onClick={() => setIsOpen(false)} />
          </span>
        )}
        <video
          className={styles.player}
          onClick={(event) => event.stopPropagation()}
          src={url}
          style={{ height: height, width: width }}
          controls={!isMobile}
          autoPlay
          muted
        ></video>
      </div>
    </div>
  );
}

export { VideoPlayer };
