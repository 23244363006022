import React from 'react';
import '../../../styles/_pagination.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

function StoresSlider({ data, onOpenImageModal }) {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      grabCursor={true}
      slidesPerView={1}
      loop={true}
      className="storesBannerSlider"
    >
      {data.map((store, index) => (
        <SwiperSlide key={store.id} onClick={() => onOpenImageModal(index)}>
          <img src={store.img} alt={store.name} loading="lazy" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export { StoresSlider };
