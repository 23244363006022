import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function useJewelryBanners() {
  const [jewelryBanners, setJewelryBanners] = useState([]);
  const [jewelryBannersLoading, setJewelryBannersLoading] = useState(false);
  const [jewelryBannersError, setJewelryBannersError] = useState(false);

  async function GetJewelryBanners() {
    setJewelryBannersLoading(true);

    try {
      const response = await $api.get(urls.getJewelryBanners);

      setJewelryBanners(response.data);
    } catch (error) {
      console.log(error);
      setJewelryBannersError(error.message);
    } finally {
      setJewelryBannersLoading(false);
    }
  }

  return {
    jewelryBannersLoading,
    jewelryBannersError,
    jewelryBanners,
    GetJewelryBanners,
  };
}

export { useJewelryBanners };
