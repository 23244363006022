export const repairTableData = [
  {
    title: 'Чистка и полировка ювелирных изделий',
    data: [
      {
        name: 'Полировка золотых изделий',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Полировка серебряных изделий',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Полировка сложных украшений',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 400 руб.',
      },
      {
        name: 'Чистка изделия в ультразвуковой ванне',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
      {
        name: 'Чистка изделия вручную с использованием специальных средств',
        silverPrice: 'от 500',
        goldenPrice: 'от 500',
      },
    ],
  },
  {
    title: 'Нанесение на ювелирные изделия покрытия за 1 гр. изделия',
    data: [
      {
        name: 'Родирование (ярко-белый родий)',
        silverPrice: 'от 150 руб.',
        goldenPrice: 'от 150 руб.',
      },
      {
        name: 'Золочение (покрытие золотом 999 пробы)',
        silverPrice: '-',
        goldenPrice: '+500 р. за работу',
      },
      {
        name: 'Чернение (черный рутений)',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
    ],
  },
  {
    title: 'Ремонт цепей и браслетов',
    data: [
      {
        name: 'Пайка одного места разрыва',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Пайка одного места разрыва лазером',
        silverPrice: 'от 500 руб.',
        goldenPrice: 'от 600 руб.',
      },
      {
        name: 'Ремонт штифта',
        silverPrice: 'от 600 руб.',
        goldenPrice: 'от 900 руб.',
      },
      {
        name: 'Уменьшение длины цепочки или браслета (если 2 пайки)',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 600 руб.',
      },
      {
        name: 'Добавление звеньев (в зависимости от плетения)',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
      {
        name: 'Пайка одного места разрыва пустотелой цепи',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 400 руб.',
      },
      {
        name: 'Чистка и полировка цепи или браслета (в зависимости от плетения)',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 500 руб.',
      },
      {
        name: 'Ремонт браслета для часов',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 500 руб.',
      },
      {
        name: 'Полировка изделия весом до 20гр',
        silverPrice: 'от 500 руб.',
        goldenPrice: 'от 800 руб.',
      },
    ],
  },
  {
    title: 'Ремонт колец',
    data: [
      {
        name: 'Правка простого обручального кольца',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
      {
        name: 'Правка кольца сложной формы (без вставок)',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Правка кольца сложной формы (без камней, эмали и т.д.)',
        silverPrice: 'от 150 руб.',
        goldenPrice: 'от 250 руб.',
      },
      {
        name: 'Правка кольца со вставками',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Увеличение простого обручального кольца (на 1 размер)',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Увеличение кольца сложной формы (на 1 размер)',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 500 руб.',
      },
      {
        name: 'Увеличение кольца с добавлением фрагмента',
        silverPrice: 'от 800 руб.',
        goldenPrice: 'от 1000 руб.',
      },
      {
        name: 'Уменьшение кольца с вырезкой фрагмента',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 600 руб.',
      },
      {
        name: 'Полировка и мойка кольца (без вставок)',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Полировка и мойка кольца (со вставками)',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
    ],
  },
  {
    title: 'Ремонт сережек',
    data: [
      {
        name: 'Ремонт иглы серьги',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Замена иглы на серьге',
        silverPrice: 'от 600 руб.',
        goldenPrice: 'от 600 руб.',
      },
      {
        name: 'Ремонт фиксации замка серьги',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Правка серьги',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Правка иглы в англ.замке (для улучшения фиксации)',
        silverPrice: ' 100 руб.',
        goldenPrice: ' 100 руб.',
      },
      {
        name: 'Полировка пары серег',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 400 руб.',
      },
      {
        name: 'Чистка пары серег',
        silverPrice: '200 руб.',
        goldenPrice: '200 руб.',
      },
      {
        name: 'Восстановление эмали на серьге',
        silverPrice: 'от 1000 руб.',
        goldenPrice: 'от 1000 руб.',
      },
      {
        name: 'Закрепить камень в серьгах',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Вклейка жемчуга в серьгу',
        silverPrice: '200 руб.',
        goldenPrice: '200 руб.',
      },
      {
        name: 'Доработка сережек',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
      {
        name: 'Ремонт пусет (серьги-гвоздики)',
        silverPrice: '400 руб.',
        goldenPrice: '400 руб.',
      },
      {
        name: 'Починка сережек сложной формы',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
      {
        name: 'Нарезка резьбы на пусетах (серьги-гвоздики)',
        silverPrice: '600 руб.',
        goldenPrice: '600 руб.',
      },
      {
        name: 'Изготовление заднего элемента застежки серьги (англ. замок; без учета металла)',
        silverPrice: '1500 руб.',
        goldenPrice: '1500 руб.',
      },
    ],
  },
  {
    title: 'Ремонт подвесок/кулона',
    data: [
      {
        name: 'Ремонт главного ушка (1 пайка)',
        silverPrice: '350 руб.',
        goldenPrice: '350 руб.',
      },
      {
        name: 'Восстановление изношенного кольца у подвески (1 пайка)',
        silverPrice: 'от 350 руб.',
        goldenPrice: 'от 350 руб.',
      },
      {
        name: 'Ремонт соединительных элементов кулона (1 пайка)',
        silverPrice: '300 руб.',
        goldenPrice: '300 руб.',
      },
      {
        name: 'Ремонт перетертого кольца на кулоне',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Правка формы кулона и подвески',
        silverPrice: '200 руб.',
        goldenPrice: '200 руб.',
      },
      {
        name: 'Ремонт погнутого кулона/подвески',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
    ],
  },
  {
    title: 'Ремонт крестиков',
    data: [
      {
        name: 'Правка ушка',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Правка формы',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Восстановление перетертого кольца крестика (1 пайка)',
        silverPrice: 'от 350 руб.',
        goldenPrice: 'от 350 руб.',
      },
      {
        name: 'Восстановить распятье',
        silverPrice: 'от 1000 руб.',
        goldenPrice: 'от 1000 руб.',
      },
      {
        name: 'Очистить крестик',
        silverPrice: '200 руб.',
        goldenPrice: '200 руб.',
      },
      {
        name: 'Починить эмаль',
        silverPrice: 'Договорная.',
        goldenPrice: 'Договорная.',
      },
      {
        name: 'Восстановление холодной эмали на украшении до 1 см',
        silverPrice: 'Договорная.',
        goldenPrice: 'Договорная.',
      },
      {
        name: 'Восстановление холодной эмали на украшении свыше 1 см',
        silverPrice: 'Договорная.',
        goldenPrice: 'Договорная.',
      },
      {
        name: 'Восстановление горячей эмали на изделии до 1 см',
        silverPrice: 'Договорная.',
        goldenPrice: 'Договорная.',
      },
      {
        name: 'Восстановление горячей эмали на украшении свыше 1 см',
        silverPrice: 'Договорная.',
        goldenPrice: 'Договорная.',
      },
    ],
  },
  {
    title: 'Стоимость вставки камней',
    data: [
      {
        name: 'Закрепка фианита - 1-3 мм',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Закрепка фианита – 4-6 мм',
        silverPrice: 'от 300 руб.',
        goldenPrice: 'от 300 руб.',
      },
      {
        name: 'Закрепка полудрагоценных камней',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 400 руб.',
      },
      {
        name: 'Закрепка драгоценного камня до 3 мм',
        silverPrice: 'от 500 руб.',
        goldenPrice: 'от 500 руб.',
      },
      {
        name: 'Закрепка изумруда',
        silverPrice: 'от 1000 руб.',
        goldenPrice: 'от 1000 руб.',
      },
      {
        name: 'Вклейка камня',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
      {
        name: 'Вклейка жемчуга',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
      {
        name: 'Выкрепка камня',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
    ],
  },
  {
    title: 'Ремонт и перетяжка бус. Ремонт и замена гайтанов',
    data: [
      {
        name: 'Ремонт обычных бус с заменой нити',
        silverPrice: 'от 1000 руб.',
        goldenPrice: 'от 1000 руб.',
      },
      {
        name: 'Перебрать бусы (с узелками на нити)',
        silverPrice: 'от 2000 руб.',
        goldenPrice: 'от 2000 руб.',
      },
      {
        name: 'Установка железного тросика вместо нити',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
      {
        name: 'Замена гайтана с коробкой (+ стоимость гайтана)',
        silverPrice: '900 руб.',
        goldenPrice: '900 руб.',
      },
      {
        name: 'Замена гайтана со штифтовкой (+ стоимость гайтана)',
        silverPrice: '800 руб.',
        goldenPrice: '800 руб.',
      },
      {
        name: 'Замена гайтана вклейкой (+ стоимость гайтана)',
        silverPrice: 'от 400',
        goldenPrice: 'от 400',
      },
    ],
  },
  {
    title: 'Гравировка на изделии',
    data: [
      {
        name: 'Фрезерная или Лазерная (в среднем 2-3 слова)',
        silverPrice: '1500 руб.',
        goldenPrice: '1500 руб.',
      },
    ],
  },
  {
    title: 'Сварка Ювелирных изделий и очков лазером',
    data: [
      {
        name: 'Стоимость услуги',
        silverPrice: 'Договорная',
        goldenPrice: 'Договорная',
      },
      {
        name: 'Полировка серебряных изделий',
        silverPrice: 'от 200 руб.',
        goldenPrice: 'от 200 руб.',
      },
      {
        name: 'Полировка сложных украшений',
        silverPrice: 'от 400 руб.',
        goldenPrice: 'от 400 руб.',
      },
      {
        name: 'Чистка изделия в ультразвуковой ванне',
        silverPrice: 'от 100 руб.',
        goldenPrice: 'от 100 руб.',
      },
      {
        name: 'Чистка изделий вручную специальными средствами',
        silverPrice: '500 руб.',
        goldenPrice: '500 руб.',
      },
    ],
  },
  {
    title: 'Ремонт бус',
    data: [
      {
        name: 'Ремонт обычных бус с заменой нити',
        silverPrice: '400 руб.',
        goldenPrice: '400 руб.',
      },
      {
        name: 'Перебрать бусы (с узелками на нити)',
        silverPrice: 'от 800 руб.',
        goldenPrice: 'от 800 руб.',
      },
      {
        name: 'Установка лески за место нити',
        silverPrice: '400 руб.',
        goldenPrice: '400 руб.',
      },
      {
        name: 'Установка железного тросика за место нити',
        silverPrice: '500 руб.',
        goldenPrice: '500 руб.',
      },
    ],
  },
];
