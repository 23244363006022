export const mapLocation = [43.975, 56.325];

export const mapStoreMarkers = [
  {
    text: 'Долгополова, 18',
    coordinates: [43.94694, 56.319554],
    link: '/about/dolgopolova',
  },
  {
    text: 'Родионова, 17',
    coordinates: [44.054927, 56.319025],
    link: '/about/rodionova',
  },
  {
    text: 'Гагарина, 66',
    coordinates: [43.981606, 56.282755],
    link: '/about/gagarina',
  },
  {
    text: 'Юбилейный, 6',
    coordinates: [43.86549, 56.345848],
    link: '/about/jubileyniy',
  },
];

export const mapJewsMarkers = [
  {
    text: 'Долгополова, 18',
    coordinates: [43.94694, 56.319554],
    link: '/about/dolgopolova',
  },
  {
    text: 'Родионова, 17',
    coordinates: [44.054927, 56.319025],
    link: '/about/rodionova',
  },
  {
    text: 'Юбилейный, 6',
    coordinates: [43.86549, 56.345848],
    link: '/about/jubileyniy',
  },
];
