import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import $api from '../../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { hashPhoneNumber } from 'shared/lib/hashPhoneNumber';
import { useLoginModal } from 'entities/Modal/ui/components/LoginModal/lib/store';

function useGetLoginCode() {
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const setActiveLoginModal = useLoginModal((state) => state.setActiveModal);
  const navigate = useNavigate();
  const location = useLocation();

  async function getLoginCode(data, type, redirectPath) {
    setLoginLoading(true);
    // Чтобы можно было изменять номер при получении кода
    localStorage.setItem('phone_number', data.phone_number);

    try {
      const response = await $api.post(urls.enterPhone, {
        phone_number: data.phone_number,
        google_metric_id: hashPhoneNumber(data.phone_number),
        type_of_auth: type,
      });

      if (response.status !== 200) {
        setLoginError('Ошибка при входе в личный кабинет');
      }

      // Для того, чтобы вернуть пользователя назад на страницу,
      // с которой он начал вход
      if (
        location.pathname !== '/login' &&
        location.pathname !== '/login/sms'
      ) {
        localStorage.setItem('redirectPath', location.pathname);
      }

      setActiveLoginModal(false);
      navigate(redirectPath);
    } catch (error) {
      console.log(error);

      if (error.response?.data.detail === 'Код уже отправлен') {
        navigate(redirectPath);
        setLoginError('Код уже отправлен');
      } else {
        localStorage.removeItem('phone_number');
        setLoginError(error.response?.data?.message);
      }
    } finally {
      setLoginLoading(false);
    }
  }

  return { loginError, loginLoading, getLoginCode };
}

export { useGetLoginCode };
