import React from 'react';
import styles from './JWorksTeam.module.scss';

import { team } from '../model/team-data';

function JWorksTeam() {
  return (
    <div className={styles.container}>
      <h2>Наша команда</h2>
      <div className={styles.team}>
        {team.map((team) => (
          <div
            className={
              team.position === 'left'
                ? styles.memberTeamLeft
                : styles.memberTeamRight
            }
            key={team.id}
          >
            <div className={styles.imgContainer}>
              <img
                className={styles.memberPhoto}
                src={`./img/jewerly-brands/jewerly-team/${team.img}`}
                alt={team.name}
                loading="lazy"
              />
            </div>
            <div className={styles.info}>
              <h3>{team.name}</h3>
              <div className={styles.text}>
                <p>{team.experience}</p>
                <p>{team.history}</p>
                <p>{team.education}</p>
                <p>{team.slogan}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { JWorksTeam };
