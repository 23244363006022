import { useState } from 'react';
import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useMessageModal } from 'entities/Modal/ui/components/MessageModal/lib/store';

export default function useJewelryRepairModal() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const setActiveMessage = useMessageModal((state) => state.setActiveModal);

  const onSubmitJewRepair = async (data, first_name, phone_number) => {
    setLoading(true);

    const userData = {
      first_name: data.first_name || first_name,
      phone_number: data.phone_number || phone_number,
    };

    try {
      await $api.post(urls.jewelryRepair, userData);

      setLoading(false);
      setActiveMessage(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, onSubmitJewRepair };
}
