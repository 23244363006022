import React, { useEffect, useState } from 'react';
import styles from './MobileMenu.module.scss';
import { NavLink, useLocation } from 'react-router-dom';

import { useUser } from 'store/user';
import { useCart } from 'store/cart';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

import { GoHomeFill } from 'react-icons/go';
import { BsGridFill } from 'react-icons/bs';
import { GoHeartFill } from 'react-icons/go';
import { BsBagCheckFill } from 'react-icons/bs';
import { BsBagFill } from 'react-icons/bs';
import { GoPersonFill } from 'react-icons/go';
import { scrollTopLink } from 'shared/lib/scrollTopLink';

function MobileMenu() {
  const isAuth = useUser((state) => state.isAuth);
  const items = useCart((state) => state.items);
  const [isMobile, setIsMobile] = useState(false);

  const windowSize = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (windowSize < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  if (isMobile) {
    return (
      <div className={styles.mobileMenu}>
        <NavLink
          to="/"
          onClick={() => scrollTopLink('/', location.pathname)}
          className={({ isActive }) =>
            isActive ? styles.iconContainerActive : styles.iconContainer
          }
        >
          <GoHomeFill className={styles.icon} size={24} />
          <p className={styles.iconTitle}>Главная</p>
        </NavLink>
        <NavLink
          to="catalog"
          onClick={() => scrollTopLink('/catalog', location.pathname)}
          className={({ isActive }) =>
            isActive ? styles.iconContainerActive : styles.iconContainer
          }
        >
          <BsGridFill className={styles.icon} size={24} />
          <p className={styles.iconTitle}>Каталог</p>
        </NavLink>
        <NavLink
          to="favorite"
          onClick={() => scrollTopLink('/favotite', location.pathname)}
          className={({ isActive }) =>
            isActive ? styles.iconContainerActive : styles.iconContainer
          }
        >
          <GoHeartFill className={styles.icon} size={24} />
          <p className={styles.iconTitle}>Избранное</p>
        </NavLink>
        <NavLink
          to="cart"
          onClick={() => scrollTopLink('/cart', location.pathname)}
          className={({ isActive }) =>
            isActive ? styles.iconContainerActive : styles.iconContainer
          }
        >
          {items.length ? (
            <BsBagCheckFill className={styles.icon} size={24} />
          ) : (
            <BsBagFill className={styles.icon} size={24} />
          )}
          <p className={styles.iconTitle}>Корзина</p>
        </NavLink>
        <NavLink
          to="profile/info"
          onClick={() => scrollTopLink('/profile/info', location.pathname)}
          className={({ isActive }) =>
            isActive ? styles.iconContainerActive : styles.iconContainer
          }
        >
          <GoPersonFill className={styles.icon} size={24} />
          <p className={styles.iconTitle}>{isAuth ? 'Профиль' : 'Войти'}</p>
        </NavLink>
      </div>
    );
  }
}

export { MobileMenu };
