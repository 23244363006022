import React, { useEffect, useState } from 'react';
import styles from '../../Forms.module.scss';

import { urls } from 'api/Config/usrlsConfig';
import { useUser } from 'store/user';
import { AuthTimer } from 'entities/AuthTimer';
import useResendSMSCode from 'api/Auth/useResendSMSCode';

export default function ResendSignUpSMSCode({ setError }) {
  const phone_number = useUser((state) => state.user.phone);
  const [isShown, setIsShown] = useState(false);
  const { error, resendCode } = useResendSMSCode();

  const onResendSignUpSMSCode = () => {
    setIsShown(false);

    resendCode(urls.regPhone, phone_number);
  };

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  if (isShown) {
    return (
      <div className={styles.tip} onClick={onResendSignUpSMSCode}>
        <span className={styles.resendCode}>Отправить код повторно</span>
      </div>
    );
  } else {
    return (
      <div className={styles.tip}>
        <span className={styles.resendCodeВDisabled}>
          Повторная отправка кода будет доступна через{' '}
          <AuthTimer
            durationInSeconds={60}
            onTimerEnd={() => setIsShown(true)}
          />
        </span>
      </div>
    );
  }
}
