import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './HeaderLogo.module.scss';

import { scrollTopLink } from 'shared/lib/scrollTopLink';

function HeaderLogo() {
  const location = useLocation();
  return (
    <div className={styles.brand}>
      <div className={styles.container}>
        <Link
          to="/"
          className={styles.link}
          onClick={() => scrollTopLink('/', location.pathname)}
        >
          <img
            className={styles.logo}
            src="/img/logotype-img/logo.webp"
            alt="logo"
          />
          <img
            className={styles.sublogo}
            src="/img/logotype-img/sublogo.webp"
            alt="sub-ogo"
          />
        </Link>
      </div>
    </div>
  );
}

export { HeaderLogo };
