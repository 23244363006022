import React, { useState } from 'react';
import styles from '../../Forms.module.scss';
import { Link } from 'react-router-dom';

import { AuthTimer } from 'entities/AuthTimer';

export default function ResendSignUpCode() {
  const [isShown, setIsShown] = useState(false);

  // При вход по номеру телефона мы не будем отправлять повторный звонок
  // Просто делаем переход на вход по СМС, спустя 60 сек

  if (isShown) {
    return (
      // Делаем редирект на вход по СМС
      <div className={styles.tip}>
        <Link to="/register/sms" className={styles.resendCode}>
          Получить код по СМС
        </Link>
      </div>
    );
  } else {
    return (
      <div className={styles.tip}>
        <span className={styles.resendCodeВDisabled}>
          Повторная отправка кода будет доступна через{' '}
          <AuthTimer
            durationInSeconds={60}
            onTimerEnd={() => setIsShown(true)}
          />
        </span>
      </div>
    );
  }
}
