import React from 'react';
import { Link } from 'react-router-dom';
import styles from './WorkTypes.module.scss';

import { work } from '../model/work-data';

import { useJewMakingModalStore } from 'entities/Modal/ui/components/JewelryMakingModal/lib/store';

import { SecondaryButton } from 'shared/ui/SecondaryButton/';

function WorkTypes() {
  const setActive = useJewMakingModalStore((state) => state.setActiveModal);

  return (
    <>
      {work.map((work) => (
        <div className={styles.blockWork} key={work.id}>
          <h2
            className={
              work.position === 'right' ? styles.titleRight : styles.titleLeft
            }
          >
            {work.name}
          </h2>
          <div
            className={
              work.position === 'right'
                ? styles.containerWorkRight
                : styles.containerWorkLeft
            }
          >
            <div className={styles.workInfo}>
              <p className={work.position === 'right' ? '' : styles.textRight}>
                {work.history}
              </p>
              <div className={styles.button}>
                {work.type === 'making' ? (
                  <SecondaryButton
                    text="Получить консультацию по изготовлению"
                    onClick={() => {
                      setActive(true);
                    }}
                  />
                ) : (
                  <Link to="remont">
                    <SecondaryButton text="Создать заявку на ремонт" />
                  </Link>
                )}
              </div>
            </div>
            <img
              className={styles.image}
              src={`./img/jewerly-brands/${work.img}`}
              alt="Виды работ"
              loading="lazy"
            />
          </div>
        </div>
      ))}
    </>
  );
}

export { WorkTypes };
