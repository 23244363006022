import React from 'react';
import styles from './SmallProductItemSkelletons.module.scss';

const skelletons = [
  { name: 'skelleton0' },
  { name: 'skelleton1' },
  { name: 'skelleton2' },
  { name: 'skelleton3' },
  { name: 'skelleton4' },
  { name: 'skelleton5' },
  { name: 'skelleton6' },
  { name: 'skelleton7' },
];

function SmallProductItemSkelletons() {
  return (
    <div className={styles.container}>
      {skelletons.map((skelleton) => (
        <div className={styles.newItem} key={skelleton.name}></div>
      ))}
    </div>
  );
}

export { SmallProductItemSkelletons };
