import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { probStore } from '../../../../model/probStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function ProbFilter() {
  const { prob, setFilters } = useFilters((state) => ({
    prob: state.filters.prob || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (prob.includes(value)) {
      const updatedValues = prob
        .split(';')
        .filter((prob) => prob !== value)
        .join(';');
      setFilters('prob', updatedValues);
    } else {
      const updatedValues = prob ? `${prob};${value}` : value;
      setFilters('prob', updatedValues);
    }
  };

  return (
    <FilterDropdown title="Проба" isOpen={!!prob}>
      <div className={styles.contentContainer}>
        {probStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={prob.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { ProbFilter };
