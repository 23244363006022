import { useState } from 'react';
import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useMessageModal } from 'entities/Modal/ui/components/MessageModal/lib/store';
import { useJewMakingModalStore } from 'entities/Modal/ui/components/JewelryMakingModal/lib/store';

export default function useJewelryMakingModal() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const setItems = useJewMakingModalStore((state) => state.setItems);
  const setActiveMessage = useMessageModal((state) => state.setActiveModal);

  const onSubmitJewMaking = async (
    data,
    first_name,
    phone_number,
    trade_offer_id,
  ) => {
    setLoading(true);

    console.log(trade_offer_id);

    const userData = {
      first_name: data.first_name || first_name,
      phone_number: data.phone_number || phone_number,
    };

    if (trade_offer_id) {
      userData.text = `Артикул изделия: ${String(trade_offer_id)}`;
    }

    try {
      await $api.post(urls.jewelryMaking, userData);

      setItems(null);
      setError(null);
      setLoading(false);
      setActiveMessage(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, onSubmitJewMaking };
}
