import React from 'react';
import { Route, Routes } from 'react-router-dom';

import styles from './LkProfile.module.scss';

import { Navbar } from 'entities/LK/Navbar/index';
import { UserInfo } from 'entities/LK/UserInfo';
import { Orders } from 'entities/LK/Orders';

function LkProfile() {
  return (
    <div className={styles.profile}>
      <Navbar />
      <Routes>
        <Route path="info" element={<UserInfo />} />
        <Route path="orders" element={<Orders />} />
      </Routes>
    </div>
  );
}

export { LkProfile };
