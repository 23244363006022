import React from 'react';
import styles from './GetOrdersSkelleton.module.scss';

const skelletons = [
  { name: 'skelleton0' },
  { name: 'skelleton1' },
  { name: 'skelleton2' },
];

function GetOrdersSkelleton() {
  return (
    <div className={styles.getOrderSkelletons}>
      {skelletons.map((skelleton) => (
        <div className={styles.getOrderSkelleton} key={skelleton.name}></div>
      ))}
    </div>
  );
}

export { GetOrdersSkelleton };
