import React, { useState, useRef } from 'react';
import styles from './Dropdown.module.scss';

import { useOutsideClick } from 'shared/lib/useOutsideClick';
import { BsChevronDown } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';

const animation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.05,
    },
  },
  exit: {
    opacity: 0,
  },
};

function Dropdown({ title, children }) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Закрываю при нажатии вне блока
  useOutsideClick(dropdownRef, () => setOpenDropdown(false));

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div
        className={styles.titleContainer}
        onClick={() => setOpenDropdown((prevState) => !prevState)}
        role="button"
      >
        <p
          className={
            openDropdown ? styles.dropdownTitleActive : styles.dropdownTitle
          }
        >
          {title}
        </p>
        <BsChevronDown
          className={openDropdown ? styles.rowReverse : styles.row}
          size={20}
        />
      </div>
      <AnimatePresence>
        {openDropdown && (
          <motion.div
            className={styles.dropdownContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export { Dropdown };
