import React from 'react';
import styles from './RecievItem.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

const animation = {
  hidden: { height: 0, opacity: 0 },
  show: {
    height: 'auto',
    opacity: 1,
  },
  exit: {
    height: 0,
    opacity: 0,
  },
};

function RecievItem({ method, onClickMethod, currentMethod, children }) {
  const { title, text, key } = method;
  const isActive =
    (currentMethod !== undefined) & (currentMethod !== null)
      ? currentMethod.key === key
      : false;

  return (
    <div
      onClick={onClickMethod}
      className={
        isActive ? styles.recievContainerActive : styles.recievContainer
      }
    >
      {title && <h3 className={styles.title}>{title}</h3>}
      {text && <p className={styles.text}>{text}</p>}
      <AnimatePresence>
        {isActive && (
          <motion.div
            className={styles.recievContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            <div className={styles.childrenContainer}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export { RecievItem };
