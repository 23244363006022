import React from 'react';
import styles from './Layout.module.scss';

import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/Header';
import { Footer } from 'widgets/Footer';
import { MobileMenu } from 'entities/MobileMenu';
import { MobileNavbar } from 'widgets/Header/ui/MobileNavbar';

function Layout() {
  return (
    <>
      <Header />
      <main className={styles.container}>
        <Outlet />
      </main>
      <Footer />
      {/* Скрываем по условию */}
      <MobileNavbar />
      <MobileMenu />
    </>
  );
}

export { Layout };
