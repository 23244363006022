export const addresStore = [
  {
    name: 'ул. Родионова 17',
    value: 'г.Н.Новгород, ул.Родионова,д.17, пом.12',
  },
  {
    name: 'ул. Долгополова 18',
    value: 'г.Н.Новгород,ул.Долгополова,д.18/36',
  },
  {
    name: 'пр-т Гагарина 66',
    value: 'г.Н.Новгород, пр.Гагарина,д.66, пом.3',
  },
  {
    name: 'б-р Юбилейный 6',
    value: 'Нижний Новгород, Юбилейный бульвар, 6',
  },
];
