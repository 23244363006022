import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import { CategoryFilter } from './components/CategoryFilter';
import { SubcategoryFilter } from './components/SubcategoryFilter';
import { ProbFilter } from './components/ProbFilter';
import { VstavkiFilter } from './components/VstavkiFilter';
import { SizeFilter } from './components/SizeFilter';
import { ColorMetalFilter } from './components/ColorMetalFilter';
import { FullLightWeightFilter } from './components/FullLightWeightFilter';
import { TypeFilter } from './components/TypeFilter';
import { MaterialFilter } from './components/MaterialFilter';
import { ForwhomFilter } from './components/ForwhomFilter';
import { AdresFilter } from './components/AdresFilter';
import { WeightFilter } from './components/WeightFilter';
import { PriceFilter } from './components/PriceFilter';

import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { MainButton } from 'shared/ui/MainButton';
import { GoReply } from 'react-icons/go';
import { useFilters } from 'store/filters';
import { useCatalogPagination } from 'store/pagination';
import { useSort } from 'store/sort';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

function Filter() {
  const [mobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  const { showMobileFilter, setShowMobileFilter, filtersActive, resetFilters } =
    useFilters((state) => ({
      showMobileFilter: state.showMobileFilter,
      setShowMobileFilter: state.setShowMobileFilter,
      filtersActive: state.filtersActive,
      resetFilters: state.resetFilters,
    }));
  const setSortState = useSort((state) => state.setSort);
  const setPaginationState = useCatalogPagination(
    (state) => state.setPagination,
  );

  // Скрываю мобильный фильтр
  const handleHideMobileFilter = () => {
    setShowMobileFilter(false);
  };

  // Скидываем состояние фильтра
  const handleResetFilters = () => {
    resetFilters();
    setPaginationState(1);
    setSortState('-id');
  };

  // Запрещаю скрол для body, чтобы скролл внутри самого фильтра отрабатывал корректно
  useEffect(() => {
    if (showMobileFilter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [showMobileFilter]);

  // Скрываем/показываем значок мобильного фильтра
  useEffect(() => {
    if (windowSize <= 1023) {
      setMobile(true);
    } else {
      setMobile(false);
      setShowMobileFilter(false);
    }
  }, [windowSize]);

  return (
    <div className={styles.filterStink}>
      <div
        className={`${styles.filterContainer} ${
          showMobileFilter ? styles.active : ''
        }`}
      >
        {mobile && (
          <div className={styles.mobileFilterHead}>
            <GoReply onClick={handleHideMobileFilter} size={24} />
            <button
              className={styles.mobileResetButton}
              onClick={handleResetFilters}
              type="button"
            >
              Сбросить фильтры
            </button>
          </div>
        )}
        <div className={styles.formContainer}>
          <CategoryFilter />
          <SubcategoryFilter />
          <ProbFilter />
          <VstavkiFilter />
          <SizeFilter />
          <MaterialFilter />
          <ColorMetalFilter />
          <FullLightWeightFilter />
          <TypeFilter />
          <ForwhomFilter />
          <AdresFilter />
          <WeightFilter />
          <PriceFilter />
          {!mobile && filtersActive && (
            <SecondaryButton
              text="Сбросить все фильтры"
              onClick={handleResetFilters}
              type="button"
            />
          )}
          {mobile && (
            <MainButton
              text="Применить фильтры"
              onClick={handleHideMobileFilter}
              disabled={!filtersActive}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { Filter };
