import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useOrder } from 'store/order';

import { useMessageModal } from 'entities/Modal/ui/components/MessageModal/lib/store';
import { useAddOrderError } from 'entities/Modal/ui/components/AddOrderError/lib/store';

// Хук по отправке заказа
function useAddOrder() {
  const resetOrder = useOrder((state) => state.resetOrder);

  const setActiveMessageModal = useMessageModal(
    (state) => state.setActiveModal,
  );

  const setActiveAddOrderError = useAddOrderError(
    (state) => state.setActiveModal,
  );

  const navigate = useNavigate();

  const [addOrderLoading, setAddOrderLoading] = useState(false);
  const [addOrderError, setAddOrderError] = useState(null);

  async function addOrder(
    address,
    delivery_type,
    payment_type,
    express_delivery,
    location_code,
    pvz,
  ) {
    setAddOrderLoading(true);

    const dataConfig = {
      payment_type: payment_type,
      delivery_type: delivery_type,
      express_delivery: express_delivery,
      address: address,
    };

    if (location_code) {
      dataConfig.to_location_code = location_code.toString();
    }

    if (pvz) {
      dataConfig.pvz = pvz;
    }

    try {
      const response = await $api.post(urls.postOrder, dataConfig);

      if (payment_type === 'card') {
        window.location.replace(response.data.payment_url);
      }

      resetOrder();
      navigate('/profile/orders');
      setActiveMessageModal(true);
    } catch (error) {
      setActiveAddOrderError(true);
      setAddOrderError(error.message);
    } finally {
      setAddOrderLoading(false);
    }
  }

  return { addOrderLoading, addOrderError, addOrder };
}

export { useAddOrder };
