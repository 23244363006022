import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { forwhomStore } from '../../../../model/forwhomStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function ForwhomFilter() {
  const { forwhom, setFilters } = useFilters((state) => ({
    forwhom: state.filters.forwhom || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (value === forwhom) {
      setFilters('forwhom', '');
    } else {
      setFilters('forwhom', value);
    }
  };

  return (
    <FilterDropdown title="Для кого" isOpen={!!forwhom}>
      <div className={styles.contentContainer}>
        {forwhomStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={forwhom.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { ForwhomFilter };
