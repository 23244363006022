import React from 'react';
import styles from './CloseIcon.module.scss';

function CloseIcon({ onClick, size = 24 }) {
  return (
    <svg
      className={styles.closeIcon}
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 29L29 1M29 29L1 1"
        stroke="#dcdcdc"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { CloseIcon };
