export function transliterateCategoryParam(param) {
  const transliterationCategoryParam = {
    sergi: 'Серьги',
    tsepi: 'Цепи',
    braslety: 'Браслеты',
    koltsa: 'Кольца',
    podveski: 'Подвески',
    broshi: 'Броши',
    pechatki: 'Печатки',
    kole: 'Колье',
    chasy: 'Часы',
    souvenir: 'Сувениры',
    gaitani: 'Гайтаны',
    slitki: 'Слитки',
    zaponki: 'Запонки',
    pirsing: 'Пирсинг',
    // 'zazhimy-dlya-galstuka': 'Зажимы для галстука',
    // Можно добавить еще соответствий
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterationCategoryParam.hasOwnProperty(param)) {
    return transliterationCategoryParam[param];
  } else {
    return undefined;
  }
}
