import React from 'react';
import styles from './CarouselItems.module.scss';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import './carousel-item-swiper.scss';

import { useFilters } from 'store/filters';
import { useSort } from 'store/sort';
import { useCatalogPagination } from 'store/pagination';

import { Card } from 'entities/Card';
import { SmallProductItemSkelletons } from 'shared/ui/Skelletons/SmallProductItem';
import { GoChevronRight } from 'react-icons/go';

function CarouselItems({ items, title, link, loading }) {
  const resetFilters = useFilters((state) => state.resetFilters);
  const setSortState = useSort((state) => state.setSort);
  const setPaginationState = useCatalogPagination(
    (state) => state.setPagination,
  );

  // Скидываем состояния на дефолтные
  const handleResetFilters = () => {
    resetFilters();
    setPaginationState(1);
    setSortState('-id');
  };

  if (!items) {
    return null;
  }

  return (
    <div className={styles.carouselItemsBlock}>
      <div className={styles.carouselItemsHeader}>
        <h2>{title}</h2>
        {link && (
          <Link
            to={link}
            className={styles.linkContainer}
            onClick={handleResetFilters}
          >
            <span>Всё</span>
            <GoChevronRight size={22} />
          </Link>
        )}
      </div>
      {!loading && items !== undefined ? (
        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          slidesPerGroup={2}
          breakpoints={{
            768: {
              spaceBetween: 24,
            },
          }}
          className="carousel-item-swiper"
        >
          {items.map((item) => (
            <SwiperSlide key={item.id}>
              <Card
                key={item.id}
                id={item.id}
                title={item.title}
                price={item.price}
                discount_price={item.discount_price}
                discount={item.discount}
                image={item.image}
                slug_url={item.slug_url}
                is_active={item.is_active}
                quantity={item.quantity}
                isInCart={item.is_in_cart}
                isInFavorite={item.is_in_favorites}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <SmallProductItemSkelletons />
      )}
    </div>
  );
}

export { CarouselItems };
