export function transliterateVstavkiParam(param) {
  const transliterateVstavkiParam = {
    fianity: 'Фианит',
    's-brilliantom': 'Бриллиант',
    korund: 'Корунд',
    yantar: 'Янтарь',
    emal: 'Эмаль',
    rubin: 'Рубин',
    topaz: 'Топаз',
    's-sapfirom': 'Сапфир',
    's-ametistom': 'Аметист',
    izumrud: 'Изумруд',
    citrin: 'Цитрин',
    granat: 'Гранат',
    's-zhemchugom': 'Жемчуг',
    malahit: 'Малахит',
    dragocennye: 'драг',
    podelochnye: 'ювел',
    poludragocennye: 'полудраг',
    sinteticheskie: 'синт',
    'bez-kamnej': 'no',

    // Фильтр может принять больше вариантов параметров, но
    // сеошники пока не дали свои сеошные названия
  };

  // Проверяем, есть ли соответствующее значение в таблице
  if (transliterateVstavkiParam.hasOwnProperty(param)) {
    return transliterateVstavkiParam[param];
  } else {
    return undefined;
  }
}
