import React from 'react';
import styles from './PaymentPage.module.scss';

function PaymentPage() {
  return (
    <>
      <h1 className={styles.paymentMainTitle}>Оплата</h1>
      <section className={styles.paymentContainer}>
        <h2 className={styles.paymentSubtitle}>
          Описание процесса передачи данных
        </h2>
        <div className={styles.paymentBox}>
          <h2 className={styles.paymentTitle}>
            Оплата через платежный шлюз Тинькофф Кассы.
          </h2>
          <p className={styles.paymentParagraph}>
            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены
            на платежный шлюз Тинькофф Кассы. Соединение с платежным шлюзом и
            передача информации осуществляется в защищенном режиме с
            использованием протокола шифрование SSL.
          </p>
        </div>
        <div className={styles.paymentBox}>
          <h2 className={styles.paymentTitle}>
            СМС- код при оплате через Интернет-эквайринг Тинькофф Кассы.
          </h2>
          <p className={styles.paymentParagraph}>
            Интернет-эквайринг Тинькофф Кассы использует технологию 3D-Secure. С
            технологией 3D-Secure после ввода данных карты потребуется
            дополнительно ввести код из СМС.
          </p>
        </div>
        <div className={styles.paymentBboxx}>
          <h2 className={styles.paymentTitle}>
            Конфиденциальность платежных и личных данных.
          </h2>
          <p className={styles.paymentParagraph}>
            Конфиденциальность сообщаемой персональной информации обеспечивается
            Тинькофф Кассы. Введенная информация не будет предоставлена третьим
            лицам за исключением случаев, предусмотренных законодательством РФ.
          </p>
          <p className={styles.paymentParagraph}>
            Проведение платежей по банковским картам осуществляется в строгом
            соответствии с требованиями платежных систем МИР, Visa, MasterCard и
            UnionPay — эмитент российский банк.
          </p>
        </div>

        <h2 className={styles.paymentSubtitle}>Способы оплаты товара</h2>
        <div className={styles.paymentBox}>
          <h2 className={styles.paymentTitle}>Наличный расчет</h2>
          <p className={styles.paymentParagraph}>
            Наличными курьеру или оператору
          </p>
          <p className={styles.paymentParagraph}>
            В случае, если заказ доставляется Почтой России, оплата
            осуществляется оператору в руки перед ее выдачей ПОКУПАТЕЛЮ. На
            оплаченные наличными денежные средства ПОКУПАТЕЛЮ выдается кассовый
            чек, или иной документ, подтверждающий оплату ТОВАРА.
          </p>
          <p className={styles.paymentParagraph}>
            Наличными в банке по квитанции
          </p>
          <p className={styles.paymentParagraph}>
            На расчетный счет ПРОДАВЦА по реквизитам, указанным на сайте в
            разделе «О компании».
          </p>
        </div>
        <div className={styles.paymentBox}>
          <h2 className={styles.paymentTitle}>Безналичный расчет</h2>
          <p className={styles.paymentParagraph}>Банковской картой</p>
          <p className={styles.paymentParagraph}>
            В случае, если заказ доставляется Почтой России, оплата
            осуществляется оператору в руки перед ее выдачей ПОКУПАТЕЛЮ. На
            оплаченные наличными денежные средства ПОКУПАТЕЛЮ выдается кассовый
            чек, или иной документ, подтверждающий оплату ТОВАРА.
          </p>
          <p className={styles.paymentParagraph}>
            Наличными в банке по квитанции
          </p>
          <p className={styles.paymentParagraph}>
            Для выбора оплаты товара с помощью банковской карты в Корзине при
            оформлении заказа необходимо нажать кнопку «Оплата банковской
            картой». Оплата происходит через ООО «РОБОКАССА» с использованием
            банковских карт следующих платежных систем:
          </p>
          <ul className={styles.paymentList}>
            <li className={styles.paymentListItem}>МИР</li>
            <li className={styles.paymentListItem}>VISA International</li>
            <li className={styles.paymentListItem}>Mastercard Worldwide</li>
            <li className={styles.paymentListItem}>Яндекс –касса</li>
          </ul>
          <p className={styles.paymentParagraphh}>
            Пожалуйста, проверяйте свои данные перед оплатой.
          </p>
        </div>
      </section>
    </>
  );
}

export { PaymentPage };
