import { create } from 'zustand';
import $api from '../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

let favorites = (set) => ({
  favorites: [],
  favoritesCount: 0,
  loading: false,
  error: null,

  getFavorite: async () => {
    set({ loading: true });

    try {
      const response = await $api.get(urls.getFavorites);

      set({
        favorites: [...response.data.results],
        favoritesCount: response.data.results.length,
      });
    } catch (error) {
      set({
        favorites: [],
        favoritesCount: 0,
      });
    } finally {
      set({ loading: false });
    }
  },
  addToFavorite: async (id) => {
    set({ loading: true });

    try {
      await $api.post(urls.addToFavorites, {
        trade_offer: id,
      });
    } catch (error) {
      console.log('ошибка при добавлении товара');
    } finally {
      set({ loading: false });
    }
  },
  removeFromFavorite: async (id) => {
    set({ loading: true });

    try {
      const response = await $api.post(urls.removeFromFavorites, {
        trade_offer: id,
      });

      set({
        favorites: [...response.data.favorite_items],
        favoritesCount: response.data.favorite_items.length,
      });
    } catch (error) {
      console.log('ошибка при удалении товара');
    } finally {
      set({ loading: false });
    }
  },
});

export const useFavorite = create(favorites);
