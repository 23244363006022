import React from 'react';
import { useForm } from 'react-hook-form';
import styles from '../Forms.module.scss';

import { useRegister } from 'api/Auth/Register/useRegister';
import validatePassCode from 'shared/lib/validatePassCode';

import ResendSignUpCode from '../ResendCode/ByCall/ResendSignUpCode';
import ResendSignUpSMSCode from '../ResendCode/BySMS/ResendSignUpSMSCode';
import { MainButton } from 'shared/ui/MainButton';

export default function RegForm({ type }) {
  const { registerError, setRegisterError, registerLoading, onRegister } =
    useRegister();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    await onRegister(data);
  };

  return (
    <div className={styles.buyer}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputContainer}>
            <label>
              <input
                className={styles.input}
                type="tel"
                autoFocus
                placeholder="Введите код"
                {...register('code', {
                  required: 'Не может быть пустым',
                  minLength: {
                    value: 4,
                    message: 'Введите 4-х значный код',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Введите 4-х значный код',
                  },
                  validate: (value) => validatePassCode(value.trim()),
                })}
              />
            </label>
            {registerError !== null ? (
              <div className={styles.error}>
                <p>{registerError}</p>
              </div>
            ) : (
              <div className={styles.error}>
                {errors?.code && (
                  <p>{errors?.code?.message || 'Кажется, здесь ошибка'}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <MainButton type="submit" text="Отправить" loading={registerLoading} />
        {type === 'sms' ? (
          <ResendSignUpSMSCode setError={setRegisterError} />
        ) : (
          <ResendSignUpCode />
        )}
      </form>
    </div>
  );
}
