// Скрыл те, которых нет в наличии

export const sergi = [
  {
    value: 'Длинные',
    slug: 'dlinnye',
  },
  // {
  //     value: 'Зажимы',
  //     slug: 'zazhimy',
  // },
  {
    value: 'Каффы',
    slug: 'kaffy',
  },
  {
    value: 'Конго',
    slug: 'kongo',
  },
  {
    value: 'Одиночные',
    slug: 'odinochnye',
  },
  // {
  //     value: 'Остальные Серьги',
  //     slug: 'ostalnye-sergi',
  // },
  {
    value: 'Пусеты',
    slug: 'pusety',
  },
  {
    value: 'Цепочки',
    slug: 'tsepochki',
  },
];

export const braslety = [
  // {
  //     value: 'Анклеты (браслет на ногу)',
  //     slug: 'anklety-braslet-na-nogu',
  // },
  {
    value: 'Браслеты цепевязальные',
    slug: 'braslety-tsepevyazalnye',
  },
  {
    value: 'Декоративные',
    slug: 'dekorativnye',
  },
  {
    value: 'Для часов',
    slug: 'dlya-chasov',
  },
  {
    value: 'Жесткие (обручи)',
    slug: 'zhestkie-obruchi',
  },
];

export const koltsa = [
  {
    value: 'Кольца спаси и сохрани',
    slug: 'koltsa-spasi-i-sohrani',
  },
  // {
  //     value: 'Наборные',
  //     slug: 'nabornye',
  // },
  // {
  //     value: 'На два пальца',
  //     slug: 'na-dva-paltsa',
  // },
  // {
  //     value: 'На две фаланги',
  //     slug: 'na-dve-falangi',
  // },
  {
    value: 'Обручальные',
    slug: 'obruchalnye',
  },
  // {
  //     value: 'Остальные Кольца',
  //     slug: 'ostalnye-koltsa',
  // },
  {
    value: 'Помолвочные',
    slug: 'pomolvochnye',
  },
  // {
  //     value: 'Трансформеры',
  //     slug: 'transformery',
  // },
];

export const podveski = [
  {
    value: 'Буквы',
    slug: 'bukvy',
  },
  {
    value: 'Декоративные подвески',
    slug: 'dekorativnye-podveski',
  },
  {
    value: 'Знаки зодиака',
    slug: 'znaki-zodiaka',
  },
  {
    value: 'Иконки нательные и ладанки',
    slug: 'ikonki-natelnye-i-ladanki',
  },
  {
    value: 'Кресты',
    slug: 'kresty',
  },
  {
    value: 'Мусульманские подвески',
    slug: 'musulmanskie-podveski',
  },
  // {
  //     value: 'Остальные Подвески',
  //     slug: 'ostalnye-podveski',
  // },
  {
    value: 'Сердце',
    slug: 'serdtse',
  },
  {
    value: 'Шармы',
    slug: 'sharmy',
  },
];
