import React, { useEffect, useState } from 'react';
import styles from '../../../Filter.module.scss';

import { useFilters } from 'store/filters';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { MainButton } from 'shared/ui/MainButton';
import { SecondaryButton } from 'shared/ui/SecondaryButton';

function WeightFilter() {
  const { minWeight, maxWeight, setFilters } = useFilters((state) => ({
    minWeight: state.filters.minWeight,
    maxWeight: state.filters.maxWeight,
    setFilters: state.setFilters,
  }));
  const [minWeigthState, setMinWeightState] = useState(minWeight);
  const [maxWeigthState, setMaxWeightState] = useState(maxWeight);

  const handleSubmitWeight = () => {
    setFilters('minWeight', minWeigthState);
    setFilters('maxWeight', maxWeigthState);
  };

  const handleResetWeight = () => {
    setFilters('minWeight', '');
    setFilters('maxWeight', '');
    setMinWeightState('');
    setMaxWeightState('');
  };

  // Обращаемся к стору, чтобы всегда иметь акруальное состояние
  useEffect(() => {
    if (minWeight || maxWeight) {
      setFilters('minWeightData', minWeight);
      setFilters('maxWeightData', maxWeight);
      setMinWeightState(minWeight);
      setMaxWeightState(maxWeight);
    } else {
      setMinWeightState('');
      setMaxWeightState('');
    }
  }, [minWeight, maxWeight]);

  //Управляю состоянием инпутов
  useEffect(() => {
    if (minWeigthState < 0) {
      setMinWeightState('');
    }
  }, [minWeigthState, maxWeigthState]);

  return (
    <FilterDropdown title="Вес изделия" isOpen={minWeight || maxWeight}>
      <div className={styles.contentContainer}>
        <label className={styles.inputContainer}>
          <span className={styles.labelText}>От</span>
          <input
            className={styles.input}
            type="number"
            value={minWeigthState}
            onChange={(event) => setMinWeightState(event.target.value)}
          />
          <span className={styles.labelText}>гр.</span>
        </label>
        <label className={styles.inputContainer}>
          <span className={styles.labelText}>До</span>
          <input
            className={styles.input}
            type="number"
            value={maxWeigthState}
            onChange={(event) => setMaxWeightState(event.target.value)}
          />
          <span className={styles.labelText}>гр.</span>
        </label>

        <MainButton
          text="Применить"
          onClick={handleSubmitWeight}
          disabled={!minWeigthState && !maxWeigthState}
        />

        {minWeight || maxWeight ? (
          <SecondaryButton text="Сбросить фильтр" onClick={handleResetWeight} />
        ) : null}
      </div>
    </FilterDropdown>
  );
}

export { WeightFilter };
