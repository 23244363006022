import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { hashPhoneNumber } from 'shared/lib/hashPhoneNumber';
import { useUser } from 'store/user';

function useGetRegCode() {
  const [regCodeError, setRegCodeError] = useState(null);
  const [regCodeLoading, setRegCodeLoading] = useState(false);

  const regUser = useUser((state) => state.regUser);

  const navigate = useNavigate();

  async function getRegCode(data, type, redirectPath) {
    setRegCodeLoading(true);
    localStorage.setItem('phone_number', data.phone_number);

    const userData = {
      phone_number: data.phone_number,
      first_name: data.first_name,
      last_name: data.last_name,
    };

    try {
      const response = await $api.post(urls.regPhone, {
        phone_number: data.phone_number,
        google_metric_id: hashPhoneNumber(data.phone_number),
        type_of_auth: type,
      });

      if (response.status !== 200) {
        setRegCodeError('Ошибка при регистрации');
      }

      regUser(userData);
      navigate(redirectPath);
    } catch (error) {
      console.log('Ошибка при регистрации пользователя', error);
      setRegCodeError(error.response.data.message);
      localStorage.removeItem('phone_number');
    } finally {
      setRegCodeLoading(false);
    }
  }
  return { regCodeError, regCodeLoading, getRegCode };
}

export { useGetRegCode };
