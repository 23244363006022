export const itemInfo = [
  {
    id: 1,
    title: 'Доставка',
    description:
      'Доставка по всей России, Армении, Казахстану, Кыргызстану и Беларуси. Пользуемся “Почтой России” и курьерской доставкой СДЭК. От момента покупки до получения заказа проходит ориентировочно от 3 до 10 дней. Все украшения мы отправляем в красивой брендовой упаковке. Во время доставки изделия страхуются, чтобы мы были уверены в том, что вы получите посылку в целости и сохранности. ',
    isOpen: true,
  },
  {
    id: 2,
    title: 'Примерка перед покупкой',
    description:
      'Наши менеджеры по запросу сделают для вас дополнительные фото в разных ракурсах и на себе для того, чтобы вы смогли принять решение о покупке. Если вы находитесь в Нижнем Новгороде для самостоятельной примерки вы можете обратиться в любой удобный для вас филиал.',
  },
  {
    id: 3,
    title: 'Рассрочка',
    description:
      'В Ювелирном Доме Grand Gold вы можете приобрести украшение с помощью беспроцентной рассрочки от магазина или от нашего банка-партнера Тинькофф. Рассрочка доступна сроком до трех месяцев. При выборе рассрочки от магазина изделие остается на филиале до полной оплаты. В случае рассрочки Тинькофф - изделие переходит в руки еще до первоначального взноса.',
  },
  {
    id: 4,
    title: 'Trade-in',
    description:
      'В Grand Gold вы можете воспользоваться услугой ювелирного Trade-In для покупки желаемого украшения, заменив старое на новое (только в Нижнем Новгороде).',
  },
];
