import React, { useState } from 'react';
import styles from './Card.module.scss';
import { Link } from 'react-router-dom';

import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';
import sendMetrik from 'shared/lib/sendYAMetrik';

import { useUser } from 'store/user';
import { useLoginModal } from 'entities/Modal/ui/components/LoginModal/lib/store';

import { GoHeart } from 'react-icons/go';
import { GoHeartFill } from 'react-icons/go';
import { MainButton } from 'shared/ui/MainButton';
import { WarningBadge } from 'shared/ui/WarningBadge';
import { useCart } from 'store/cart';
import { useFavorite } from 'store/favorite';

function Card({
  id,
  title,
  price,
  discount_price,
  discount,
  image,
  slug_url,
  quantity,
  is_active,
  isInCart,
  isInFavorite,
}) {
  // Добавляем товар в корзину
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(image);
  const [isItemInCart, setIsItemInCart] = useState(() => isInCart || false);
  const inCart = isItemInCart || isInCart;
  const addToCart = useCart((state) => state.addToCart);
  // Добавление в избранное
  const isAuth = useUser((state) => state.isAuth);
  const [inFavorite, setInFavorite] = useState(() => isInFavorite);
  const setActiveLoginModal = useLoginModal((state) => state.setActiveModal);
  const { addToFavorite, removeFromFavorite } = useFavorite((state) => ({
    addToFavorite: state.addToFavorite,
    removeFromFavorite: state.removeFromFavorite,
  }));

  const onAddToCart = async () => {
    setLoading(true);
    await addToCart(id);

    // Отправляю данные в Яндекс.Метрику
    sendMetrik('reachGoal', 'add');

    setLoading(false);
    setIsItemInCart(true);
  };

  const onAddFavorite = () => {
    addToFavorite(id);
    setInFavorite(true);
  };

  const onRemoveFavorite = () => {
    removeFromFavorite(id);
    setInFavorite(false);
  };

  // Вызов модалки Вход в ЛК
  const onShowLoginModal = () => {
    setActiveLoginModal(true);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.photoContainer}>
        <div className={styles.favoriteIcon}>
          {inFavorite ? (
            <GoHeartFill onClick={onRemoveFavorite} />
          ) : (
            <GoHeart onClick={isAuth ? onAddFavorite : onShowLoginModal} />
          )}
        </div>
        {!price || !is_active || !quantity ? (
          <div className={styles.badge}>
            <WarningBadge text="Под заказ" />
          </div>
        ) : null}
        <Link
          className={styles.imgLinkContainer}
          key={id}
          to={`/catalog/product/${slug_url}`}
        >
          <img
            className={styles.photoCard}
            src={imgSrc}
            onError={() => setImgSrc('/img/no-image.webp')}
            alt={title}
            loading="lazy"
          />
        </Link>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.cardTextContainer}>
          {price > 0 && is_active && quantity ? (
            <div className={styles.cardPrices}>
              <>
                <p className={styles.cardPrice}>
                  {toCurrency.format(roundPrice(discount_price))} руб.
                </p>
                {discount > 0 && (
                  <>
                    <p className={styles.cardOldPrice}>
                      {toCurrency.format(roundPrice(price))} руб.
                    </p>
                    <p className={styles.cardDiscount}>-{discount}%</p>
                  </>
                )}
              </>
            </div>
          ) : null}
          <Link className={styles.textCard} to={`/catalog/product/${slug_url}`}>
            {title}
          </Link>
        </div>
        {price > 0 && is_active && quantity ? (
          <>
            {inCart ? (
              <Link to="/cart">
                <MainButton text="Перейти в корзину" />
              </Link>
            ) : (
              <MainButton
                text="В корзину"
                onClick={onAddToCart}
                loading={loading}
              />
            )}
          </>
        ) : (
          <Link to={`/catalog/product/${slug_url}`}>
            <MainButton text="Изготовить похожее" />
          </Link>
        )}
      </div>
    </div>
  );
}

export { Card };
