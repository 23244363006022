import React from 'react';
import styles from './CategoryItem.module.scss';

function CategoryItem({ img, title }) {
  return (
    <div className={styles.category}>
      <div className={styles.imgContainer}>
        <img
          className={styles.categoryImage}
          src={'./img/category-img/' + img}
          alt="category"
        />
      </div>
      <p className={styles.categoryText}>{title}</p>
    </div>
  );
}

export { CategoryItem };
