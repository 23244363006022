import React from 'react';
import styles from './otzyvy.module.scss';

// import Revievs from 'modules/Revievs/Revievs'
import SmartWidgets from 'entities/Revievs/SmartWidgets/SmartWidgets';

function Otzyvy() {
  return (
    <>
      <h1 className={styles.otzyvyMainTitle}>Более 1000 отзывов о нас</h1>
      {/* Это наш стандартный модуль с отзывами */}
      {/* <Revievs /> */}
      <SmartWidgets />
    </>
  );
}

export { Otzyvy };
