import React, { useState, useEffect } from 'react';
import styles from './UserInfo.module.scss';
import { useForm } from 'react-hook-form';

import { useUser } from 'store/user';
import { MainButton } from 'shared/ui/MainButton';
import { Checkbox } from 'shared/ui/Checkbox';

function UserInfo() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const {
    first_name,
    last_name,
    email,
    loading,
    error,
    is_mailing,
    getUser,
    patchUser,
  } = useUser((state) => ({
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    email: state.user.email,
    is_mailing: state.user.is_mailing,
    loading: state.loading,
    error: state.error,
    getUser: state.getUser,
    patchUser: state.patchUser,
  }));

  const [valudateButton, setValidateButton] = useState(true);

  const [firstNameState, setFirstNameState] = useState(first_name);
  const [lastNameState, setLastNameState] = useState(last_name);
  const [emailState, setEmailState] = useState(email);
  const [isSubscribed, setIsSubscribed] = useState(is_mailing);

  const onSubmit = () => {
    patchUser(firstNameState, lastNameState, emailState, isSubscribed);

    setValidateButton(true);
  };

  const validateButton = () => {
    if (
      emailState === email &&
      firstNameState === first_name &&
      lastNameState === last_name &&
      isSubscribed === is_mailing
    )
      setValidateButton(true);
    else {
      setValidateButton(false);
    }
  };

  useEffect(() => {
    validateButton();
  }, [emailState, firstNameState, lastNameState, isSubscribed]);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className={styles.userInfo}>
      <h2>Информация о пользователе</h2>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              placeholder="Введите Ваше имя"
              type="text"
              {...register('first_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите Ваше имя',
                },
              })}
              value={firstNameState}
              onChange={(event) => setFirstNameState(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') event.preventDefault();
              }}
            />

            <div className={styles.error}>
              {errors?.first_name && (
                <p>{errors?.first_name?.message || 'Ошибка'}</p>
              )}
            </div>
          </div>

          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              placeholder="Введите Вашу фамилию"
              type="text"
              {...register('last_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите Вашу фамилию',
                },
              })}
              value={lastNameState}
              onChange={(event) => setLastNameState(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') event.preventDefault();
              }}
            />

            <div className={styles.error}>
              {errors?.last_name && (
                <p>{errors?.last_name?.message || 'Ошибка'}</p>
              )}
            </div>
          </div>

          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              placeholder="Эл. почта"
              type="email"
              {...register('email_adres', {
                maxLength: {
                  value: 40,
                  message: 'Кажется, здесь ошибка',
                },
              })}
              value={emailState}
              onChange={(event) => setEmailState(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') event.preventDefault();
              }}
            />

            {error ? (
              <div className={styles.error}>
                <p>{error}</p>
              </div>
            ) : (
              <div className={styles.error}>
                {errors?.email_adres && (
                  <p>
                    {errors?.email_adres?.message || 'Кажется, здесь ошибка'}
                  </p>
                )}
              </div>
            )}
          </div>
          {email && (
            <Checkbox
              label="Получать рассылку на почту"
              checked={isSubscribed}
              handleChange={(event) => setIsSubscribed(event.target.checked)}
            />
          )}
        </div>
        <MainButton
          text="Сохранить"
          type="submit"
          disabled={valudateButton}
          loading={loading}
        />
      </form>
    </div>
  );
}

export { UserInfo };
