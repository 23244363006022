import React from 'react';
import styles from './vozvrat.module.scss';

import { InfoDropdown } from 'shared/ui/InfoDropdown';

function Vozvrat() {
  return (
    <>
      <h1 className={styles.vozvratMainTitle}>Возврат и обмен</h1>
      <section className={styles.vozvratContainer}>
        <InfoDropdown title={'Ювелирные товары возврату не подлежат'}>
          <p className={styles.vozvratParagraph}>
            В соответствии с постановлением Правительства Российской Федерации
            от 19.01.98 №55 ювелирные изделия из драгоценных металлов и
            драгоценных камней возврату или обмену не подлежат.
          </p>
        </InfoDropdown>
        <InfoDropdown title={'Возврат бракованных изделий'}>
          <p className={styles.vozvratParagraph}>
            Обязательно проверяйте целостность упаковки в присутствии курьера! В
            случае, если после получения заказа Вы обнаружили брак, или изделие
            вам не подошло, Вы можете отказаться от заказа только в присутствии
            курьера! Стоимость обратной пересылки в таком случае Вы не
            оплачиваете, но и сумма, уплаченная за доставку до пункта назначения
            (до Вас) не подлежит возврату.
          </p>
          <p className={styles.vozvratParagraph}>
            В случае, если во время осмотра изделия в присутствии курьера Вы не
            заметили возможного повреждения, брака, поломки изделия, вызванных
            ненадлежащей транспортировкой, чего-либо еще, что Вас в дальнейшем
            бы не устроило (размер, вид, цвет),- в дальнейшем претензии к
            Интернет-магазину
            <a href="https://grand.gold/" className={styles.vozvratLink}>
              {' '}
              grand.gold
            </a>{' '}
            не принимаются.
          </p>
        </InfoDropdown>
        <InfoDropdown title={'Срок возврата средств на банковскую карту'}>
          <p className={styles.vozvratParagraph}>
            Если оплата производилась посредством банковской карты, возврат
            средств возможен только на счет данной банковской карты с указанием
            полных банковских реквизитов этой карты и только при наличии
            заявления о возврате денежных средств в течение 3-5 рабочих дней со
            дня получения магазином такого заявления.
          </p>
        </InfoDropdown>
      </section>
    </>
  );
}

export { Vozvrat };
