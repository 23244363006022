import React from 'react';
import { Link } from 'react-router-dom';

import { MainButton } from 'shared/ui/MainButton';

export default function EmptyTotal() {
  return (
    <>
      <h2>Добавьте товар в корзину</h2>
      <p>
        Скорее всего этот товар был продан. Но мы можем изготовить такой же для
        Вас на заказ! Для этого просто перейдите на страницу этого товара и
        оставьте заявку на изготовление.
      </p>
      <Link to={'/catalog'}>
        <MainButton text="Перейти в Каталог" />
      </Link>
    </>
  );
}
