import React from 'react';
import styles from './MainButton.module.scss';
import { Spinner } from 'shared/ui/Spinner';

function MainButton({ text, disabled, onClick, type, loading }) {
  return (
    <button
      className={loading ? styles.mainLoadingButton : styles.mainButton}
      disabled={disabled}
      onClick={onClick}
      type={type || 'button'}
    >
      {loading ? <Spinner size="small" /> : text}
    </button>
  );
}

export { MainButton };
