import React from 'react';
import styles from './CartButton.module.scss';
import { NavLink } from 'react-router-dom';

import { useCart } from 'store/cart';

import { BsBagCheckFill } from 'react-icons/bs';
import { BsBagFill } from 'react-icons/bs';

function CartButton() {
  const items = useCart((state) => state.items);

  return (
    <NavLink
      to="cart"
      className={({ isActive }) =>
        isActive ? styles.cartLinkActive : styles.cartLink
      }
    >
      {items.length ? (
        <BsBagCheckFill className={styles.icon} size={24} />
      ) : (
        <BsBagFill className={styles.icon} size={24} />
      )}
    </NavLink>
  );
}

export { CartButton };
