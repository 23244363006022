import { create } from 'zustand';

let modal = (set) => ({
  active: false,
  setActiveModal: (param) => {
    set({ active: param });
  },
});

export const useAddOrderError = create(modal);
