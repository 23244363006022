import styles from './Sort.module.scss';

import { useSort } from 'store/sort';
import { useCatalogPagination } from 'store/pagination';

import { Dropdown } from 'shared/ui/Dropdown';

function Sort() {
  const setPagination = useCatalogPagination((state) => state.setPagination);
  const { sort, sortItems, setSort } = useSort((state) => ({
    sort: state.sort,
    sortItems: state.sortItems,
    setSort: state.setSort,
  }));

  const handleSort = (value) => {
    setSort(value);
    setPagination(1);
  };

  return (
    <Dropdown title={sort.name}>
      <div className={styles.sortCatalogItems}>
        {sortItems.map((item) => (
          <div
            onClick={() => handleSort(item.value)}
            className={
              sort.value === item.value ? styles.active : styles.sortCatalogItem
            }
            key={item.value}
          >
            {item.name}
          </div>
        ))}
      </div>
    </Dropdown>
  );
}

export { Sort };
