import React from 'react';
import styles from './Spinner.module.scss';

function Spinner({ size }) {
  return (
    <span className={size === 'big' ? styles.loaderBig : styles.loader}></span>
  );
}

export { Spinner };
