import { create } from 'zustand';
import $api from '../app/axios/axiosConfig';
import { urls } from '../api/Config/usrlsConfig';

let items = (set) => ({
  items: [],
  itemsCount: 0,
  description: null,
  loading: false,
  warning: null,
  error: false,

  fetchItems: async (
    limit,
    page,
    searchParam,
    sortParam,
    fullLightWeightParam,
    tradeOfferTypeParam,
    colorMetalParam,
    materialParam,
    forwhomParam,
    storeParam,
    probParam,
    categoryParam,
    subcategoryParam,
    sizeParam,
    vstavkiParam,
    vstavkiDetailParam,
    minWeightParam,
    maxWeightParam,
    minPriceParam,
    maxPriceParam,
  ) => {
    set({ loading: true });

    const params = {
      limit: limit || undefined,
      offset: page && limit ? (page - 1) * limit : undefined,
      search: searchParam || undefined,
      ordering: sortParam.value || undefined,
      full_weight_or_light_weight: fullLightWeightParam || undefined,
      trade_offer_type: tradeOfferTypeParam || undefined,
      color_metal: colorMetalParam || undefined,
      material: materialParam || undefined,
      forwhom: forwhomParam || undefined,
      store: storeParam || undefined,
      sample: probParam || undefined,
      category: categoryParam || undefined,
      subcategory: subcategoryParam || undefined,
      size: sizeParam || undefined,
      vstavki: vstavkiParam || undefined,
      vstavkidetail: vstavkiDetailParam || undefined,
      weight_min: minWeightParam || undefined,
      weight_max: maxWeightParam || undefined,
      min_price: minPriceParam || undefined,
      max_price: maxPriceParam || undefined,
    };

    // Запрашиваем каталог
    try {
      const response = await $api.get(urls.getItems, {
        params: params,
      });

      if (response.data.count === 0) {
        set({
          itemsCount: 0,
          items: [],
          description: null,
          warning:
            'Нет товаров, соответствующих заданным параметрам. Попробуйте поменять или убрать параметры в фильтре.',
          error: false,
        });
      } else {
        set({
          items: response.data,
          itemsCount: response.data.count,
          description: response.data.description,
          warning: null,
          error: false,
        });
      }
    } catch (error) {
      set({
        itemsCount: 0,
        items: [],
        description: null,
        warning: null,
        error: true,
      });
    } finally {
      set({ loading: false });
    }
  },
});

export const useCatalogItems = create(items);
