import { Link } from 'react-router-dom';
import styles from './ErrorContainer.module.scss';

import { MainButton } from 'shared/ui/MainButton';
import { SecondaryButton } from 'shared/ui/SecondaryButton';

function ErrorContainer({ title, message }) {
  return (
    <div className={styles.itemError}>
      <h1 className={styles.title}>{title}</h1>
      {message && <p className={styles.info}>{message}</p>}
      <div className={styles.buttons}>
        <Link to="/catalog">
          <MainButton text="Перейти в Каталог" />
        </Link>
        <Link to="/about">
          <SecondaryButton text="Связаться с нами" />
        </Link>
      </div>
    </div>
  );
}

export { ErrorContainer };
