import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import styles from './Navbar.module.scss';

import { useOrder } from 'store/order';
import { useUser } from 'store/user';
import { SecondaryButton } from 'shared/ui/SecondaryButton';

function Navbar() {
  const resetOrder = useOrder((state) => state.resetOrder);
  const { first_name, last_name, setLogOut } = useUser((state) => ({
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    setLogOut: state.setLogOut,
  }));

  const onLogOut = () => {
    setLogOut();
    resetOrder();
  };

  return (
    <div className={styles.cabinetNavbar}>
      <div className={styles.navbarUserInfo}>
        <span className={styles.fioSpan}>Фио</span>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.navbarUserFioActive : styles.navbarUserFio
          }
          to="/profile/info"
        >
          {first_name + ' ' + last_name}
        </NavLink>
      </div>
      <div className={styles.navbarList}>
        <NavLink
          to="/profile/orders"
          className={({ isActive }) =>
            isActive ? styles.active : styles.navbarListItem
          }
        >
          Мои заказы
        </NavLink>
        <Link to="/about" className={styles.navbarListItem}>
          Адреса филиалов
        </Link>
        <Link to="/vopros-otvet" className={styles.navbarListItem}>
          Вопрос-ответ
        </Link>
        <SecondaryButton text="Выход" onClick={onLogOut} />
      </div>
    </div>
  );
}

export { Navbar };
