import styles from './SEOCatalogPage.module.scss';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import axios from 'axios';

import { urls } from 'api/Config/usrlsConfig';
import { transliterateCategoryParam } from 'shared/lib/SEOutils/transliterateCategoryParam';
import { transliterateSubcategoryParam } from 'shared/lib/SEOutils/transliterateSubcategoryParam';
import { transliterateMaterialParam } from 'shared/lib/SEOutils/transliterateMaterialParam';
import { transliterateForwhomParam } from 'shared/lib/SEOutils/transliterateForwhomParam';
import { transliterateVstavkiParam } from 'shared/lib/SEOutils/transliterateVstavkiParam';
import { transliterateColorParam } from 'shared/lib/SEOutils/transliterateColorParam';
import {
  paramMapping,
  createFilters,
} from 'shared/lib/SEOutils/seoParamMapping';

import { Filter } from 'entities/Filter';
import { CatalogPagination } from 'entities/CatalogPagination';
import { Sort } from 'entities/Sort';
import { CatalogItemsCounter } from 'entities/CatalogItemsCounter';
import { FilterIcon } from 'shared/ui/FilterIcon';
import { SEOCatalogTitle } from 'entities/SEO/SEOCatalogTitle';
import SeoCatalogItems from 'widgets/CatalogItems/ui/SeoCatalogItems';

import { useFilters } from 'store/filters';
import { useCatalogPagination } from 'store/pagination';
import { useSort } from 'store/sort';

function SEOCatalogPage() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMounted, setMounted] = useState(false);
  // Подсчет страниц для пагинации
  const [itemsCount, setItemsCount] = useState(0);

  const { category, subcategory, material, color_metal, forwhom, vstavki } =
    useParams();
  // Достаю фильтры из seoParamMapping.js
  const filters = createFilters(
    paramMapping,
    category,
    subcategory,
    material,
    color_metal,
    forwhom,
    vstavki,
  );

  // Достаю значения фильтров
  // Подбивать буду категорию, для кого, материал, вставки и подкатегории
  // За остальными просто следим
  const {
    setFilters,
    categoryParam,
    materialParam,
    forwhomParam,
    vstavkiParam,
    subcategoryParam,
    colorMetalParam,
    vstavkiDetailParam,
    tradeOfferTypeParam,
    fullLightWeightParam,
    storeParam,
    sizeParam,
    probParam,
    minWeightParam,
    maxWeightParam,
    minPriceParam,
    maxPriceParam,
    resetFilters,
  } = useFilters((state) => ({
    setFilters: state.setFilters,
    categoryParam: state.filters.category,
    materialParam: state.filters.material,
    forwhomParam: state.filters.forwhom,
    vstavkiParam: state.filters.vstavki,
    subcategoryParam: state.filters.subcategory,
    colorMetalParam: state.color_metal,
    vstavkiDetailParam: state.filters.vstavkidetail,
    tradeOfferTypeParam: state.filters.trade_offer_type,
    fullLightWeightParam: state.filters.full_light_weight,
    storeParam: state.filters.store,
    sizeParam: state.filters.size,
    probParam: state.filters.prob,
    minWeightParam: state.filters.minWeight,
    maxWeightParam: state.filters.maxWeight,
    minPriceParam: state.filters.minPrice,
    maxPriceParam: state.filters.maxPrice,
    resetFilters: state.resetFilters,
  }));

  // Пагинация
  const { paginationParam, setPaginationState, limit } = useCatalogPagination(
    useShallow((state) => ({
      paginationParam: state.pagination,
      setPaginationState: state.setPagination,
      limit: state.pageLimit,
    })),
  );

  // Сортировка
  const setSort = useSort((state) => state.setSort);

  // Получаю товары
  const fetchSeoCatalog = async (
    category,
    material,
    color_metal,
    forwhom,
    vstavki,
    subcategory,
    paginationParam,
    limit,
  ) => {
    setError(false);
    setLoading(true);

    const pagination = paginationParam
      ? `limit=${limit}&offset=${(paginationParam - 1) * limit}`
      : '';

    try {
      const response = await axios.get(
        `${urls.getSeoItems}${category}/${subcategory}/${material}/${forwhom}/${vstavki}/${color_metal}?${pagination}`,
      );

      if (response.data.count === 0) {
        setError(true);
      } else {
        setItems(response.data.results);
        setItemsCount(response.data.count);
        // Дальше устанавливаю значения в фильтр
        setFilters('category', transliterateCategoryParam(filters.category));
        setFilters(
          'subcategory',
          transliterateSubcategoryParam(filters.subcategory),
        );
        setFilters('material', transliterateMaterialParam(filters.material));
        setFilters('color_metal', transliterateColorParam(filters.color_metal));
        setFilters('forwhom', transliterateForwhomParam(filters.forwhom));
        setFilters('vstavki', transliterateVstavkiParam(filters.vstavki));

        setLoading(false);
        setError(false);
        setMounted(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Чистим ранее заданные пользователем фильтры
  useEffect(() => {
    resetFilters();
    setPaginationState(1);
    setSort('-id');
  }, []);

  // Редиректим на стандартный каталог,
  // если параметры любого фильтра изменились
  useEffect(() => {
    if (isMounted) {
      navigate('/catalog');
    }
  }, [
    categoryParam,
    vstavkiParam,
    subcategoryParam,
    vstavkiDetailParam,
    tradeOfferTypeParam,
    colorMetalParam,
    fullLightWeightParam,
    storeParam,
    materialParam,
    forwhomParam,
    sizeParam,
    probParam,
    minWeightParam,
    maxWeightParam,
    minPriceParam,
    maxPriceParam,
  ]);

  useEffect(() => {
    fetchSeoCatalog(
      category,
      subcategory,
      material,
      color_metal,
      forwhom,
      vstavki,
      paginationParam,
      limit,
    );
  }, [
    category,
    subcategory,
    material,
    color_metal,
    forwhom,
    vstavki,
    paginationParam,
    limit,
  ]);

  if (error) {
    navigate('/404');
  }

  return (
    <div className={styles.catalogPage}>
      <div className={styles.catalogTitleContainer}>
        <SEOCatalogTitle />
        <CatalogItemsCounter itemsCount={itemsCount} loading={loading} />
      </div>
      <div className={styles.catalogContainer}>
        <Filter />
        <div className={styles.sortCatalogContainer}>
          <div className={styles.sortMobileFilter}>
            <Sort />
            <FilterIcon />
          </div>
          <SeoCatalogItems loading={loading} items={items} />
        </div>
      </div>
      <CatalogPagination itemsCount={itemsCount} />
    </div>
  );
}

export { SEOCatalogPage };
