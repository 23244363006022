export const dlg = [
  {
    id: 1,
    name: 'Магазин на Долгополова',
    img: '../img/shop-img/DolgopolovaStore/dlg_1.webp',
  },
  {
    id: 2,
    name: 'Магазин на Долгополова',
    img: '../img/shop-img/DolgopolovaStore/dlg_2.webp',
  },
  {
    id: 3,
    name: 'Магазин на Долгополова',
    img: '../img/shop-img/DolgopolovaStore/dlg_3.webp',
  },
  {
    id: 4,
    name: 'Магазин на Долгополова',
    img: '../img/shop-img/DolgopolovaStore/dlg_4.webp',
  },
  {
    id: 5,
    name: 'Магазин на Долгополова',
    img: '../img/shop-img/DolgopolovaStore/dlg_5.webp',
  },
];

export const rdv = [
  {
    id: 1,
    name: 'Магазин на Родионова',
    img: '../img/shop-img/RodionovaStore/rdn_1.webp',
  },
  {
    id: 2,
    name: 'Магазин на Родионова',
    img: '../img/shop-img/RodionovaStore/rdn_2.webp',
  },
  {
    id: 3,
    name: 'Магазин на Родионова',
    img: '../img/shop-img/RodionovaStore/rdn_3.webp',
  },
];

export const ggr = [
  {
    id: 1,
    name: 'Магазин на Гагарина',
    img: '../img/shop-img/GagarinaStore/ggr_1.webp',
  },
];

export const ubl = [
  {
    id: 1,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_1.webp',
  },
  {
    id: 2,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_2.webp',
  },
  {
    id: 3,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_3.webp',
  },
  {
    id: 4,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_4.webp',
  },
  {
    id: 5,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_5.webp',
  },
  {
    id: 6,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_6.webp',
  },
  {
    id: 7,
    name: 'Магазин на Юбилейном',
    img: '../img/shop-img/JubileyniyStore/ubl_7.webp',
  },
];
