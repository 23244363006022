import React from 'react';
import styles from './Contacts.module.scss';

function Contacts() {
  return (
    <div className={styles.contacts}>
      <a className={styles.contactLink} href="mailto:info@grand.gold">
        info@grand.gold
      </a>
      <a className={styles.contactLink} href="tel:+78007776930">
        8 (800) 777 69 30
      </a>
      <p className={styles.contact}>Звонок по России бесплатный</p>
    </div>
  );
}

export { Contacts };
