import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { materialStore } from '../../../../model/materialStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function MaterialFilter() {
  const { material, setFilters } = useFilters((state) => ({
    material: state.filters.material || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (value === material) {
      setFilters('material', '');
    } else {
      setFilters('material', value);
    }
  };

  return (
    <FilterDropdown title="Материал" isOpen={!!material}>
      <div className={styles.contentContainer}>
        {materialStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={material.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { MaterialFilter };
