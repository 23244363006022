import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AddressItem.module.scss';

export default function AddressItem({ url, address, time, info }) {
  return (
    <div className={styles.address}>
      <div className={styles.addressInfo}>
        <NavLink
          to={url}
          className={({ isActive }) =>
            isActive ? styles.activeLink : styles.addressLink
          }
        >
          <h3 className={styles.addressTitle}>{address}</h3>
        </NavLink>
        <p className={styles.addressWorkTime}>{time}</p>
      </div>
      <p className={styles.addressText}>{info}</p>
    </div>
  );
}
