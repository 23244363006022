export const forwhomStore = [
  {
    name: 'Женские',
    value: 'Женские',
  },
  {
    name: 'Мужские',
    value: 'Мужские',
  },
  {
    name: 'Детские',
    value: 'Детские',
  },
];
