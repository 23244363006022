import { useMessageModal } from '../lib/store';
import { MainButton } from 'shared/ui/MainButton';
import { ModalLayout } from '../../ModalLayouts/StandartModal';
import isStoreOpen from 'shared/lib/checkStoreOpen';

function MessageModal() {
  const { active, setActive } = useMessageModal((state) => ({
    active: state.active,
    setActive: state.setActiveModal,
  }));

  return (
    <ModalLayout
      active={active}
      onClose={() => setActive(false)}
      title="Благодарим за заказ"
      message={
        isStoreOpen()
          ? 'В течение 10 минут с Вами свяжется наш менеджер для подтверждения заказа'
          : 'Наш менеджер свяжется с Вами в рабочее время, завтра с 9:00 (по МСК)'
      }
    >
      <MainButton text="Хорошо" onClick={() => setActive(false)} />
    </ModalLayout>
  );
}

export { MessageModal };
