export const categoriesStore = [
  {
    name: 'Серьги',
    value: 'Серьги',
  },
  {
    name: 'Цепи',
    value: 'Цепи',
  },
  {
    name: 'Браслеты',
    value: 'Браслеты',
  },
  {
    name: 'Кольца',
    value: 'Кольца',
  },
  {
    name: 'Подвески',
    value: 'Подвески',
  },
  {
    name: 'Броши',
    value: 'Броши',
  },
  {
    name: 'Печатки',
    value: 'Печатки',
  },
  {
    name: 'Колье',
    value: 'Колье',
  },
  {
    name: 'Часы',
    value: 'Часы',
  },
  {
    name: 'Сувениры',
    value: 'Монеты и сувениры',
  },
  {
    name: 'Гайтаны',
    value: 'Гайтаны',
  },
  {
    name: 'Золотые слитки',
    value: 'Слитки',
  },
  {
    name: 'Запонки',
    value: 'Запонки',
  },
  {
    name: 'Зажимы для галстука',
    value: 'Зажимы для галстука',
  },
];
