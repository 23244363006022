import { create } from 'zustand';

let navbar = (set) => ({
  navbarState: false,
  setNavbarState: (param) => {
    set({ navbarState: param });
  },
});

export const useNavbar = create(navbar);
