import React from 'react';
import styles from './CatalogItems.module.scss';

import { useCatalogItems } from 'store/catalog';
import { useShallow } from 'zustand/react/shallow';
import { CatalogSkelleton } from 'shared/ui/Skelletons/Catalog';
import CatalogItemsError from './Error/CatalogItemsError';
import { Card } from 'entities/Card';

function CatalogItems({ setError }) {
  const { items, loading, error, warning } = useCatalogItems(
    useShallow((state) => ({
      items: state.items.results,
      loading: state.loading,
      error: state.error,
      warning: state.warning,
    })),
  );

  if (error) {
    setError(true);
  }

  if (warning) {
    return <CatalogItemsError error={warning} loading={loading} />;
  }

  return (
    <>
      {!loading && items !== undefined ? (
        <div className={styles.catalogItemsContainer}>
          {items.map((item) => (
            <Card
              key={item.id}
              id={item.id}
              title={item.title}
              price={item.price}
              discount_price={item.discount_price}
              discount={item.discount}
              image={item.image}
              slug_url={item.slug_url}
              is_active={item.is_active}
              quantity={item.quantity}
              isInCart={item.is_in_cart}
              isInFavorite={item.is_in_favorites}
            />
          ))}
        </div>
      ) : (
        <CatalogSkelleton />
      )}
    </>
  );
}

export { CatalogItems };
