import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function useFetchNewItems() {
  const [newItems, setNewItems] = useState([]);
  const [newItemsLoading, setNewItemsLoading] = useState(false);
  const [newItemsError, setNewItemsError] = useState(null);

  // Нужно для вывода товаров
  const limit = 32;
  const offset = 0;

  async function fetchNewItems() {
    setNewItemsLoading(true);

    try {
      const response = await $api.get(
        `${urls.getItems}?limit=${limit}&offset=${offset}`,
      );

      setNewItems(response.data.results);
      setNewItemsError(null);
    } catch (error) {
      setNewItemsError(error.message);
    } finally {
      setNewItemsLoading(false);
    }
  }

  return { newItems, newItemsLoading, newItemsError, fetchNewItems };
}

export { useFetchNewItems };
