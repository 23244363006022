import React, { useState, useEffect } from 'react';
import styles from './FilterCheckbox.module.scss';

function FilterCheckbox({ item, label, handleChange, checked }) {
  const [isChecked, setIsChecked] = useState(checked || false);

  //Обновляю isChecked при изменении checked
  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    handleChange(event);
  };

  return (
    <label
      className={`${styles.checkboxContainer} ${
        isChecked ? styles.checkedStyle : ''
      }`}
    >
      <div className={styles.inputContainer}>
        <input
          className={styles.checkbox}
          type="checkbox"
          value={item.value}
          checked={checked || isChecked}
          onChange={(event) => handleCheckboxChange(event)}
        />
        <span className={styles.checkmark}></span>
      </div>
      <span className={styles.labelText}>{label || 'Параметр'}</span>
    </label>
  );
}

export { FilterCheckbox };
