import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

// Оплата Тинькофф
function useTinkoffPayment() {
  const [payTinkoffLoading, setPayTinkoffLoading] = useState(false);
  const [payTinkoffError, setPayTinkoffError] = useState(null);

  async function payOrderTinkoff(id) {
    setPayTinkoffLoading(true);

    try {
      const response = await $api.post(urls.payOrderTinkoff, {
        order_id: id,
      });

      window.location.replace(response.data.payment_url);
    } catch (error) {
      console.log(error);
      setPayTinkoffError(error.message);
    } finally {
      setPayTinkoffLoading(false);
    }
  }

  return { payTinkoffError, payTinkoffLoading, payOrderTinkoff };
}

export { useTinkoffPayment };
