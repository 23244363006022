import { useEffect, useRef, useState } from 'react';
import styles from './CatalogPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { useFilters } from 'store/filters';
import { useSort } from 'store/sort';
import { useCatalogPagination } from 'store/pagination';
import { useSearch } from 'store/search';
import { useShallow } from 'zustand/react/shallow';
import { useCatalogItems } from 'store/catalog';
import { scrollTop } from 'shared/lib/scrollTop';

import { CatalogTitle } from 'entities/CatalogTitle';
import { CatalogItemsCounter } from 'entities/CatalogItemsCounter';
import { CatalogContainer } from 'widgets/CatalogContainer';
import { CatalogPagination } from 'entities/CatalogPagination';

function CatalogPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(false);
  const isURL = useRef(false);
  const [error, setError] = useState(false);

  const { itemsCount, fetchItems, description, loading } = useCatalogItems(
    useShallow((state) => ({
      itemsCount: state.items.count,
      fetchItems: state.fetchItems,
      description: state.description,
      loading: state.loading,
    })),
  );

  // Сортировка
  const { sortParam, setSort } = useSort(
    useShallow((state) => ({
      sortParam: state.sort,
      setSort: state.setSort,
    })),
  );

  // Поиск
  const { searchParam, setSearch } = useSearch(
    useShallow((state) => ({
      searchParam: state.search,
      setSearch: state.setSearch,
    })),
  );

  // Пагинация
  const { paginationParam, limit, setPagination } = useCatalogPagination(
    useShallow((state) => ({
      paginationParam: state.pagination,
      limit: state.pageLimit,
      setPagination: state.setPagination,
    })),
  );

  // Фильтры
  const {
    fullLightWeightParam,
    tradeOfferTypeParam,
    colorMetalParam,
    materialParam,
    forwhomParam,
    storeParam,
    probParam,
    categoryParam,
    subcategoryParam,
    sizeParam,
    vstavkiParam,
    vstavkiDetailParam,
    minWeightParam,
    maxWeightParam,
    minPriceParam,
    maxPriceParam,
    setFilters,
  } = useFilters(
    useShallow((state) => ({
      fullLightWeightParam: state.filters.full_light_weight,
      tradeOfferTypeParam: state.filters.trade_offer_type,
      colorMetalParam: state.filters.color_metal,
      materialParam: state.filters.material,
      forwhomParam: state.filters.forwhom,
      storeParam: state.filters.store,
      probParam: state.filters.prob,
      categoryParam: state.filters.category,
      subcategoryParam: state.filters.subcategory,
      sizeParam: state.filters.size,
      vstavkiParam: state.filters.vstavki,
      vstavkiDetailParam: state.filters.vstavkidetail,
      minWeightParam: state.filters.minWeight,
      maxWeightParam: state.filters.maxWeight,
      minPriceParam: state.filters.minPrice,
      maxPriceParam: state.filters.maxPrice,
      setFilters: state.setFilters,
    })),
  );

  // Зависимости
  const addictions = [
    limit,
    paginationParam,
    searchParam,
    sortParam,
    fullLightWeightParam,
    tradeOfferTypeParam,
    colorMetalParam,
    materialParam,
    forwhomParam,
    storeParam,
    probParam,
    categoryParam,
    subcategoryParam,
    sizeParam,
    vstavkiParam,
    vstavkiDetailParam,
    minWeightParam,
    maxWeightParam,
    minPriceParam,
    maxPriceParam,
  ];

  // Формируем пользовательский URL
  useEffect(() => {
    const queryParams = {
      page: paginationParam || undefined,
      search: searchParam || undefined,
      sort: sortParam.value || undefined,
      //дальше фильтры
      polnoves: fullLightWeightParam || undefined,
      type: tradeOfferTypeParam || undefined,
      color: colorMetalParam || undefined,
      material: materialParam || undefined,
      forwhom: forwhomParam || undefined,
      adres: storeParam || undefined,
      prob: probParam || undefined,
      category: categoryParam || undefined,
      subcategory: subcategoryParam || undefined,
      size: sizeParam || undefined,
      vstavki: vstavkiParam || undefined,
      vstavkidetail: vstavkiDetailParam || undefined,
      w_min: minWeightParam || undefined,
      w_max: maxWeightParam || undefined,
      min_price: minPriceParam || undefined,
      max_price: maxPriceParam || undefined,
    };

    const queryString = qs.stringify(queryParams);

    navigate(`?${queryString}`);
  }, addictions);

  // Устанавливаем фильтры из URL при первом рендеринге
  useEffect(() => {
    if (location.search && !isMounted.current) {
      const queryParams = qs.parse(location.search.substring(1));

      const {
        page,
        search,
        sort,
        polnoves,
        type,
        color,
        material,
        forwhom,
        adres,
        prob,
        category,
        subcategory,
        size,
        vstavki,
        vstavkidetail,
        w_min,
        w_max,
        min_price,
        max_price,
      } = queryParams;
      setPagination(Number(page));
      setSearch(search);
      setSort(sort);
      setFilters('full_light_weight', polnoves);
      setFilters('trade_offer_type', type);
      setFilters('color_metal', color);
      setFilters('material', material);
      setFilters('forwhom', forwhom);
      setFilters('store', adres);
      setFilters('prob', prob);
      setFilters('category', category);
      setFilters('subcategory', subcategory);
      setFilters('size', size);
      setFilters('vstavki', vstavki);
      setFilters('vstavkidetail', vstavkidetail);
      setFilters('minWeight', w_min);
      setFilters('maxWeight', w_max);
      setFilters('minPrice', min_price);
      setFilters('maxPrice', max_price);

      // Отметим, что url уже вставляли
      isURL.current = true;
      isMounted.current = true;
    }
  }, [location.search]);

  // Получаем товары
  useEffect(() => {
    scrollTop();

    if (!isURL.current) {
      fetchItems(
        limit,
        paginationParam,
        searchParam,
        sortParam,
        fullLightWeightParam,
        tradeOfferTypeParam,
        colorMetalParam,
        materialParam,
        forwhomParam,
        storeParam,
        probParam,
        categoryParam,
        subcategoryParam,
        sizeParam,
        vstavkiParam,
        vstavkiDetailParam,
        minWeightParam,
        maxWeightParam,
        minPriceParam,
        maxPriceParam,
      );
    }

    isURL.current = false;
  }, addictions);

  if (error) {
    navigate('/404');
  }

  return (
    <>
      <div className={styles.catalogTitleContainer}>
        <CatalogTitle />
        <CatalogItemsCounter itemsCount={itemsCount} loading={loading} />
      </div>
      <CatalogContainer setError={setError} />
      <CatalogPagination itemsCount={itemsCount} />
      {description && (
        <p className={styles.catalogDescription}>{description}</p>
      )}
    </>
  );
}

export { CatalogPage };
