import React from 'react';
import styles from './vacancy.module.scss';

function Vacancy() {
  return (
    <>
      <h1 className={styles.vacancyTitle}>Вакансии</h1>
      <p className={styles.vacancySubtitle}>
        В данный момент открыто 3 вакансии
      </p>
      <h2 className={styles.itemTitle}>Ювелир</h2>
      <div className={styles.item}>
        <div className={styles.itemContainer}>
          <div className={styles.itemInfo}>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>
                Со своей стороны гарантируем:
              </h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Заработную плату без задержки
                </li>
                <li className={styles.itemInfoItem}>
                  Обеспечение инструментом, расходниками и оборудованием
                </li>
                <li className={styles.itemInfoItem}>
                  Постоянную загрузку без простоев
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>От Вас ожидаем:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Качественное и своевременное выполнение заказов
                </li>
                <li className={styles.itemInfoItem}>
                  Техническое понимание и внимательность к деталям
                </li>
                <li className={styles.itemInfoItem}>Честность, аккуратность</li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Требования:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Изготовление ювелирных изделий
                </li>
                <li className={styles.itemInfoItem}>
                  Приведение изделий к товарному виду (полировка и чистка)
                </li>
                <li className={styles.itemInfoItem}>
                  Ремонт ювелирных изделий разной сложности
                </li>
                <li className={styles.itemInfoItem}>
                  Поддержание рабочего места в чистоте
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Условия:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Заработная плата 2 раза в месяц. СДЕЛКА: 40000-75000 рублей
                </li>
                <li className={styles.itemInfoItem}>
                  График на выбор 2/2, 3/3, 4/2, 5/2, с 9:00 по 18:00 или с 9:00
                  по 20:00
                </li>
                <li className={styles.itemInfoItem}>
                  Трудоустройство по ТК РФ (оплачиваемый отпуск)
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Бонусы:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Размер Вашего максимального дохода компания никак не
                  ограничивает
                </li>
                <li className={styles.itemInfoItem}>
                  Дружный коллектив и лояльное руководство
                </li>
              </ol>
            </div>

            <div className={styles.itemInfoContacts}>
              <h2
                className={
                  (styles.itemInfoTitle, styles.itemInfoTitleMainColor)
                }
              >
                Отдел подбора персонала:
              </h2>
              <a className={styles.itemTel} href="tel:+79200582458">
                +7 (920) 058 24 58
              </a>
              <a className={styles.itemEmail} href="mailto:info@grand.gold">
                info@grand.gold
              </a>
            </div>
          </div>
          <img
            className={styles.itemImage}
            src="/img/vacancy-img/jewelry-works.webp"
            alt="jewelry_works"
          />
        </div>
      </div>

      <h2 className={styles.itemTitle}>
        Продавец-консультант в ювелирный магазин
      </h2>
      <div className={styles.item}>
        <div className={styles.itemContainer}>
          <div className={styles.itemInfo}>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>
                Со своей стороны гарантируем:
              </h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Высокую стабильную заработную плату
                </li>
                <li className={styles.itemInfoItem}>
                  Стабильный поток клиентов
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>От Вас ожидаем:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Честность, пунктуальность и желание много продавать
                </li>
                <li className={styles.itemInfoItem}>
                  Качественное обслуживание клиентов и увеличение среднего чека
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Требования:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Опыт продаж от года обязателен. Он позволит вам быстрее выйти
                  на высокий уровень дохода
                </li>
                <li className={styles.itemInfoItem}>
                  Умение работать с ПК и орг. техникой на уровне пользователя
                </li>
                <li className={styles.itemInfoItem}>
                  Желателен опыт работы с программой 1С и навык быстрого набора
                  текста
                </li>
                <li className={styles.itemInfoItem}>
                  Коммуникабельность, внимательность, опрятность и
                  доброжелательность
                </li>
                <li className={styles.itemInfoItem}>
                  Готовность к обучению и развитию
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Условия:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Стабильная заработная плата 2 раза в месяц: оклад + проценты
                  (от 43200 до 75 000 рублей) + премии за перевыполнение планов
                </li>
                <li className={styles.itemInfoItem}>
                  Работа в подходящем районе (Нижегородский, Советский и
                  Канавинский районы)
                </li>
                <li className={styles.itemInfoItem}>
                  Сменный график 4/2, с 8:30 до 20:00
                </li>
                <li className={styles.itemInfoItem}>
                  Трудоустройство по ТК РФ (оплачиваемый отпуск)
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Бонусы:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Размер Вашего максимального дохода компания никак не
                  ограничивает
                </li>
                <li className={styles.itemInfoItem}>
                  Дополнительные квартальные и годовые премии по итогам
                  выполнения планов
                </li>
                <li className={styles.itemInfoItem}>
                  Профессиональный карьерный рост до руководителя филиала
                </li>
                <li className={styles.itemInfoItem}>
                  Оплачиваемое обучение внутри компании
                </li>
                <li className={styles.itemInfoItem}>
                  Дополнительные скидки на изделия для сотрудников компании
                </li>
                <li className={styles.itemInfoItem}>
                  Оплата рабочей формы сотрудников
                </li>
                <li className={styles.itemInfoItem}>
                  Дружный коллектив и лояльное руководство
                </li>
              </ol>
            </div>

            <div className={styles.itemInfoContacts}>
              <h2 className={styles.itemInfoTitleItemInfoTitleMainColor}>
                Отдел подбора персонала:
              </h2>
              <a className={styles.itemTel} href="tel:+79200582458">
                +7 (920) 058 24 58
              </a>
              <a className={styles.itemEmail} href="mailto:info@grand.gold">
                info@grand.gold
              </a>
            </div>
          </div>
          <img
            className={styles.itemImage}
            src="/img/vacancy-img/local-shop.webp"
            alt="local_shop"
          />
        </div>
      </div>

      <h2 className={styles.itemTitle}>
        Менеджер по продажам ювелирных украшений в интернет-магазин (входящие
        заявки)
      </h2>
      <div className={styles.item}>
        <div className={styles.itemContainer}>
          <div className={styles.itemInfo}>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>
                Со своей стороны гарантируем:
              </h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>Поток горячих клиентов</li>
                <li className={styles.itemInfoItem}>
                  Стабильную заработную плату без задержек
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>От Вас ожидаем:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Пунктуальность, грамотная речь и желание много продавать
                </li>
                <li className={styles.itemInfoItem}>
                  Качественную обработку интернет заявок и увеличение среднего
                  чека
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Требования:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Опыт продаж от года обязателен. Он позволит вам быстрее выйти
                  на высокий уровень дохода
                </li>
                <li className={styles.itemInfoItem}>
                  Уверенный пользователь ПК и соц. сетей (Instagram,ВК,
                  WhatsApp, Аvito и др.)
                </li>
                <li className={styles.itemInfoItem}>
                  Желателен опыт работы с программой 1С и СRM; навык набора
                  печатного текста
                </li>
                <li className={styles.itemInfoItem}>
                  Общение с 30-50 интернет клиентами (в день)
                </li>
                <li className={styles.itemInfoItem}>
                  Коммуникабельность, внимательность, доброжелательность
                </li>
                <li className={styles.itemInfoItem}>
                  Готовность к обучению и развитию
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Условия:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Заработная плата дважды в месяц: оклад + проценты (от 35000 до
                  70 000 рублей) + премии за перевыполнение планов
                </li>
                <li className={styles.itemInfoItem}>
                  Работа в офисе (рядом с ТЦ Республика)
                </li>
                <li className={styles.itemInfoItem}>
                  Сменный график 2/2, с 9:00 до 20:00
                </li>
                <li className={styles.itemInfoItem}>
                  Трудоустройство по ТК РФ (оплачиваемый отпуск)
                </li>
              </ol>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Бонусы:</h2>
              <ol className={styles.itemInfoList}>
                <li className={styles.itemInfoItem}>
                  Размер Вашего максимального дохода компания никак не
                  ограничивает
                </li>
                <li className={styles.itemInfoItem}>
                  Дополнительные квартальные и годовые премии по итогам
                  выполнения планов
                </li>
                <li className={styles.itemInfoItem}>
                  Профессиональный карьерный рост до руководителя отдела продаж
                </li>
                <li className={styles.itemInfoItem}>
                  Оплачиваемое обучение внутри компании
                </li>
                <li className={styles.itemInfoItem}>
                  Дополнительные скидки на изделия для сотрудников компании
                </li>
                <li className={styles.itemInfoItem}>
                  Дружный коллектив и лояльное руководство
                </li>
              </ol>
            </div>

            <div className={styles.itemInfoContacts}>
              <h2 className={styles.itemInfoTitleItemInfoTitleMainColor}>
                Отдел подбора персонала:
              </h2>
              <a className={styles.itemTel} href="tel:+79200582458">
                +7 (920) 058 24 58
              </a>
              <a className={styles.itemEmail} href="mailto:info@grand.gold">
                info@grand.gold
              </a>
            </div>
          </div>
          <img
            className={styles.itemImage}
            src="/img/vacancy-img/online-marketing.webp"
            alt="online_marketing"
          />
        </div>
      </div>
    </>
  );
}

export { Vacancy };
