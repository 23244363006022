import { create } from 'zustand';
import { persist } from 'zustand/middleware';

let filters = (set) => ({
  // Показать/скрыть мобильный фильтр
  showMobileFilter: false,
  setShowMobileFilter: (param) => {
    set({ showMobileFilter: param });
  },
  // Свойство для отслеживания активности фильтров
  filtersActive: false,
  // Сами фильтры
  filters: {},
  // Принимает первым параметром filters.название, вторым параметром value фильтра
  setFilters: (key, param) => {
    set((state) => ({
      filters: { ...state.filters, [key]: param },
      filtersActive: Object.values({
        ...state.filters,
        [key]: param,
      }).some(Boolean),
    }));
  },
  resetFilters: () => {
    set({
      filters: {},
      filtersActive: false,
    });
  },
});

filters = persist(filters, { name: 'filters' });
export const useFilters = create(filters);
