import { useState, useEffect } from 'react';
import { debounce } from './debounce';

function useWindowSize() {
  const getWindowSize = () => window.innerWidth;
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      setWindowSize(getWindowSize());
    }, 300);

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return windowSize;
}

export { useWindowSize };
