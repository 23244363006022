import styles from '../../../Filter.module.scss';
import { addresStore } from '../../../../model/addresStore';
import { useFilters } from 'store/filters';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function AdresFilter() {
  const { address, setFilters } = useFilters((state) => ({
    address: state.filters.store || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (value === address) {
      setFilters('store', '');
    } else {
      setFilters('store', value);
    }
  };

  return (
    <FilterDropdown title="Адреса салонов" isOpen={!!address}>
      <div className={styles.contentContainer}>
        {addresStore.map((item, index) => (
          <FilterCheckbox
            key={index}
            item={item}
            label={item.name}
            handleChange={() => handleChange(item.value)}
            checked={address.includes(item.value)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
}

export { AdresFilter };
