import React from 'react';

import { useAddOrderError } from '../lib/store';
import { ModalLayout } from '../../ModalLayouts/StandartModal';

function AddOrderError() {
  const { active, setActive } = useAddOrderError((state) => ({
    active: state.active,
    setActive: state.setActiveModal,
  }));

  return (
    <ModalLayout
      active={active}
      onClose={() => setActive(false)}
      title="Упс..."
      message={`Пожалуйста, воспользуйтесь кнопкой "Купить в 1 клик". Кажется, возникла ошибка при создании заказа.`}
    ></ModalLayout>
  );
}

export { AddOrderError };
