import React from 'react';
import styles from './FooterNavbar.module.scss';

import { LinkButton } from 'shared/ui/LinkButton';

function FooterNavbar() {
  return (
    <div className={styles.footerNavbar}>
      <LinkButton text="Оплата" link={`oplata`} />
      <LinkButton text="Доставка" link={`dostavka`} />
      <LinkButton text="Возврат и обмен" link={`vozvrat-obmen`} />
      <LinkButton text="Вакансии" link={`vacancy`} />
      <LinkButton text="События и акции" link={`news`} />
      <LinkButton text="Полезная информация" link={`info`} />
      <LinkButton text="Отзывы" link={`otzyvy`} />
      <LinkButton text="Контакты" link={`about`} />
      <LinkButton text="Вопрос-ответ" link={`vopros-otvet`} />
    </div>
  );
}

export { FooterNavbar };
