export const team = [
  {
    id: 1,
    position: 'left',
    name: 'Кирилл',
    experience: 'Стаж работы 9 лет',
    history:
      'Кирилл увлекался рисованием и хотел связать свою жизнь с ювелирным искусством.В этой профессии его привлекало совмещение прибыльности и эстетики.',
    education:
      'Средне-специальное образование Кирилл получал 5 лет! Он закончил художественную школу, учился в художественном лицее Карла Фаберже и закончил техникум народных художественных промыслов.',
    slogan: '“Стремление к совершенству, внимание к деталям”',
    img: 'member1.webp',
  },

  {
    id: 2,
    position: 'right',
    name: 'Константин ',
    experience: 'Стаж работы 43 года',
    history:
      'Любовь к рисованию подтолкнула Константина после 10-го класса пойти в Павловское художественное училище, которое он закончил с красным дипломом. С 1997 года Константин стал ювелиром.',
    education: 'Прекрасный учитель и наставник для младшего поколения.',
    slogan: '"Работайте не без ошибок, но умейте их исправлять"',
    img: 'member2.webp',
  },

  {
    id: 3,
    position: 'left',
    name: 'Елена',
    experience: 'Стаж работы 18 лет',
    history:
      'Елена выбрала профессию ювелира, потому что любит украшения. В газете она увидела объявление: “Требуются ученики ювелира”Елену с радостью взяли на данную должность и она приступила к долгому и упорному обучению.',
    education:
      'Сейчас Елене особенно доставляет удовольствие, когда клиенты забирают изделия и радуются результату.',
    slogan: '“Делать надо хорошо, ведь плохо умеет каждый”',
    img: 'member3.webp',
  },
];
