function sendYAMetrik(type, value) {
  if (
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== 'devgrandgold.ru'
  ) {
    return window.ym(48915560, type, value);
  }
}

export default sendYAMetrik;

// ym(48915560, 'reachGoal', '1clickcart')
// ym(48915560, 'reachGoal', '1click')
// ym(48915560, 'reachGoal', 'add')
// ym(48915560, 'reachGoal', 'order')
