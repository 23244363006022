export const vstavkiStore = [
  { name: 'Без вставок', value: 'no', index: 0 },
  { name: 'Фианиты', value: 'Фианит', index: 1 },
  { name: 'Бриллианты', value: 'Бриллиант', index: 2 },
  { name: 'Корунд', value: 'Корунд', index: 3 },
  { name: 'Янтарь', value: 'Янтарь', index: 4 },
  { name: 'Эмаль', value: 'Эмаль', index: 5 },
  { name: 'Рубин', value: 'Рубин', index: 6 },
  { name: 'Топаз', value: 'Топаз', index: 7 },
  { name: 'Сапфир', value: 'Сапфир', index: 8 },
  { name: 'Аметист', value: 'Аметист', index: 9 },
  { name: 'Изумруд', value: 'Изумруд', index: 10 },
  { name: 'Цитрин', value: 'Цитрин', index: 11 },
  { name: 'Гранат', value: 'Гранат', index: 12 },
  { name: 'Жемчуг', value: 'Жемчуг', index: 13 },
  { name: 'Малахит', value: 'Малахит', index: 14 },
];

export const vstavkiDetailStore = [
  { name: 'Драгоценные', value: 'драг', index: 15 },
  { name: 'Поделочные', value: 'ювел', index: 16 },
  { name: 'Полудрагоценные', value: 'полуДраг', index: 17 },
  { name: 'Синтетические', value: 'синт', index: 18 },
];
