import { create } from 'zustand';

let pagination = (set) => ({
  pagination: 1,
  pageLimit: 32,
  setPagination: (page) => {
    set({ pagination: page });
  },
  setPageLimit: (limit) => {
    set({ pageLimit: limit });
  },
});

export const useCatalogPagination = create(pagination);
