import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useUser } from 'store/user';

export default function useLogin() {
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const navigate = useNavigate();

  const { setAuth, getUser } = useUser((state) => ({
    setAuth: state.setAuth,
    getUser: state.getUser,
  }));

  async function onLogin(data) {
    setLoginLoading(true);
    const phone_number = localStorage.getItem('phone_number');
    const redirectPath = localStorage.getItem('redirectPath');

    try {
      const response = await $api.post(urls.login, {
        phone_number: phone_number,
        code: data.code,
      });

      localStorage.setItem('token', response.data.access_token);
      await getUser();
      setAuth(true);

      navigate(redirectPath || '/profile/info');
      localStorage.removeItem('phone_number');
      localStorage.removeItem('redirectPath');
    } catch (error) {
      localStorage.removeItem('token');
      setLoginError(error.response?.data.message);
    } finally {
      setLoginLoading(false);
    }
  }
  return {
    loginError,
    loginLoading,
    onLogin,
  };
}
