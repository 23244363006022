export const storesAddresses = {
  title: 'Наши филиалы в Нижнем Новгороде',
  stores: [
    {
      id: 1,
      url: '/about/dolgopolova',
      address: 'ул. Долгополова, 18',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Рядом с ЦУМом, недалеко от Московского вокзала',
    },

    {
      id: 2,
      url: '/about/rodionova',
      address: 'ул. Родионова, 17',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Остановка Фруктовая',
    },

    {
      id: 3,
      url: '/about/gagarina',
      address: 'пр-т Гагарина, 66',
      time: '9:00 до 20:00',
      info: 'Остановка Медицинская, напротив парка Швейцария',
    },

    {
      id: 4,
      url: '/about/jubileyniy',
      address: 'б-р Юбилейный, 6',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Рядом с Сормовским парком',
    },
  ],
};

export const jewsAddresses = {
  title: 'Наши ювелиры в Нижнем Новгороде',
  stores: [
    {
      id: 1,
      url: '/about/dolgopolova',
      address: 'ул. Долгополова, 18',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Рядом с ЦУМом, недалеко от Московского вокзала',
    },

    {
      id: 2,
      url: '/about/rodionova',
      address: 'ул. Родионова, 17',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Остановка Фруктовая',
    },

    {
      id: 3,
      url: '/about/jubileyniy',
      address: 'б-р Юбилейный, 6',
      time: '9:00 до 20:00',
      repairTime: '9:00 до 18:00',
      info: 'Рядом с Сормовским парком',
    },
  ],
};
