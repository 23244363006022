import React, { useEffect, useState } from 'react';
import styles from './FilterDropdown.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

import { BsChevronDown } from 'react-icons/bs';

const animation = {
  hidden: { height: 0, opacity: 0 },
  show: {
    height: 'auto',
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
  },
};

function FilterDropdown({ title, children, isOpen }) {
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    setOpenDropdown(isOpen || false);
  }, [isOpen]);

  return (
    <div className={styles.dropdownContainer}>
      <div
        className={styles.titleContainer}
        onClick={() => setOpenDropdown((prevState) => !prevState)}
        role="button"
      >
        <p className={openDropdown ? styles.dropdownTitleActive : ''}>
          {title || 'Название'}
        </p>
        <BsChevronDown
          className={openDropdown ? styles.rowReverse : styles.row}
          size={20}
        />
      </div>
      <AnimatePresence>
        {openDropdown && (
          <motion.div
            className={styles.dropdownContent}
            variants={animation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export { FilterDropdown };
