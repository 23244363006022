import React from 'react';
import styles from './Topic.module.scss';

function Topic() {
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>История бренда</h1>
      <div className={styles.info}>
        <p>
          Ювелирный Дом Grand Gold с 2007 года открыл свои двери для истинных
          ценителей уникальных ювелирных изделий!
        </p>
        <p>
          Благодаря слаженной работе нашей команды и выбору надежных поставщиков
          в Ювелирном Доме Grand Gold мы обеспечиваем выдающийся уровень сервиса
          и качества товара, чтобы каждый клиент уходил с улыбкой и рекомендовал
          нас друзьям и знакомым.
        </p>
        <p>
          В Grand Gold вы можете воспользоваться услугой ювелирного Trade-In для
          покупки желаемого украшения, заменив старое на новое или изготовить
          изделие вашей мечты по индивидуальному эскизу. 
        </p>
        <p>
          Миссия Ювелирного Дома Grand Gold привить каждому любовь и доверие к
          ювелирному искусству, сформировать правильный вкус и желание носить
          украшения! 
        </p>
      </div>
    </div>
  );
}

export { Topic };
