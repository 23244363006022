import React from 'react';
import './SmartWidgets.scss';

import { Helmet } from 'react-helmet';

export default function SmartWidgets({ title }) {
  return (
    <>
      <Helmet>
        <script src="https://res.smartwidgets.ru/app.js" defer></script>
      </Helmet>
      <div>
        {title && <h2 style={{ marginBottom: '32px' }}>Отзывы о нас</h2>}
        <div
          className="sw-app"
          data-app="4738b3ff34e13eef9722da18a90bf04b"
        ></div>
      </div>
    </>
  );
}
