import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';
import { useOrder } from 'store/order';

// Хук по запросу Корзины
function useCalcCdekCost() {
  const {
    address,
    delivery_type,
    express_delivery,
    location_code,
    setDeliveryCost,
  } = useOrder(
    useShallow((state) => ({
      address: state.address,
      delivery_type: state.delivery_type,
      express_delivery: state.express_delivery,
      location_code: state.location_code,
      setDeliveryCost: state.setDeliveryCost,
    })),
  );

  const [errorCalcCdekCost, setErrorCalcCdekCost] = useState(null);

  async function calcCdekCost() {
    const dataConfig = {
      delivery_type: delivery_type,
    };

    if (location_code) {
      dataConfig.to_location_code = location_code.toString();
    }

    if (address) {
      dataConfig.address = address;
      dataConfig.express_delivery = express_delivery;
    }

    try {
      const response = await $api.post(urls.calcDeliveryCost, dataConfig);

      setDeliveryCost(response.data.delivery_cost);
    } catch (error) {
      setErrorCalcCdekCost(error.message);
    }
  }

  return { errorCalcCdekCost, calcCdekCost };
}

export { useCalcCdekCost };
