import { useEffect } from 'react';

import { HeadTitleAndDescription } from 'entities/SEO/HeadTitleAndDescription';
import Router from 'app/Router/Router';
import useCheckAuth from 'api/Auth/useCheckAuth';
import { Modals } from 'entities/Modal';

function App() {
  const access_token = localStorage.getItem('token');
  const { checkAuth } = useCheckAuth();

  useEffect(() => {
    if (access_token) {
      checkAuth();
    }
  }, []);

  return (
    <>
      <HeadTitleAndDescription />
      <Router />
      <Modals />
    </>
  );
}

export default App;
