import styles from './ItemMainInfo.module.scss';
import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';

export default function ItemMainInfo({
  price,
  quantity,
  is_active,
  red_price,
  discount_price,
  discount,
}) {
  // Считаем размер бонусов
  const bonus = ((discount_price || price) / 100) * 3;

  return quantity && is_active ? (
    <div className={styles.itemInfo}>
      {!red_price && (
        <p className={styles.bonus}>
          Получите дополнительную скидку до 5% <br /> или{' '}
          {toCurrency.format(roundPrice(bonus))} руб. на бонусную карту Grand
          Gold
        </p>
      )}

      <div className={styles.pricesContainer}>
        <div className={styles.prices}>
          <h3 className={styles.mainPricePrimary}>
            {toCurrency.format(roundPrice(discount_price || price))} руб.
          </h3>
          {discount > 0 && (
            <p className={styles.oldPrice}>
              {toCurrency.format(roundPrice(price))}
              руб.
            </p>
          )}
        </div>
      </div>
    </div>
  ) : null;
}
