import React from 'react';
import styles from './TelegramButton.module.scss';

function TelegramButton() {
  return (
    <a
      className={styles.telegramButton}
      href="https://t.me/grandgold_bot"
      target="noopener"
    >
      <svg
        className={styles.icon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_393_710)">
          <path
            d="M22.0517 2.12913L0.78878 10.371C-0.0668675 10.7548 -0.356277 11.5234 0.581933 11.9406L6.03682 13.683L19.2259 5.48977C19.946 4.9754 20.6833 5.11257 20.0489 5.67837L8.72124 15.9878L8.3654 20.3508C8.69499 21.0244 9.29847 21.0275 9.68339 20.6927L12.8174 17.712L18.1848 21.752C19.4314 22.4939 20.1097 22.0151 20.3779 20.6554L23.8985 3.89903C24.264 2.22532 23.6407 1.48788 22.0517 2.12913Z"
            fill="#DCDCDC"
          />
        </g>
        <defs>
          <clipPath id="clip0_393_710">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

export { TelegramButton };
