import React, { useEffect, useState } from 'react';
import styles from './PostRecievType.module.scss';

import { useOrder } from 'store/order';
import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { AddressSuggestions } from 'react-dadata';
import { GoXCircleFill } from 'react-icons/go';

import { useCalcPostCost } from 'api/Order/useCalcPostCost';
import { useCart } from 'store/cart';

export default function PostRecievType() {
  const [inputValue, setInputValue] = useState('');
  const { errorCalcPostCost, calcPostCost } = useCalcPostCost();

  const itemsCount = useCart((state) => state.itemsCount);
  const { delivery_type, address, setAddRess, setDeliveryCost } = useOrder(
    (state) => ({
      delivery_type: state.delivery_type,
      address: state.address,
      setAddRess: state.setAddRess,
      setDeliveryCost: state.setDeliveryCost,
    }),
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    setAddRess(inputValue);

    const delivery_cost = await calcPostCost(delivery_type, inputValue);
    setDeliveryCost(delivery_cost);
  };

  const handleResetAddress = () => {
    setAddRess(null);
    setInputValue('');
    setDeliveryCost(null);
  };

  // Вызываем перерасчет при изменении itemsCount
  useEffect(() => {
    if (address) {
      const recalculateDeliveryCost = async () => {
        const delivery_cost = await calcPostCost(delivery_type, address);
        setDeliveryCost(delivery_cost);
      };

      recalculateDeliveryCost();
    }
  }, [itemsCount]);

  return (
    <form onSubmit={onSubmit} className={styles.containerPost}>
      <div className={styles.inputContainer}>
        <AddressSuggestions
          token={process.env.REACT_APP_DADATA_TOKEN}
          defaultQuery={address}
          value={inputValue}
          inputProps={{
            placeholder: 'Введите свой адрес',
          }}
          onChange={(event) => setInputValue(event.value)}
          count={4}
        />
        {address && (
          <GoXCircleFill
            size={20}
            className={styles.closeButton}
            onClick={handleResetAddress}
          />
        )}
      </div>
      {errorCalcPostCost && <p className={styles.error}>{errorCalcPostCost}</p>}
      {inputValue.length > 15 && (
        <SecondaryButton type="submit" text="Подтвердить адрес" />
      )}
    </form>
  );
}
