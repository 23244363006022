import React from 'react';
import styles from './FavoriteButton.module.scss';
import { NavLink } from 'react-router-dom';

import { GoHeartFill } from 'react-icons/go';

function FavoriteButton() {
  return (
    <NavLink
      to="favorite"
      className={({ isActive }) =>
        isActive ? styles.iconLinkActive : styles.iconLink
      }
    >
      <GoHeartFill className={styles.icon} size={24} />
    </NavLink>
  );
}

export { FavoriteButton };
