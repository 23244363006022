import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

// Запрос конкретного товара из Каталога
function useFetchItem() {
  const [item, setItem] = useState([]);
  const [isInCart, setInCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function fetchItem(id) {
    setLoading(true);

    try {
      const response = await $api.get(urls.getItem + id);

      setError(null);
      setItem(response.data);
      setInCart(response.data.is_in_cart);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  return { item, isInCart, loading, error, fetchItem };
}

export { useFetchItem };
