import { useState, useEffect, useRef } from 'react';

function AuthTimer({ durationInSeconds, onTimerEnd }) {
  const [secondsLeft, setSecondsLeft] = useState(durationInSeconds - 1);
  const timerRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    const countdown = () => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerRef.current);
          if (isMounted.current) {
            onTimerEnd();
          }
          return 0;
        }
        return prevSeconds - 1;
      });
    };

    timerRef.current = setInterval(countdown, 1000);

    // Cleanup function
    return () => {
      clearInterval(timerRef.current);
      isMounted.current = false;
    };
  }, [onTimerEnd]);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <span>
      {minutes}:{seconds < 10 ? '0' : ''}
      {seconds}
    </span>
  );
}

export { AuthTimer };
