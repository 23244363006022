import axios from 'axios';
import { urls } from '../../api/Config/usrlsConfig';

const $api = axios.create({
  withCredentials: true,
});

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;

      try {
        const response = await axios.get(urls.checkAuth, {
          withCredentials: true,
        });
        // Обновляем значения access_token
        localStorage.setItem('token', response.data.access_token);

        return $api.request(originalRequest);
      } catch (error) {
        console.log('Перехватчик на перезапись токенов:', error);
      }
    }

    throw error;
  },
);

export default $api;
