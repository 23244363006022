import { useState } from 'react';
import { urls } from 'api/Config/usrlsConfig';
import { useUser } from 'store/user';
import axios from 'axios';

export default function useCheckAuth() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setAuth, setLogOut } = useUser((state) => ({
    setAuth: state.setAuth,
    setLogOut: state.setLogOut,
  }));

  async function checkAuth() {
    setLoading(true);

    try {
      const response = await axios.get(urls.checkAuth, {
        withCredentials: true,
      });

      localStorage.setItem('token', response.data.access_token);
      setAuth(true);
    } catch (error) {
      console.log('checkAuth:', error);

      setLogOut();
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  }

  return { error, loading, checkAuth };
}
