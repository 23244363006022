import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import { categoriesStore } from '../../../../model/categoriesStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function CategoryFilter() {
  const { category, setFilters } = useFilters((state) => ({
    category: state.filters.category || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (category.includes(value)) {
      const updatedValues = category
        .split(';')
        .filter((category) => category !== value)
        .join(';');
      setFilters('category', updatedValues);
    } else {
      const updatedValues = category ? `${category};${value}` : value;
      setFilters('category', updatedValues);
    }

    setFilters('subcategory', '');
    setFilters('size', '');
  };

  return (
    <div className={styles.filteBox}>
      <FilterDropdown title="Категория" isOpen={!!category}>
        <div className={styles.contentContainer}>
          {categoriesStore.map((item, index) => (
            <FilterCheckbox
              key={index}
              item={item}
              label={item.name}
              handleChange={() => handleChange(item.value)}
              checked={category.includes(item.value)}
            />
          ))}
        </div>
      </FilterDropdown>
    </div>
  );
}

export { CategoryFilter };
