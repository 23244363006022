import styles from './ItemDiscription.module.scss';

import { CastomTooltip } from 'shared/ui/Tooltip';

export default function ItemDiscription({ item }) {
  //Форматируем артикул заранее
  const series = item.series.substr(7);

  //Так меньше кода получается
  const descriptionData = [
    {
      title: 'Артикул:',
      value: series,
    },
    { title: 'Размер:', value: item.size },
    { title: 'Проба:', value: item.sample },
    {
      title: 'Полновес | облегченное:',
      value: item.full_weight_or_light_weight,
    },
    {
      title: 'Вид изделия:',
      value: item.trade_offer_type,
    },
    { title: 'Цвет металла:', value: item.color_metal },
    { title: 'Вставки:', value: item.vstavki },
    { title: 'Тип замка:', value: item.locktype },
    {
      title: 'Адрес салона:',
      value: item.address,
      children: <CastomTooltip text="Возможен самовывоз сегодня" />,
    },
    { title: 'Код товара:', value: item.sku },
    {
      title: 'Вес:',
      value: item.weight,
      unit: ' гр.',
    },
    {
      title: 'Ширина:',
      value: item.width,
      unit: ' мм.',
    },
    {
      title: 'Высота:',
      value: item.height,
      unit: ' мм.',
    },
  ];

  return (
    <section>
      <h2 className={styles.itemDiscriptionTitle}>Характеристики</h2>
      <ul>
        {descriptionData.map((data) => {
          return data.value !== '' &&
            data.value !== '0' &&
            data.value !== undefined &&
            data.value !== null ? (
            <li className={styles.itemDiscriptionLi} key={data.title}>
              {data.title} {data.value} {data.unit}
              {data.children}
            </li>
          ) : null;
        })}
      </ul>
    </section>
  );
}
