import React from 'react';
import styles from './CatalogContainer.module.scss';

import { Filter } from 'entities/Filter';
import { Sort } from 'entities/Sort';
import { FilterIcon } from 'shared/ui/FilterIcon';
import { CatalogItems } from 'widgets/CatalogItems';

function CatalogContainer({ setError }) {
  return (
    <div className={styles.catalogContainer}>
      <Filter />
      <div className={styles.sortCatalogContainer}>
        <div className={styles.sortMobileFilter}>
          <Sort />
          <FilterIcon />
        </div>
        <CatalogItems setError={setError} />
      </div>
    </div>
  );
}

export { CatalogContainer };
