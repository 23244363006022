import { useState } from 'react';
import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

export default function useOneClickModal() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmitOneClick = async (
    data,
    first_name,
    phone_number,
    isWhatsApp,
    items,
  ) => {
    setLoading(true);

    const userData = {
      first_name: data.first_name || first_name,
      phone_number: data.phone_number || phone_number,
      is_whatsapp: isWhatsApp,
      trade_offers: items,
    };

    try {
      await $api.post(urls.buy1Click, userData);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, onSubmitOneClick };
}
