import React from 'react';
import styles from './AboutPage.module.scss';

import { MapSection } from 'widgets/MapSection';

function AboutPage() {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.titleAbout}>
        <h1>Контакты</h1>
      </div>
      <MapSection />
    </div>
  );
}

export { AboutPage };
