import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { normalizeSeoPath } from 'shared/lib/normalizeSeoPath';
import { seoMetadataMap } from '../../seoMetadataMap';

function HeadTitleAndDescription() {
  const [currentPath, setCurrentPath] = useState('/');
  const [title, setTitle] = useState('Grand Gold');
  const [description, setDescription] = useState('');
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(normalizeSeoPath(location.pathname));
  }, [location]);

  useEffect(() => {
    setTitle(
      (seoMetadataMap[currentPath] && seoMetadataMap[currentPath].title) ||
        'Grand Gold',
    );
    setDescription(
      (seoMetadataMap[currentPath] &&
        seoMetadataMap[currentPath].description) ||
        'Новое поступление ювелирных изделий по выгодным ценам в Ювелирном салоне Grand Gold. С 2007 года радуем вас красивыми и качественными украшениями.',
    );
  }, [currentPath]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export { HeadTitleAndDescription };
