import { useEffect, useState } from 'react';
import styles from './FavoritePage.module.scss';
import { Link } from 'react-router-dom';

import { FavoriteSkelleton } from 'shared/ui/Skelletons/Favorite';
import { MainButton } from 'shared/ui/MainButton';
import { GoHeart } from 'react-icons/go';
import { useFavorite } from 'store/favorite';
import { FavoriteItems } from 'widgets/FavoriteItems';

function FavoritePage() {
  const [pageLoading, setPageLoading] = useState(true);
  const { favorites, favoritesCount, getFavorite } = useFavorite((state) => ({
    favorites: state.favorites,
    favoritesCount: state.favoritesCount,
    getFavorite: state.getFavorite,
  }));

  const initializePage = async () => {
    await getFavorite();
    setPageLoading(false);
  };

  useEffect(() => {
    initializePage();
  }, []);

  if (pageLoading) {
    return (
      <>
        <h1 className={styles.title}>Избранные товары</h1>
        <FavoriteSkelleton />
      </>
    );
  }

  if (!pageLoading && !favoritesCount) {
    return (
      <div className={styles.emptyFavoriteContainer}>
        <h1 className={styles.title}>Избранные товары</h1>
        <GoHeart size={100} />
        <h3>Здесь пока пусто...</h3>
        <Link to="/catalog">
          <MainButton text="Перейти в Каталог" />
        </Link>
      </div>
    );
  }

  return (
    <>
      <h1 className={styles.title}>Избранные товары</h1>
      <FavoriteItems favorites={favorites} />
    </>
  );
}

export { FavoritePage };
