import React, { useEffect, useState } from 'react';
import styles from './Burger.module.scss';

import { useNavbar } from '../lib/store';

import { BsList } from 'react-icons/bs';
import { BsXLg } from 'react-icons/bs';

function Burger() {
  const [activeBurger, setActiveBurger] = useState(false);
  const { navbarState, setNavbarState } = useNavbar((state) => ({
    navbarState: state.navbarState,
    setNavbarState: state.setNavbarState,
  }));

  useEffect(() => {
    setActiveBurger(navbarState);
  }, [navbarState]);

  function handleOpenNavbar() {
    setNavbarState(true);
    setActiveBurger(true);
  }

  function handleCloseNavbar() {
    setNavbarState(false);
    setActiveBurger(false);
  }

  return (
    <>
      {!activeBurger ? (
        <BsList className={styles.icon} onClick={handleOpenNavbar} size={24} />
      ) : (
        <BsXLg className={styles.icon} onClick={handleCloseNavbar} size={24} />
      )}
    </>
  );
}

export { Burger };
