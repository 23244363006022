import styles from '../../../Filter.module.scss';
import { useFilters } from 'store/filters';
import {
  vstavkiStore,
  vstavkiDetailStore,
} from '../../../../model/vstavkiStore';

import { FilterDropdown } from 'shared/ui/FilterDropdown';
import { FilterCheckbox } from 'shared/ui/FilterCheckbox';

function VstavkiFilter() {
  const { vstavki, vstavkiDetail, setFilters } = useFilters((state) => ({
    vstavki: state.filters.vstavki || '',
    vstavkiDetail: state.filters.vstavkidetail || '',
    setFilters: state.setFilters,
  }));

  const handleChange = (value) => {
    if (vstavki.includes(value)) {
      // При снятии активного чекбокса
      const updatedValues = vstavki
        .split(';')
        .filter((vstavki) => vstavki !== value)
        .join(';');
      setFilters('vstavki', updatedValues);
    } else {
      const updatedValues = vstavki ? `${vstavki};${value}` : value;
      setFilters('vstavki', updatedValues);
    }
  };

  const handleChangeDetail = (value) => {
    if (vstavkiDetail.includes(value)) {
      // При снятии активного чекбокса
      const updatedValues = vstavkiDetail
        .split(';')
        .filter((vstavki) => vstavki !== value)
        .join(';');
      setFilters('vstavkidetail', updatedValues);
    } else {
      const updatedValues = vstavkiDetail ? `${vstavkiDetail};${value}` : value;
      setFilters('vstavkidetail', updatedValues);
    }
  };

  return (
    <FilterDropdown title="Вставки" isOpen={Boolean(vstavki || vstavkiDetail)}>
      <div className={styles.contentContainer}>
        <>
          {vstavkiStore.map((item) => (
            <FilterCheckbox
              key={item.index}
              item={item}
              label={item.name}
              handleChange={() => handleChange(item.value)}
              checked={vstavki.includes(item.value)}
            />
          ))}
          {vstavkiDetailStore.map((item) => (
            <FilterCheckbox
              key={item.index}
              item={item}
              label={item.name}
              handleChange={() => handleChangeDetail(item.value)}
              checked={vstavkiDetail.includes(item.value)}
            />
          ))}
        </>
      </div>
    </FilterDropdown>
  );
}

export { VstavkiFilter };
