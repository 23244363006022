import React from 'react';
import styles from './TotalError.module.scss';

export default function TotalError({ payment_type, address }) {
  return (
    <div className={styles.errorsContainer}>
      {address === null && (
        <p className={styles.error}>
          Выберите способ получения и укажите адрес
        </p>
      )}
      {payment_type === null && (
        <p className={styles.error}>Выберите тип оплаты</p>
      )}
    </div>
  );
}
