import { useEffect, useState } from 'react';
import styles from './NewPage.module.scss';

import { useParams } from 'react-router-dom';
import { usePromoBanner } from 'api/Banners/usePromoBanner';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

import { NewsBanners } from 'widgets/Banners';

function NewPage() {
  const { url } = useParams();
  const [key, setKey] = useState('');
  const windowSize = useWindowSize();
  const { loading, promoBanner, getPromoBanner } = usePromoBanner();

  // Подключаем разные размеры баннеров по условию
  // Меняю ключ в зависимости от размеров экрана
  useEffect(() => {
    if (!loading) {
      if (windowSize < 600) {
        setKey('image360x512');
      } else if (windowSize < 940) {
        setKey('image768x512');
      } else if (windowSize < 1280) {
        setKey('image1024x512');
      } else if (windowSize < 1700) {
        setKey('image1286x512');
      } else {
        setKey('image1840x512');
      }
    }
  }, [useWindowSize, loading]);

  useEffect(() => {
    getPromoBanner(url);
  }, []);

  return (
    <div className={styles.page}>
      {promoBanner && (
        <>
          <h1 className={styles.mainTitle}>Акция {promoBanner.title}</h1>
          <NewsBanners
            key={promoBanner.id}
            image={promoBanner[key]}
            loading={loading}
          />
          <div className={styles.topic}>
            <h2 className={styles.listTitle}>Условия акции</h2>
            <p className={styles.paragraph}>{promoBanner.text}</p>
          </div>
        </>
      )}
    </div>
  );
}

export { NewPage };
