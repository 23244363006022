export const ringsSizes = [
  { value: '14,0' },
  { value: '14,5' },
  { value: '15,0' },
  { value: '15,5' },
  { value: '16,0' },
  { value: '16,5' },
  { value: '17,0' },
  { value: '17,5' },
  { value: '18,0' },
  { value: '18,5' },
  { value: '19,0' },
  { value: '19,5' },
  { value: '20,0' },
  { value: '20,5' },
  { value: '21,0' },
  { value: '21,5' },
  { value: '22,0' },
  { value: '22,5' },
  { value: '23,0' },
  { value: '23,5' },
  { value: '24,0' },
  { value: '24,5' },
  { value: '25,0' },
];

export const chainsSizes = [
  { value: '40,0' },
  { value: '45,0' },
  { value: '50,0' },
  { value: '55,0' },
  { value: '60,0' },
  { value: '65,0' },
  { value: '70,0' },
  { value: '75,0' },
  { value: '80,0' },
];

export const cufsSizes = [
  { value: '14,0' },
  { value: '14,5' },
  { value: '15,0' },
  { value: '15,5' },
  { value: '16,0' },
  { value: '16,5' },
  { value: '17,0' },
  { value: '17,5' },
  { value: '18,0' },
  { value: '18,5' },
  { value: '19,0' },
  { value: '19,5' },
  { value: '20,0' },
  { value: '20,5' },
  { value: '21,0' },
  { value: '21,5' },
  { value: '22,0' },
  { value: '22,5' },
  { value: '23,0' },
  { value: '23,5' },
  { value: '24,0' },
  { value: '24,5' },
  { value: '25,0' },
];

export const gaitanySizes = [
  { value: '45,0' },
  { value: '50,0' },
  { value: '55,0' },
  { value: '60,0' },
  { value: '65,0' },
  { value: '70,0' },
];
