import styles from './CalculateTotal.module.scss';

import wordsDeclention from 'shared/lib/wordsDeclention';
import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';

const words = ['товар', 'товара', 'товаров'];

export default function CalculateTotal({
  itemsLength,
  summEconomy,
  delivery_cost,
  summOldPrises,
}) {
  return (
    <div className={styles.calculateTotalCOntainer}>
      <div className={styles.sell}>
        <p>{`Сумма за ${itemsLength} ${wordsDeclention(
          itemsLength,
          words,
        )}`}</p>
        <p className={styles.colorPrimary}>Скидка</p>
        {delivery_cost ? <p>Доставка</p> : null}
      </div>
      <div className={styles.prices}>
        <p>{toCurrency.format(roundPrice(summOldPrises))} руб.</p>
        {summEconomy > 1 ? (
          <p className={styles.colorPrimary}>
            {toCurrency.format(roundPrice(summEconomy))} руб.
          </p>
        ) : null}
        {delivery_cost ? (
          <p>{toCurrency.format(roundPrice(delivery_cost))} руб.</p>
        ) : null}
      </div>
    </div>
  );
}
