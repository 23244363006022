import { useState } from 'react';

import $api from '../../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

// Хук по запросу Корзины
function useCalcPostCost() {
  const [errorCalcPostCost, setErrorCalcPostCost] = useState(null);

  async function calcPostCost(delivery_type, address) {
    const dataConfig = {
      delivery_type: delivery_type,
      address: address,
    };

    try {
      const response = await $api.post(urls.calcDeliveryCost, dataConfig);

      setErrorCalcPostCost(null);
      return Number(response.data.delivery_cost);
    } catch (error) {
      setErrorCalcPostCost(error.response.data.message);
    }
  }

  return { errorCalcPostCost, calcPostCost };
}

export { useCalcPostCost };
